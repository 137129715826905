import React, {useState} from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField, Checkbox, Autocomplete,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const MediaDownloads: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;

    const downloads: { id: string, title: string }[] = data.selectDownloads;
    const selectedDownloadsObj = state.downloads ? state.downloads.map((id: string) => downloads.filter((item) => item.id === id)[0]) : (values.downloads ? values.downloads.map((id: string) => downloads.filter((item) => item.id === id)[0]) : []);
    const [selectedDownloads, setSelectedDownloads] = useState<any[]>(selectedDownloadsObj);

    //------------------------------- Public methods -------------------------------------

    const handleDownloadsChange = (value: any) => {
        setSelectedDownloads(value);
    }

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
               Downloads
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <Autocomplete
                        multiple
                        options={downloads}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedDownloads || []}
                        defaultValue={selectedDownloads || []}
                        onChange={(event: any, value: any) => {
                            handleDownloadsChange(value);
                            onChange(event, 'downloads', 'autocomplete', value.map((item: any) => item.id))
                        }
                        }
                        renderOption={(props, option, {selected}) => (
                            <li {...props} key={option.id}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Downloads" placeholder="Downloads"
                                       name="downloads"/>
                        )}
                    />
                </Grid>
            </Grid>

        </React.Fragment>
    );
}


export default MediaDownloads;
