import React from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";


// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {useParams} from "react-router-dom";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const GeneralInfoArticle: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const params = useParams();
    const lang = params.lang ? params.lang : '';

    //------------------------------- Public methods -------------------------------------

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations
            </Typography>

            {lang == 'de' &&
                <Grid container spacing={6} mb={5}>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="titleDe"
                            label="Title De"
                            value={values.titleDe ? values.titleDe : ''}
                            error={Boolean(touched.titleDe && errors.titleDe)}
                            fullWidth
                            helperText={touched.titleDe && errors.titleDe}
                            onChange={onChange}
                            variant="outlined"

                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="title"
                            label="Title"
                            value={values.title ? values.title : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="typeDe"
                            label="Type De"
                            value={values.typeDe ? values.typeDe : ''}
                            error={Boolean(touched.typeDe && errors.typeDe)}
                            fullWidth
                            helperText={touched.typeDe && errors.typeDe}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="type"
                            label="Type"
                            value={values.type ? values.type : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="applicationDe"
                            label="Application De"
                            value={values.applicationDe ? values.applicationDe : ''}
                            error={Boolean(touched.applicationDe && errors.applicationDe)}
                            fullWidth
                            helperText={touched.applicationDe && errors.applicationDe}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="application"
                            label="Application"
                            value={values.application ? values.application : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="eanUpcDe"
                            label="EAN/UPC De"
                            value={values.eanUpcDe ? values.eanUpcDe : ''}
                            error={Boolean(touched.eanUpcDe && errors.eanUpcDe)}
                            fullWidth
                            helperText={touched.eanUpcDe && errors.eanUpcDe}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="eanUpc"
                            label="EAN/UPC"
                            value={values.eanUpc ? values.eanUpc : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="Description De"
                            name="descriptionDe"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.descriptionDe ? values.descriptionDe : ''}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="Description"
                            name="description"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.description ? values.description : ''}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
            }
            {lang == 'cn' &&
                <Grid container spacing={6} mb={5}>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="titleCn"
                            label="Title Cn"
                            value={values.titleCn ? values.titleCn : ''}
                            error={Boolean(touched.titleCn && errors.titleCn)}
                            fullWidth
                            helperText={touched.titleCn && errors.titleCn}
                            onChange={onChange}
                            variant="outlined"

                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="title"
                            label="Title"
                            value={values.title ? values.title : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="typeCn"
                            label="Type Cn"
                            value={values.typeCn ? values.typeCn : ''}
                            error={Boolean(touched.typeCn && errors.typeCn)}
                            fullWidth
                            helperText={touched.typeCn && errors.typeCn}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="type"
                            label="Type"
                            value={values.type ? values.type : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="applicationCn"
                            label="Application Cn"
                            value={values.applicationCn ? values.applicationCn : ''}
                            error={Boolean(touched.applicationCn && errors.applicationCn)}
                            fullWidth
                            helperText={touched.applicationCn && errors.applicationCn}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="application"
                            label="Application"
                            value={values.application ? values.application : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="eanUpcCn"
                            label="EAN/UPC Cn"
                            value={values.eanUpcCn ? values.eanUpcCn : ''}
                            error={Boolean(touched.eanUpcCn && errors.eanUpcCn)}
                            fullWidth
                            helperText={touched.eanUpcCn && errors.eanUpcCn}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="eanUpc"
                            label="EAN/UPC"
                            value={values.eanUpc ? values.eanUpc : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="Description Cn"
                            name="descriptionCn"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.descriptionCn ? values.descriptionCn : ''}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="Description"
                            name="description"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.description ? values.description : ''}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}


export default GeneralInfoArticle;
