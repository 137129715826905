import React, {createRef, RefObject, useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button
} from "@mui/material";
import ListViewTable from "../components/ListViewTable";
import {CompaniesContext} from "./store/companies-context";
import SearchBar from "../components/SearchBar";


// Types
import {HeadCell} from "../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon, FileDownload, FileUpload} from "@mui/icons-material";

const Input = styled('input')({display: 'none',});
const Divider = styled(MuiDivider)(spacing);


function Companies() {
    const companiesCtx = useContext(CompaniesContext);
    const fileInput: RefObject<HTMLInputElement> = createRef();

    // Set table headers data
    const headCells: Array<HeadCell> = companiesCtx.headers;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Companies"/>
            <Grid item xs={12} mb={10}>
                <SearchBar text={companiesCtx.search} onSearch={companiesCtx.onSearch}/>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        Companies
                    </Typography>
                </Grid>
                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" onClick={companiesCtx.exportCsv}
                                startIcon={<FileDownload/>} className="margin-right-btn">
                            CSV Export Companies
                        </Button>
                        <Input
                            ref={fileInput}
                            accept=".csv,.xlsx,.xls"
                            type="file"
                            name="file"
                            onChange={(event: any) => {
                                if (companiesCtx.importCsv) {
                                    companiesCtx.importCsv(event.target.files)
                                }
                            }}
                        />
                        <Button variant="contained" color="primary" onClick={(e: any) => {
                            if (fileInput.current) {
                                fileInput.current.click();
                            }
                        }} startIcon={<FileUpload/>} className="margin-right-btn">
                            CSV Import Companies
                        </Button>
                        <Button variant="contained" color="primary" onClick={companiesCtx.addItem}  startIcon={<AddIcon/>}>
                            New Company
                        </Button>
                    </div>
                </Grid>
            </Grid>

            {companiesCtx.filters
                ? <Divider my={6}/>
                : <Divider my={6} mb={10}/>
            }

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={companiesCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={companiesCtx.removeItem}
                    onSelectHandler={companiesCtx.selectItem}
                    onPaginateHandler={companiesCtx.onPaginate}
                    onSortHandler={companiesCtx.onSort}
                    count={companiesCtx.count}
                    page={companiesCtx.page}
                    rowsPerPage={companiesCtx.rowsPerPage}
                    sorting={companiesCtx.sorting}
                    filters={companiesCtx.filters}/>
            </Grid>


        </React.Fragment>
    );
}

export default Companies;
