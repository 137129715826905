import React from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Divider as MuiDivider,
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {useParams} from "react-router-dom";


const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const GeneralInfoCompany: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const params = useParams();
    const lang = params.lang ? params.lang : '';

    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations
            </Typography>

            {lang == 'de' &&
                <React.Fragment>

                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="titleDe"
                                label="Title De"
                                value={values.titleDe ? values.titleDe : ''}
                                error={Boolean(touched.titleDe && errors.titleDe)}
                                fullWidth
                                helperText={touched.titleDe && errors.titleDe}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="title"
                                label="Title"
                                value={values.title ? values.title : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Divider mb={10}/>
                        </Grid>
                    </Grid>

                    <Typography variant="h6" mb={5}>
                        Additional info
                    </Typography>
                    <Grid container spacing={6}>

                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="additionalDe"
                                label="Additional De"
                                value={values.additionalDe ? values.additionalDe : ''}
                                fullWidth
                                multiline
                                rows={4}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="additional"
                                label="Additional"
                                value={values.additional ? values.additional : ''}
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            {lang == 'cn' &&
                <React.Fragment>

                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="titleCn"
                                label="Title Cn"
                                value={values.titleCn ? values.titleCn : ''}
                                error={Boolean(touched.titleCn && errors.titleCn)}
                                fullWidth
                                helperText={touched.titleCn && errors.titleCn}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="title"
                                label="Title"
                                value={values.title ? values.title : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Divider mb={10}/>
                        </Grid>
                    </Grid>

                    <Typography variant="h6" mb={5}>
                        Additional info
                    </Typography>
                    <Grid container spacing={6}>

                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="additionalCn"
                                label="Additional Cn"
                                value={values.additionalCn ? values.additionalCn : ''}
                                fullWidth
                                multiline
                                rows={4}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="additional"
                                label="Additional"
                                value={values.additional ? values.additional : ''}
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            }


        </React.Fragment>
    );
}


export default GeneralInfoCompany;
