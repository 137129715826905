import React, {useState} from "react";
import {
    Grid,
    Typography,
    Divider as MuiDivider,
    TextField as MuiTextField,
    Autocomplete, Checkbox, Chip, Stack
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const RelatedProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const accessories: { id: string, title: string }[] = data.selectAccessories;
    const articles: { id: string, title: string }[] = data.selectArticles;
    const selectedAccessoriesObj = state.accessories ? state.accessories.map((id: string) => accessories.filter((item) => item.id === id)[0]) : (values.accessories ? values.accessories.map((id: string) => accessories.filter((item) => item.id === id)[0]) : []);

    // States
    const [selectedAccessories, setSelectedAccessories] = useState<any[]>(selectedAccessoriesObj);


    //------------------------------- Public methods -------------------------------------

    // Update platforms on change
    const handleAccessoriesChange = (value: any) => {
        setSelectedAccessories(value);
    };


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Accessorise (Datasheet)
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} >
                    <Autocomplete
                        multiple
                        options={accessories}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedAccessories || []}
                        defaultValue={selectedAccessories || []}
                        onChange={(event: any, value: any) => {
                            handleAccessoriesChange(value);
                            onChange(event, 'accessories', 'autocomplete', value.map((item: any) => item.id))
                        }
                        }
                        renderOption={(props, option, {selected}) => (
                            <li {...props} key={option.id}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Accessories" placeholder="Accessories" name="accessories"/>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10} mt={10}/>
                </Grid>
            </Grid>
            <Grid container spacing={6} mb={10}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                       Articles
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1} className="related-item">
                        {articles.map((item: { id: string, title: string }) => (
                            <Chip key={item.id} label={item.title} className="item" />
                        ))}
                    </Stack>

                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default RelatedProduct;
