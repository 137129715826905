import React, {useState} from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Checkbox,
    Divider as MuiDivider, Autocomplete, InputLabel, Select, MenuItem, FormControl, FormControlLabel, FormGroup,
} from "@mui/material";
import ReactQuill from "react-quill";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const Divider = styled(MuiDivider)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {min-height: 200px;}`;

const AdditionalProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const approvals: { id: string, title: string }[] = data.selectApprovals;
    const selectedApprovalsObj = state.approvals ? state.approvals.map((id: string) => approvals.filter((item) => item.id === id)[0]) : (values.approvals ? values.approvals.map((id: string) => approvals.filter((item) => item.id === id)[0]) : []);
    const [selectedApprovals, setSelectedApprovals] = useState<any[]>(selectedApprovalsObj);

    const capacitanceUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'capacitanceUnit');
    const frequencyUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'frequencyUnit');
    const frequencyDcToKhzUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'frequencyDcToKhzUnit');
    const switchingFrequencyUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'switchingFrequencyUnit');
    const inductanceUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'inductanceUnit');
    const leakageCurrentUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'leakageCurrentUnit');
    const leakageCurrentAUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'leakageCurrentAUnit');
    const leakageCurrentBUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'leakageCurrentBUnit');
    const leakageCurrentMikroampereBUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'leakageCurrentMikroampereBUnit');
    const leakageCurrentOnlyOneUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'leakageCurrentOnlyOneUnit');
    const motorPowerUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'motorPowerUnit');
    const motorCableLengthUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'motorCableLengthUnit');
    const motorFrequencyUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'motorFrequencyUnit');
    const ratedCurrentsUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'ratedCurrentsUnit');
    const ratedCurrentTemperatureUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'ratedCurrentTemperatureUnit');

    const [capacitanceUnit, setCapacitanceUnit] = useState(state.capacitanceUnit ? state.capacitanceUnit : (values.capacitanceUnit ? values.capacitanceUnit[0] : ''));
    const [frequencyUnit, setFrequencyUnit] = useState(state.frequencyUnit ? state.frequencyUnit : (values.frequencyUnit ? values.frequencyUnit[0] : ''));
    const [frequencyDcToKhzUnit, setFrequencyDcToKhzUnit] = useState(state.frequencyDcToKhzUnit ? state.frequencyDcToKhzUnit : (values.frequencyDcToKhzUnit ? values.frequencyDcToKhzUnit[0] : ''));
    const [switchingFrequencyUnit, setSwitchingFrequencyUnit] = useState(state.switchingFrequencyUnit ? state.switchingFrequencyUnit : (values.switchingFrequencyUnit ? values.switchingFrequencyUnit[0] : ''));
    const [inductanceUnit, setInductanceUnit] = useState(state.inductanceUnit ? state.inductanceUnit : (values.inductanceUnit ? values.inductanceUnit[0] : ''));
    const [leakageCurrentUnit, setLeakageCurrentUnit] = useState(state.leakageCurrentUnit ? state.leakageCurrentUnit : (values.leakageCurrentUnit ? values.leakageCurrentUnit[0] : ''));
    const [leakageCurrentAUnit, setLeakageCurrentAUnit] = useState(state.leakageCurrentAUnit ? state.leakageCurrentAUnit : (values.leakageCurrentAUnit ? values.leakageCurrentAUnit[0] : ''));
    const [leakageCurrentBUnit, setLeakageCurrentBUnit] = useState(state.leakageCurrentBUnit ? state.leakageCurrentBUnit : (values.leakageCurrentBUnit ? values.leakageCurrentBUnit[0] : ''));
    const [leakageCurrentMikroampereBUnit, setLeakageCurrentMikroampereBUnit] = useState(state.leakageCurrentMikroampereBUnit ? state.leakageCurrentMikroampereBUnit : (values.leakageCurrentMikroampereBUnit ? values.leakageCurrentMikroampereBUnit[0] : ''));
    const [leakageCurrentOnlyOneUnit, setLeakageCurrentOnlyOneUnit] = useState(state.leakageCurrentOnlyOneUnit ? state.leakageCurrentOnlyOneUnit : (values.leakageCurrentOnlyOneUnit ? values.leakageCurrentOnlyOneUnit[0] : ''));
    const [motorPowerUnit, setMotorPowerUnit] = useState(state.motorPowerUnit ? state.motorPowerUnit : (values.motorPowerUnit ? values.motorPowerUnit[0] : ''));
    const [motorCableLengthUnit, setMotorCableLengthUnit] = useState(state.motorCableLengthUnit ? state.motorCableLengthUnit : (values.motorCableLengthUnit ? values.motorCableLengthUnit[0] : ''));
    const [motorFrequencyUnit, setMotorFrequencyUnit] = useState(state.motorFrequencyUnit ? state.motorFrequencyUnit : (values.motorFrequencyUnit ? values.motorFrequencyUnit[0] : ''));
    const [ratedCurrentsUnit, setRatedCurrentsUnit] = useState(state.ratedCurrentsUnit ? state.ratedCurrentsUnit : (values.ratedCurrentsUnit ? values.ratedCurrentsUnit[0] : ''));
    const [ratedCurrentTemperatureUnit, setRatedCurrentTemperatureUnit] = useState(state.ratedCurrentTemperatureUnit ? state.ratedCurrentTemperatureUnit : (values.ratedCurrentTemperatureUnit ? values.ratedCurrentTemperatureUnit[0] : ''));


    // States
    const [valueChassisMount, setValueChassisMount] = useState<boolean>(values.chassisMount?.length == 0 || !values.chassisMount ? false : values.chassisMount);
    const currentType: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'currentType');
    const setCurrentTypeObj = state.currentType ? state.currentType.map((id: string) => currentType.filter((item) => item.id === id)[0]) : (values.currentType ? values.currentType.map((id: string) => currentType.filter((item) => item.id === id)[0]) : []);
    const [selectedCurrentType, setSelectedCurrentType] = useState<any[]>(setCurrentTypeObj);
    const [descriptionAdditional, setDescriptionAdditional] = useState(state.descriptionAdditional ? state.descriptionAdditional : (values.descriptionAdditional ? values.descriptionAdditional : ''));
    const [qrCodeDescription, setQrCodeDescription] = useState(state.qrCodeDescription ? state.qrCodeDescription : (values.qrCodeDescription ? values.qrCodeDescription : ''));


    //------------------------------- Public methods -------------------------------------

    // Update multiselect on change with autocomplete
    const handleApprovalsChange = (value: any) => {
        setSelectedApprovals(value);
    };
    const handleCurrentTypeChange = (value: any) => {
        setSelectedCurrentType(value);
    };


    // Update description on change
    const handleChangeDescriptionAdditional = (event: any) => {
        event.toString('html');
        setDescriptionAdditional(event);
    };
    const handleChangeQrCodeDescription = (event: any) => {
        event.toString('html');
        setQrCodeDescription(event);
    };

    //update checkbox
    const handleChassisMount = (event:any) => {
        setValueChassisMount(event.target.checked);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Approvals
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <Autocomplete
                        multiple
                        options={approvals}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedApprovals || []}
                        defaultValue={selectedApprovals || []}
                        onChange={(event: any, value: any) => {
                            handleApprovalsChange(value);
                            onChange(event, 'approvals', 'autocomplete', value.map((item: any) => item.id))
                        }
                        }
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Approvals & Compliances" placeholder="Approvals & Compliances"
                                       name="approvals"/>
                        )}
                    />
                </Grid>
                <Grid item xs={12} mb={5}>
                    <TextField
                        label="Approvals & Compliances note"
                        name="approvalsNote"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.approvalsNote ? values.approvalsNote : ''}
                        onChange={onChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Frequency
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Frequency from"
                        name="frequencyFrom"
                        fullWidth
                        value={values.frequencyFrom ? values.frequencyFrom : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Frequency to"
                        name="frequencyTo"
                        fullWidth
                        value={values.frequencyTo ? values.frequencyTo : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Frequency unit</InputLabel>
                        <Select
                            name="frequencyUnit"
                            value={frequencyUnit}
                            label="Frequency unit"
                            onChange={(event: any) => {
                                setFrequencyUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {frequencyUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Frequency (DC)"
                        name="frequencyDcToKhz"
                        fullWidth
                        value={values.frequencyDcToKhz ? values.frequencyDcToKhz : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Frequency (DC) unit</InputLabel>
                        <Select
                            name="frequencyDcToKhzUnit"
                            value={frequencyDcToKhzUnit}
                            label="Frequency (DC) unit"
                            onChange={(event: any) => {
                                setFrequencyDcToKhzUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {frequencyDcToKhzUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Switching frequency from"
                        name="switchingFrequencyFrom"
                        fullWidth
                        value={values.switchingFrequencyFrom ? values.switchingFrequencyFrom : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Switching frequency to"
                        name="switchingFrequencyTo"
                        fullWidth
                        value={values.switchingFrequencyTo ? values.switchingFrequencyTo : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Switching frequency unit</InputLabel>
                        <Select
                            name="switchingFrequencyUnit"
                            value={switchingFrequencyUnit}
                            label="Switching frequency unit"
                            onChange={(event: any) => {
                                setSwitchingFrequencyUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {switchingFrequencyUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Inductance
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Inductance from "
                        name="inductanceFrom"
                        fullWidth
                        value={values.inductanceFrom ? values.inductanceFrom : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Inductance to"
                        name="inductanceTo"
                        fullWidth
                        value={values.inductanceTo ? values.inductanceTo : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Inductance unit</InputLabel>
                        <Select
                            name="inductanceUnit"
                            value={inductanceUnit}
                            label="Inductance unit"
                            onChange={(event: any) => {
                                setInductanceUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {inductanceUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Leakage
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Leakage current from"
                        name="leakageCurrentFrom"
                        fullWidth
                        value={values.leakageCurrentFrom ? values.leakageCurrentFrom : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Leakage current to"
                        name="leakageCurrentTo"
                        fullWidth
                        value={values.leakageCurrentTo ? values.leakageCurrentTo : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Leakage current unit</InputLabel>
                        <Select
                            name="leakageCurrentUnit"
                            value={leakageCurrentUnit}
                            label="Leakage current unit"
                            onChange={(event: any) => {
                                setLeakageCurrentUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {leakageCurrentUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Leakage current (A types)"
                        name="leakageCurrentA"
                        fullWidth
                        value={values.leakageCurrentA ? values.leakageCurrentA : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Leakage current (A types) unit</InputLabel>
                        <Select
                            name="leakageCurrentAUnit"
                            value={leakageCurrentAUnit}
                            label="Leakage current (A types) unit"
                            onChange={(event: any) => {
                                setLeakageCurrentAUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {leakageCurrentAUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Leakage current (B types)"
                        name="leakageCurrentB"
                        fullWidth
                        value={values.leakageCurrentB ? values.leakageCurrentB : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Leakage current (B types) unit</InputLabel>
                        <Select
                            name="leakageCurrentBUnit"
                            value={leakageCurrentBUnit}
                            label="Leakage current (B types) unit"
                            onChange={(event: any) => {
                                setLeakageCurrentBUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {leakageCurrentBUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Leakage current mikroampere (B types)"
                        name="leakageCurrentMikroampereB"
                        fullWidth
                        value={values.leakageCurrentMikroampereB ? values.leakageCurrentMikroampereB : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Leakage current mikroampere (B types) unit</InputLabel>
                        <Select
                            name="leakageCurrentMikroampereBUnit"
                            value={leakageCurrentMikroampereBUnit}
                            label="Leakage current mikroampere (B types) unit"
                            onChange={(event: any) => {
                                setLeakageCurrentMikroampereBUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {leakageCurrentMikroampereBUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Leakage current only one"
                        name="leakageCurrentOnlyOne"
                        fullWidth
                        value={values.leakageCurrentOnlyOne ? values.leakageCurrentOnlyOne : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Leakage current only one unit</InputLabel>
                        <Select
                            name="leakageCurrentOnlyOneUnit"
                            value={leakageCurrentOnlyOneUnit}
                            label="Leakage current only one unit"
                            onChange={(event: any) => {
                                setLeakageCurrentOnlyOneUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {leakageCurrentOnlyOneUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Leakage current only one mikroampere"
                        name="leakageCurrentOnlyOneMikroampere"
                        fullWidth
                        value={values.leakageCurrentOnlyOneMikroampere ? values.leakageCurrentOnlyOneMikroampere : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Motor
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Motor power from"
                        name="motorPowerFrom"
                        fullWidth
                        value={values.motorPowerFrom ? values.motorPowerFrom : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Motor power to"
                        name="motorPowerTo"
                        fullWidth
                        value={values.motorPowerTo ? values.motorPowerTo : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Motor power unit</InputLabel>
                        <Select
                            name="motorPowerUnit"
                            value={motorPowerUnit}
                            label="Motor power unit"
                            onChange={(event: any) => {
                                setMotorPowerUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {motorPowerUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Motor cable length"
                        name="motorCableLength"
                        fullWidth
                        value={values.motorCableLength ? values.motorCableLength : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Motor cable length unit</InputLabel>
                        <Select
                            name="motorCableLengthUnit"
                            value={motorCableLengthUnit}
                            label="Motor cable length unit"
                            onChange={(event: any) => {
                                setMotorCableLengthUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {motorCableLengthUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Motor frequency from"
                        name="motorFrequencyFrom"
                        fullWidth
                        value={values.motorFrequencyFrom ? values.motorFrequencyFrom : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Motor frequency to"
                        name="motorFrequencyTo"
                        fullWidth
                        value={values.motorFrequencyTo ? values.motorFrequencyTo : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Motor frequency unit</InputLabel>
                        <Select
                            name="motorFrequencyUnit"
                            value={motorFrequencyUnit}
                            label="Motor frequency unit"
                            onChange={(event: any) => {
                                setMotorFrequencyUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {motorFrequencyUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>


            <Typography variant="h6" mb={5}>
                Capacitance
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Capacitance from"
                        name="capacitanceFrom"
                        fullWidth
                        value={values.capacitanceFrom ? values.capacitanceFrom : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Capacitance to"
                        name="capacitanceTo"
                        fullWidth
                        value={values.capacitanceTo ? values.capacitanceTo : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Capacitance unit</InputLabel>
                        <Select
                            name="capacitanceUnit"
                            value={capacitanceUnit}
                            label="Capacitance unit"
                            onChange={(event: any) => {
                                setCapacitanceUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {capacitanceUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Rated
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Rated currents from"
                        name="ratedCurrentsFrom"
                        fullWidth
                        value={values.ratedCurrentsFrom ? values.ratedCurrentsFrom : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Rated currents to"
                        name="ratedCurrentsTo"
                        fullWidth
                        value={values.ratedCurrentsTo ? values.ratedCurrentsTo : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Rated currents unit</InputLabel>
                        <Select
                            name="ratedCurrentsUnit"
                            value={ratedCurrentsUnit}
                            label="Rated currents unit"
                            onChange={(event: any) => {
                                setRatedCurrentsUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {ratedCurrentsUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        label="Temperature for Rated currents"
                        name="ratedCurrentTemperature"
                        fullWidth
                        value={values.ratedCurrentTemperature ? values.ratedCurrentTemperature : ''}
                        onChange={onChange}
                    />
                </Grid>

                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Temperature for Rated currents unit</InputLabel>
                        <Select
                            name="ratedCurrentTemperatureUnit"
                            value={ratedCurrentTemperatureUnit}
                            label="Temperature for Rated currents unit"
                            onChange={(event: any) => {
                                setRatedCurrentTemperatureUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {ratedCurrentTemperatureUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Grid container spacing={6}>
                <Grid item xs={12} md={2} mb={5}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                name="chassisMount"
                                checked={valueChassisMount}
                                onChange={(e) =>{
                                    handleChassisMount(e);
                                    onChange(e)
                                } }
                            />
                        } label="Chassis mount"/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={7} mb={5}>
                    <Autocomplete
                        multiple
                        options={currentType}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedCurrentType || []}
                        defaultValue={selectedCurrentType || []}
                        onChange={(event: any, value: any) => {
                            handleCurrentTypeChange(value);
                            onChange(event, 'currentType', 'autocomplete', value.map((item: any) => item.id))
                        }
                        }
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Current Type" placeholder="Current Type" name="currentType"/>
                        )}
                    />
                </Grid>
                <Grid item xs={12} mb={5}>
                    <QuillWrapper className="full-width">
                        <InputLabel className="mb-5">Power quality(new for AHF SYNC)</InputLabel>
                        <ReactQuill
                            theme="snow"
                            value={descriptionAdditional}
                            onChange={(e) => {
                                handleChangeDescriptionAdditional(e);
                                onChange(e, 'descriptionAdditional')
                            }}
                        />
                    </QuillWrapper>
                </Grid>
                <Grid item xs={12} mb={5}>
                    <QuillWrapper className="full-width">
                        <InputLabel className="mb-5">QR-Code Description</InputLabel>
                        <ReactQuill
                            theme="snow"
                            value={qrCodeDescription}
                            onChange={(e) => {
                                handleChangeQrCodeDescription(e);
                                onChange(e, 'qrCodeDescription')
                            }}
                        />
                    </QuillWrapper>
                </Grid>
            </Grid>


        </React.Fragment>
    );
}


export default AdditionalProduct;
