import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab, Chip, Avatar
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import InlineMedia from "../../components/inline-media/InlineMedia";
import TabPanel from "../components/TabPanel";
import GeneralInfoProduct from "./fieldGroups/GeneralInfoProduct";
import AdditionalProduct from "./fieldGroups/AdditionalProduct";
import PromotionProduct from "./fieldGroups/PromotionProduct";
import ReferenceProduct from "./fieldGroups/ReferenceProduct";
import CompanyProduct from "./fieldGroups/CompanyProduct";
import VideoProduct from "./fieldGroups/VideoProduct";
import InlineContentElement from "../../components/inline-content-element/InlineContentElement";
import Tree from "../../components/tree/Tree";
import InlineTechnicalSpecifications from "./inline-technical-specifications/InlineTechnicalSpecifications";
import TechnicalSpecificationProduct from "./fieldGroups/TechnicalSpecificationProduct";
import FiltersProduct from "./fieldGroups/FiltersProduct";

// GraphQL
import {FetchResult} from "@apollo/client";
import {
    NewProductQuery,
    ProductQuery, useCreateProductMutation,
    useNewProductQuery,
    useProductQuery, useUpdateProductMutation,
    CreateProductMutation, useInlineMediaUploadProductMutation, InlineMediaUploadProductMutation
} from "../../graphql/product/product.graphql-gen";

// Types
import {MediaItemType} from "../../types/inlineMedia";
import {TechnicalSpecificationItemType} from "../../types/inlineTechnicalSpecificationsType";
import {ContentElementItemType} from "../../types/inlineContentElementType";


// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import RelatedProduct from "./fieldGroups/RelatedProduct";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";
import england from "../../assets/img/england.svg";
import germany from "../../assets/img/germany.svg";
import china from "../../assets/img/china.svg";
import MediaDownloads from "./fieldGroups/MediaDownloads";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);

const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    sku: Yup.string().required("Required"),
});

const Product: React.FC = () => {
    let title: string = 'New product';
    const model: string = 'Product';
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const inlineModels: string[] = ['images', 'headerImages', 'datasheetImage', 'performanceIndicatorsImage', 'typicalElectricalSchematicImages', 'cadData', 'dataSheet', 'installationManual', 'ogImage'];
    const navigate = useNavigate();
    const multipleSelect: boolean = true;

    // Queries
    const {data: data, error: error, loading: loading, refetch: singleViewData} = useProductQuery({
        variables: {id, model, technicalSpecificationModel: 'TechnicalSpecification'},
        skip: id === '',
        onCompleted: (data: ProductQuery) => {
            setImages(data.product.images);
            setMediaItems(data.product.images?.items);

            setHeaderImages(data.product.headerImages);
            setHeaderImagesItems(data.product.headerImages?.items);

            setOgImage(data.product.ogImage);
            setOgImageItems(data.product.ogImage?.items);

            setDatasheetImage(data.product.datasheetImage);
            setDatasheetImageItems(data.product.datasheetImage?.items);

            setPerformanceIndicatorsImage(data.product.performanceIndicatorsImage);
            setPerformanceIndicatorsImageItems(data.product.performanceIndicatorsImage?.items);

            setTypicalElectricalSchematicImages(data.product.typicalElectricalSchematicImages);
            setTypicalElectricalSchematicImagesItems(data.product.typicalElectricalSchematicImages?.items);

            setCadData(data.product.cadData);
            setCadDataItems(data.product.cadData?.items);

            setDataSheet(data.product.dataSheet);
            setDataSheetItems(data.product.dataSheet?.items);

            setInstallationManual(data.product.installationManual);
            setInstallationManualItems(data.product.installationManual?.items);

            setContentElements(data.product.contentElements);

            setTechnicalSpecifications(data.product.technicalSpecifications);

            if (data.product && data.product.product) {
                setState(data.product.product);
                setInicialValue({
                    "data": data.product.product,
                    "images": data.product.images?.items,
                    "headerImages": data.product.headerImages?.items,
                    "ogImage": data.product.ogImage?.items,
                    "datasheetImage": data.product.datasheetImage?.items,
                    "performanceIndicatorsImage": data.product.performanceIndicatorsImage?.items,
                    "typicalElectricalSchematicImages": data.product.typicalElectricalSchematicImages?.items,
                    "cadData": data.product.cadData?.items,
                    "dataSheet": data.product.dataSheet?.items,
                    "installationManual": data.product.installationManual?.items,
                    "contentElements": data.product.contentElements,
                    "technicalSpecifications": data.product.technicalSpecifications,
                    "checked": data.categoriesThree.checked
                });
            }

            setChecked(data.categoriesThree.checked);
            setExpanded(data.categoriesThree.expanded);

        }
    });
    const {data: dataNew, error: errorNew, loading: loadingNew} = useNewProductQuery({
        variables: {
            fields: inlineModels,
            model,
            technicalSpecificationModel: 'TechnicalSpecification'
        },
        skip: id !== '',
        onCompleted: (data: NewProductQuery) => {
            setState({});
            setInicialValue({});
            if (data.inlineMedia) {
                data.inlineMedia.map(item => {
                    switch (item.field) {
                        case 'images':
                            setImages(item.inlineMedia);
                            break;
                        case 'datasheetImage':
                            setDatasheetImage(item.inlineMedia);
                            break;
                        case 'headerImages':
                            setHeaderImages(item.inlineMedia);
                            break;
                        case 'ogImage':
                            setOgImage(item.inlineMedia);
                            break;
                        case 'performanceIndicatorsImage':
                            setPerformanceIndicatorsImage(item.inlineMedia);
                            break;
                        case 'typicalElectricalSchematicImages':
                            setTypicalElectricalSchematicImages(item.inlineMedia);
                            break;
                        case 'cadData':
                            setCadData(item.inlineMedia);
                            break;
                        case 'dataSheet':
                            setDataSheet(item.inlineMedia);
                            break;
                        case 'installationManual':
                            setInstallationManual(item.inlineMedia);
                            break;
                    }
                })
            }
        }
    });
    const [updateProduct] = useUpdateProductMutation();
    const [createProduct] = useCreateProductMutation();
    const [inlineMediaUpload] = useInlineMediaUploadProductMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [images, setImages] = useState<any>(dataObj.product ? dataObj.product.images : {});
    const [mediaItems, setMediaItems] = useState<any>(dataObj.product ? dataObj.product.images.items : []);

    const [headerImages, setHeaderImages] = useState<any>(dataObj.product ? dataObj.product.headerImages : {});
    const [headerImagesItems, setHeaderImagesItems] = useState<any>(dataObj.product ? dataObj.product.headerImages.items : []);
    const [ogImage, setOgImage] = useState<any>(dataObj.product ? dataObj.product.ogImage : {});
    const [ogImageItems, setOgImageItems] = useState<any>(dataObj.product ? dataObj.product.ogImage.items : []);

    const [datasheetImage, setDatasheetImage] = useState<any>(dataObj.product ? dataObj.product.datasheetImage : {});
    const [datasheetImageItems, setDatasheetImageItems] = useState<any>(dataObj.product ? dataObj.product.datasheetImage.items : []);

    const [performanceIndicatorsImage, setPerformanceIndicatorsImage] = useState<any>(dataObj.product ? dataObj.product.performanceIndicatorsImage : {});
    const [performanceIndicatorsImageItems, setPerformanceIndicatorsImageItems] = useState<any>(dataObj.product ? dataObj.product.performanceIndicatorsImage.items : []);

    const [typicalElectricalSchematicImages, setTypicalElectricalSchematicImages] = useState<any>(dataObj.product ? dataObj.product.typicalElectricalSchematicImages : {});
    const [typicalElectricalSchematicImagesItems, setTypicalElectricalSchematicImagesItems] = useState<any>(dataObj.product ? dataObj.product.typicalElectricalSchematicImages.items : []);

    const [cadData, setCadData] = useState<any>(dataObj.product ? dataObj.product.cadData : {});
    const [cadDataItems, setCadDataItems] = useState<any>(dataObj.product ? dataObj.product.cadData.items : []);

    const [dataSheet, setDataSheet] = useState<any>(dataObj.product ? dataObj.product.dataSheet : {});
    const [dataSheetItems, setDataSheetItems] = useState<any>(dataObj.product ? dataObj.product.dataSheet.items : []);

    const [installationManual, setInstallationManual] = useState<any>(dataObj.product ? dataObj.product.installationManual : {});
    const [installationManualItems, setInstallationManualItems] = useState<any>(dataObj.product ? dataObj.product.installationManual.items : []);

    const [contentElements, setContentElements] = useState<any>(dataObj.product ? dataObj.product.contentElements : []);
    const [technicalSpecifications, setTechnicalSpecifications] = useState<any>(dataObj.product ? dataObj.product.technicalSpecifications : []);

    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);

    const [checked, setChecked] = useState(dataObj.categoriesThree ? dataObj.categoriesThree.checked : []);
    const [expanded, setExpanded] = useState(dataObj.categoriesThree ? dataObj.categoriesThree.expanded : []);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [lang, setLang] = useState<string>('');
    const [isLang, setIsLang] = useState<boolean>(false);


    // Set loading
    if (loading || loadingNew || loader) {
        return <Loader/>
    }

    // Handle error
    if (error || errorNew) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data || dataNew) {
        dataObj = data || dataNew
    }

    // Set title
    if (dataObj.product && dataObj.product.product) {
        title = dataObj.product.product.title;
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveProduct = () => {
        if (id != '') {
            updateProductMutation(state);
        } else {
            createProductMutation(state);
        }
        setInicialValue({
            "data": state,
            "images": mediaItems,
            "headerImages": headerImagesItems,
            "ogImage": ogImageItems,
            "datasheetImage": datasheetImageItems,
            "performanceIndicatorsImage": performanceIndicatorsImageItems,
            "typicalElectricalSchematicImages": typicalElectricalSchematicImagesItems,
            "cadData": cadDataItems,
            "dataSheet": dataSheetItems,
            "installationManual": installationManualItems,
            "contentElements": contentElements,
            "technicalSpecifications": technicalSpecifications,
            "checked": checked
        });
    }

    // Update product mutation
    const updateProductMutation = (data: any) => {
        setLoader(true);
        updateProduct({
            variables: {
                model,
                id,
                data,
                categories: checked,
                images: mediaItems,
                headerImages: headerImagesItems,
                ogImage: ogImageItems,
                datasheetImage: datasheetImageItems,
                performanceIndicatorsImage: performanceIndicatorsImageItems,
                typicalElectricalSchematicImages: typicalElectricalSchematicImagesItems,
                cadData: cadDataItems,
                dataSheet: dataSheetItems,
                installationManual: installationManualItems,
                removedMedia,
                contentElements,
                technicalSpecifications
            }
        }).then(r => {
            setUpdated(true)
            setRemovedMedia([])

            setTimeout(() => {
                setUpdated(false);
            }, 3000);

            // Refetch data after update
            singleViewData().then(res => {
                setLoader(false);
            });

        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }

    // Create product mutation
    const createProductMutation = (data: any) => {
        createProduct({
            variables: {
                model,
                data,
                categories: checked,
                images: mediaItems,
                headerImages: headerImagesItems,
                ogImage: ogImageItems,
                datasheetImage: datasheetImageItems,
                performanceIndicatorsImage: performanceIndicatorsImageItems,
                typicalElectricalSchematicImages: typicalElectricalSchematicImagesItems,
                cadData: cadDataItems,
                dataSheet: dataSheetItems,
                installationManual: installationManualItems,
                removedMedia,
                contentElements,
                technicalSpecifications
            },
        }).then((res: FetchResult<CreateProductMutation>) => {
            if (res.data && res.data.createProduct.product) {
                setUpdated(true);
                navigate(`/products/${res.data.createProduct.product.id}`, {replace: true})
            }
        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }

    //Back to ListView
    const backToListView = () => {
        if (!isLang) {
            navigate(`/products`, {replace: true})
        } else {
            navigate(`/products/${lang}/${id}`, {replace: true})
        }
    }
    const openDialog = () => {

        const hasChanged = !shallowEqual(inicialValue, {
            "data": state,
            "images": mediaItems,
            "headerImages": headerImagesItems,
            "ogImage": ogImageItems,
            "datasheetImage": datasheetImageItems,
            "performanceIndicatorsImage": performanceIndicatorsImageItems,
            "typicalElectricalSchematicImages": typicalElectricalSchematicImagesItems,
            "cadData": cadDataItems,
            "dataSheet": dataSheetItems,
            "installationManual": installationManualItems,
            "contentElements": contentElements,
            "technicalSpecifications": technicalSpecifications,
            "checked": checked
        });

        setIsLang(false);
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }

    }
    const openTranslateDialog = (lang: any) => {
        const hasChanged = !shallowEqual(inicialValue, {
            "data": state, "images": mediaItems,
            "headerImages": headerImagesItems,
            "ogImage": ogImageItems,
            "datasheetImage": datasheetImageItems,
            "performanceIndicatorsImage": performanceIndicatorsImageItems,
            "typicalElectricalSchematicImages": typicalElectricalSchematicImagesItems,
            "cadData": cadDataItems,
            "dataSheet": dataSheetItems,
            "installationManual": installationManualItems,
            "contentElements": contentElements,
            "technicalSpecifications": technicalSpecifications,
            "checked": checked
        });
        setLang(lang);
        setIsLang(true);
        if (hasChanged) {
            setOpen(true);
        } else {
            navigate(`/products/${lang}/${id}`, {replace: true})
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName: any | string = '';
        let value: any;

        if (type) {
            fieldName = field;
            value = values
        } else {

            // Input fields not checkbox
            if (event.target && event.target.type !== 'checkbox') {
                fieldName = event.target.name;
                value = event.target.value;
            }

            // Input fields checkbox
            if (event.target && event.target.type === 'checkbox') {
                fieldName = event.target.name;
                // @ts-ignore
                value = event.target.checked;
            }

            if (field) {
                fieldName = field
                value = event

            }
        }

        // Update select value
        setState({...state, [fieldName]: value});

    };


    //------------------------ Inline media methods -------------------------------------

    // Add media
    const onAddMediaHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'images',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setMediaItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update media
    const onMediaUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = mediaItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        mediaItems[foundIndex] = updateData;
        setMediaItems(mediaItems)
    }

    // Remove media
    const onMediaRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setMediaItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }


    // Add header images
    const onAddHeaderImagesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'headerImages',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setHeaderImagesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update header images
    const onHeaderImagesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = headerImagesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        headerImagesItems[foundIndex] = updateData;
        setHeaderImagesItems(headerImagesItems);
    }

    // Remove header images
    const onHeaderImagesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setHeaderImagesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    // Add OG images
    const onAddOgImageHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'ogImage',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setOgImageItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update OG images
    const onOgImageUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = ogImageItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        ogImageItems[foundIndex] = updateData;
        setOgImageItems(ogImageItems);
    }

    // Remove OG images
    const onOgImageRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setOgImageItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }


    // Add datasheet
    const onAddDatasheetHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'datasheetImage',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setDatasheetImageItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update datasheet
    const onDatasheetUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = datasheetImageItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        datasheetImageItems[foundIndex] = updateData;
        setDatasheetImageItems(datasheetImageItems);
    }

    // Remove datasheet
    const onDatasheetRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setDatasheetImageItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }


    // Add performance indicators
    const onAddPerformanceIndicatorsImageHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'performanceIndicatorsImage',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setPerformanceIndicatorsImageItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update performance indicators
    const onPerformanceIndicatorsImageUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = performanceIndicatorsImageItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        performanceIndicatorsImageItems[foundIndex] = updateData;
        setPerformanceIndicatorsImageItems(performanceIndicatorsImageItems);
    }

    // Remove performance indicators
    const onPerformanceIndicatorsImageRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setPerformanceIndicatorsImageItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }


    // Add typical electrical schematic
    const onAddTypicalElectricalSchematicImagesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'typicalElectricalSchematicImages',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setTypicalElectricalSchematicImagesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update typical electrical schematic
    const onTypicalElectricalSchematicImagesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = typicalElectricalSchematicImagesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        typicalElectricalSchematicImagesItems[foundIndex] = updateData;
        setTypicalElectricalSchematicImagesItems(typicalElectricalSchematicImagesItems);
    }

    // Remove typical electrical schematic
    const onTypicalElectricalSchematicImagesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setTypicalElectricalSchematicImagesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }


    // Add cad data
    const onAddCadDataHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'cadData',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setCadDataItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update cad data
    const onCadDataUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = cadDataItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        cadDataItems[foundIndex] = updateData;
        setCadDataItems(cadDataItems);
    }

    // Remove cad data
    const onCadDataRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setCadDataItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    // Add data sheet
    const onAddDataSheetHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'dataSheet',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setDataSheetItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }
    // Update data sheet
    const onDataSheetUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = dataSheetItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        dataSheetItems[foundIndex] = updateData;
        setDataSheetItems(dataSheetItems);
    }
    // Remove data sheet
    const onDataSheetRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setDataSheetItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    // Add installation Manual
    const onAddInstallationManualHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'installationManual',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadProductMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setInstallationManualItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }
    // Update data sheet
    const onInstallationManualUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = installationManualItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        installationManualItems[foundIndex] = updateData;
        setInstallationManualItems(installationManualItems);
    }
    // Remove data sheet
    const onInstallationManualRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setInstallationManualItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }


    //------------------------ Inline content element methods --------------------------

    // Add content element
    const onAddContentElementHandler = (element: ContentElementItemType) => {
        setContentElements((prevState: any) => {
            if (element) {
                return [...prevState, element];
            }
        });
    }

    // Update content element
    const onContentElementUpdateHandler = (updateData: ContentElementItemType) => {
        const foundIndex = contentElements.findIndex((item: ContentElementItemType) => item.id == updateData.id);
        contentElements[foundIndex] = updateData;
        setContentElements(contentElements)
    }

    // Remove content element
    const onContentElementRemoveHandler = (id: number | string) => {
        setContentElements((prevState: ContentElementItemType[]) => prevState.filter(item => item.id !== id));
    }

    // Remove media from content element
    const onContentElementMediaRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
    }


    //------------------------ Inline technical specifications methods --------------------------

    // Add technical option
    const onAddTechnicalSpecificationHandler = (element: TechnicalSpecificationItemType) => {
        setTechnicalSpecifications((prevState: any) => {
            if (element) {
                return [...prevState, element];
            }
        });
    }

    // Update technical option
    const onTechnicalSpecificationUpdateHandler = (updateData: TechnicalSpecificationItemType) => {
        const foundIndex = technicalSpecifications.findIndex((item: TechnicalSpecificationItemType) => item.id == updateData.id);
        technicalSpecifications[foundIndex] = updateData;
        setTechnicalSpecifications(technicalSpecifications)
    }

    // Remove technical option
    const onTechnicalSpecificationRemoveHandler = (id: number | string) => {
        setTechnicalSpecifications((prevState: TechnicalSpecificationItemType[]) => prevState.filter(item => item.id !== id));
    }


    //------------------------------- Tabs methods -------------------------------------

    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    //--------------------------- Categories tree methods ------------------------------

    //Set tree handlerers
    const treeHandlerCheck = (checkedArr: any, targetNode: any) => {
        if (multipleSelect) {
            setChecked(checkedArr)
        } else {
            if (checkedArr.length != 0) {
                setChecked([targetNode.value.toString()])
            } else {
                setChecked([]);
            }
        }
    }
    const treeHandlerExpand = (event: any) => {
        setExpanded(event)
    }

    return (
        <React.Fragment>
            <Helmet title="Product"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        {id &&
                            <div>
                                <Chip className="lang-nav active" variant="outlined" avatar={<Avatar src={england}/>}
                                      label="En"/>
                                <Chip className="lang-nav" variant="outlined" onClick={(lang) => {
                                    openTranslateDialog('de')
                                }} clickable={true} avatar={<Avatar src={germany}/>} label="De"/>
                                <Chip className="lang-nav" variant="outlined" onClick={(lang) => {
                                    openTranslateDialog('cn')
                                }} clickable={true} avatar={<Avatar src={china}/>} label="Cn"/>
                            </div>
                        }
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveProduct}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wrapper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable"
                                                      allowScrollButtonsMobile>
                                                    <Tab label="General" {...tabsProps(0)} />
                                                    <Tab label="Related" {...tabsProps(1)} />
                                                    <Tab label="Additional" {...tabsProps(2)} />
                                                    <Tab label="Media" {...tabsProps(3)} />
                                                    <Tab label="SEO (Website)" {...tabsProps(4)} />
                                                    <Tab label="Reference" {...tabsProps(5)} />
                                                    <Tab label="Technical specifications" {...tabsProps(6)} />
                                                    <Tab label="Company (Datasheet)" {...tabsProps(7)} />
                                                    <Tab label="Filter Criteria (Website)" {...tabsProps(8)} />
                                                </Tabs>
                                            </div>

                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={1}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Categories
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Tree
                                                        data={dataObj.categoriesThree}
                                                        checked={checked}
                                                        expanded={expanded}
                                                        onCheck={treeHandlerCheck}
                                                        onExpand={treeHandlerExpand}
                                                    />
                                                    <Grid container spacing={6} mt={5}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10} mt={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <RelatedProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={2}>
                                                    <AdditionalProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={3}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Image
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={mediaItems}
                                                        fieldType={images.fieldType}
                                                        allowedExtensions={images.allowedExtensions}
                                                        isMultiple={images.isMultiple}
                                                        onAdd={onAddMediaHandler}
                                                        onUpdate={onMediaUpdateHandler}
                                                        onDelete={onMediaRemoveHandler}/>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Header images (Website)
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={headerImagesItems}
                                                        fieldType={headerImages.fieldType}
                                                        allowedExtensions={headerImages.allowedExtensions}
                                                        isMultiple={headerImages.isMultiple}
                                                        onAdd={onAddHeaderImagesHandler}
                                                        onUpdate={onHeaderImagesUpdateHandler}
                                                        onDelete={onHeaderImagesRemoveHandler}/>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Datasheet Image
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={datasheetImageItems}
                                                        fieldType={datasheetImage.fieldType}
                                                        allowedExtensions={datasheetImage.allowedExtensions}
                                                        isMultiple={datasheetImage.isMultiple}
                                                        onAdd={onAddDatasheetHandler}
                                                        onUpdate={onDatasheetUpdateHandler}
                                                        onDelete={onDatasheetRemoveHandler}/>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Performance indicators
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={performanceIndicatorsImageItems}
                                                        fieldType={performanceIndicatorsImage.fieldType}
                                                        allowedExtensions={performanceIndicatorsImage.allowedExtensions}
                                                        isMultiple={performanceIndicatorsImage.isMultiple}
                                                        onAdd={onAddPerformanceIndicatorsImageHandler}
                                                        onUpdate={onPerformanceIndicatorsImageUpdateHandler}
                                                        onDelete={onPerformanceIndicatorsImageRemoveHandler}/>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Typical electrical schematic
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={typicalElectricalSchematicImagesItems}
                                                        fieldType={typicalElectricalSchematicImages.fieldType}
                                                        allowedExtensions={typicalElectricalSchematicImages.allowedExtensions}
                                                        isMultiple={typicalElectricalSchematicImages.isMultiple}
                                                        onAdd={onAddTypicalElectricalSchematicImagesHandler}
                                                        onUpdate={onTypicalElectricalSchematicImagesUpdateHandler}
                                                        onDelete={onTypicalElectricalSchematicImagesRemoveHandler}/>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>


                                                    <VideoProduct
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10} mt={5}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                CAD-Files
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={cadDataItems}
                                                        fieldType={cadData.fieldType}
                                                        allowedExtensions={cadData.allowedExtensions}
                                                        isMultiple={cadData.isMultiple}
                                                        onAdd={onAddCadDataHandler}
                                                        onUpdate={onCadDataUpdateHandler}
                                                        onDelete={onCadDataRemoveHandler}/>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10} mt={5}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Data sheet
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={dataSheetItems}
                                                        fieldType={dataSheet.fieldType}
                                                        allowedExtensions={dataSheet.allowedExtensions}
                                                        isMultiple={dataSheet.isMultiple}
                                                        onAdd={onAddDataSheetHandler}
                                                        onUpdate={onDataSheetUpdateHandler}
                                                        onDelete={onDataSheetRemoveHandler}/>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10} mt={5}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Installation Manual
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={installationManualItems}
                                                        fieldType={installationManual.fieldType}
                                                        allowedExtensions={installationManual.allowedExtensions}
                                                        isMultiple={installationManual.isMultiple}
                                                        onAdd={onAddInstallationManualHandler}
                                                        onUpdate={onInstallationManualUpdateHandler}
                                                        onDelete={onInstallationManualRemoveHandler}/>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10} mt={5}/>
                                                        </Grid>
                                                    </Grid>
                                                    <MediaDownloads
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />

                                                </TabPanel>
                                                <TabPanel value={tabValue} index={4}>
                                                    <PromotionProduct
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Storytelling for product promotions
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineContentElement
                                                        items={contentElements}
                                                        onAdd={onAddContentElementHandler}
                                                        onUpdate={onContentElementUpdateHandler}
                                                        onDelete={onContentElementRemoveHandler}
                                                        onMediaDelete={onContentElementMediaRemoveHandler}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                OG image (Website)
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={ogImageItems}
                                                        fieldType={ogImage.fieldType}
                                                        allowedExtensions={ogImage.allowedExtensions}
                                                        isMultiple={ogImage.isMultiple}
                                                        onAdd={onAddOgImageHandler}
                                                        onUpdate={onOgImageUpdateHandler}
                                                        onDelete={onOgImageRemoveHandler}/>

                                                </TabPanel>
                                                <TabPanel value={tabValue} index={5}>
                                                    <ReferenceProduct
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={6}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Technical specifications
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineTechnicalSpecifications
                                                        items={technicalSpecifications}
                                                        types={dataObj.getTechnicalSpecificationSelect}
                                                        onAdd={onAddTechnicalSpecificationHandler}
                                                        onUpdate={onTechnicalSpecificationUpdateHandler}
                                                        onDelete={onTechnicalSpecificationRemoveHandler}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10} mt={5}/>
                                                        </Grid>
                                                    </Grid>

                                                    <TechnicalSpecificationProduct
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={7}>
                                                    <CompanyProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={8}>
                                                    <FiltersProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Saved successfully" open={updated}/>
            }
            <ConfirmationDialog
                message="Are you sure you want to leave without saving?"
                title="Leave page"
                open={open}
                button="Confirm"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default Product;
