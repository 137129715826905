import React from "react";
import {Grid} from "@mui/material";
import TechnicalSpecificationItemTranslate from "./TechnicalSpecificationItemTranslate";

// Types
import {
    InlineTechnicalSpecificationType,
    TechnicalSpecificationItemType
} from "../../../types/inlineTechnicalSpecificationsType";



const InlineTechnicalSpecificationsTranslate: React.FC<InlineTechnicalSpecificationType> = (props) => {
    const {onUpdate,  types} = props;

    // Trigger element update
    const onUpdateHandler = (data: any) => {
        onUpdate(data);
    }

    return (
        <React.Fragment>
            <Grid container spacing={6}>
                {props.items.length > 0 &&
                    <Grid item xs={12}>
                        {props.items.map((item: TechnicalSpecificationItemType) => {
                            return (
                                <TechnicalSpecificationItemTranslate
                                    key={item.id}
                                    item={item}
                                    types={types}
                                    onUpdate={onUpdateHandler}
                                />
                            )
                        })}
                    </Grid>
                }

            </Grid>
        </React.Fragment>
    );
}

export default InlineTechnicalSpecificationsTranslate;
