import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert
} from "@mui/material";
import {Formik} from "formik";
import {useNavigate, useParams} from "react-router-dom";
import GeneralInfoDynamicSelect from "./fieldGroups/GeneralInfoDynamicSelect";
import DynamicOptions from "./dynamic-options/DynamicOptions";
import SnackbarNotification from "../../../components/SnackbarNotification";
import Loader from "../../../components/Loader";
import {FetchResult} from "@apollo/client";

// GraphQL
import {
    DynamicSelectQuery, UpdateDynamicSelectMutation,
    useDynamicSelectQuery, useUpdateDynamicSelectMutation
} from "../../../graphql/settings/dynamic-select/dynamic-select.graphql-gen";


//Types
import {DynamicSelectOption} from "../../../types/dynamicSelectOption";


// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";



const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const DynamicSelect: React.FC = () => {
    let title: string = '';
    let dataObj: any = {};
    const params = useParams();
    const id: string = params.id ? params.id : '';
    const navigate = useNavigate();

    // Queries
    const {data, error, loading} = useDynamicSelectQuery({
        variables: {id},
        onCompleted: (data: DynamicSelectQuery) => {
            if (data && data.getSelectField) {
                setState(data.getSelectField.selectField);

                if (data.getSelectField.options) {
                    setOptions(data.getSelectField.options);
                    setInicialValue(data.getSelectField.options);
                }
            }
        }
    });
    const [updateDynamicSelect] = useUpdateDynamicSelectMutation();

    // States
    const [updated, setUpdated] = useState(false);

    const [state, setState] = useState<any>();
    const [options, setOptions] = useState<any>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();

    // Set loading
    if (loading) {
        return <Loader/>
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data) {
        dataObj = data
    }

    // Set title
    if (dataObj.getSelectField) {
        title = dataObj.getSelectField.selectField.field;
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveDynamicSelect = () => {
        updateDynamicSelectMutation();
        setInicialValue(state);
    }

    // Update mutation
    const updateDynamicSelectMutation = () => {

        updateDynamicSelect({
            variables: {
                id,
                options
            }
        })
            .then((res: FetchResult<UpdateDynamicSelectMutation>) => {
                setUpdated(true)

                if (res && res.data) {
                    setOptions(res.data.updateSelectField.options)
                }

                setTimeout(() => {
                    setUpdated(false);
                }, 3000)

            })
            .catch(error => {
                return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
            });
    }

    //Back to ListView
    const backToListView = () => {
        navigate(`/settings/selects`, {replace: true})
    }
    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, state);
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    const onAddHandler = (newOption: DynamicSelectOption) => {
        setOptions((prevState: DynamicSelectOption[]) => [...prevState, newOption])
    }

    // This function is called when the select changes
    const updateHandler = (updateData: DynamicSelectOption) => {

        if (updateData.sorting) {
            updateData.sorting = +updateData.sorting;
        }

        const foundIndex = options.findIndex((item: DynamicSelectOption) => item.id === updateData.id);
        options[foundIndex] = updateData;
        setOptions(options);
    };

    // This function is called when the select changes
    const deleteHandler = (id: string) => {
        const newOptions = options.filter((item: DynamicSelectOption) => item.id !== id);
        setOptions(newOptions);
    };

    return (
        <React.Fragment>
            <Helmet title="Dynamic select"/>

            <Grid justifyContent="space-between" container spacing={10} className="page-top">
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6} className="top-divider"/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    onSubmit={onSaveDynamicSelect}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="scrollable-content no-tabs">
                                                <GeneralInfoDynamicSelect
                                                    values={values}
                                                />

                                                <DynamicOptions
                                                    options={options}
                                                    onAdd={onAddHandler}
                                                    onUpdate={updateHandler}
                                                    onDelete={deleteHandler}/>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Saved successfully" open={updated}/>
            }
            <ConfirmationDialog
                message="Are you sure you want to leave without saving?"
                title="Leave page"
                open={open}
                button="Confirm"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default DynamicSelect;
