import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    InputLabel,
    Checkbox,
    Autocomplete, FormGroup, FormControlLabel, FormControl, Select, MenuItem,
} from "@mui/material";

import ReactQuill from "react-quill";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {min-height: 200px;}`;


const GeneralInfoArticle: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const platforms: { id: string, title: string }[] = data.selectPlatforms;
    const products: { id: string, title: string }[] = data.selectProduct;
    const phases: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'phases');


    // States
    const [selectedPlatforms, setSelectedPlatforms] = useState<any>(state.platforms ? state.platforms.map((id: string) => platforms.filter((item) => item.id === id)[0]) : (values.platforms ? values.platforms.map((id: string) => platforms.filter((item) => item.id === id)[0]) : []));
    const [valueExportWeb, setValueExportWeb] = useState<boolean>(values.exportWeb?.length == 0 || !values.exportWeb ? false : values.exportWeb);
    //const [description, setDescription] = useState(state.description ? state.description : (values.description ? values.description : ''));
    const [selectedProduct, setSelectedProduct] = useState<any[]>(state.product ? state.product  : (values.product ? values.product[0] : ''));
    const [selectedPhases, setSelectedPhases] = useState<any[]>(state.phases ? state.phases  : (values.phases ? values.phases[0] : ''));

    //------------------------------- Public methods -------------------------------------


    // Update description on change
    // const handleChangeDescription = (event: any) => {
    //     event.toString('html');
    //     setDescription(event);
    // };

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations
            </Typography>

            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={4} mb={5}>
                    <TextField
                        name="articleNo"
                        label="Article Number *"
                        value={values.articleNo ? values.articleNo : ''}
                        error={Boolean(touched.articleNo && errors.articleNo)}
                        fullWidth
                        helperText={touched.articleNo && errors.articleNo}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="sorting"
                        label="Sorting"
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        value={values.sorting ? values.sorting : ''}
                        error={Boolean(touched.sorting && errors.sorting)}
                        fullWidth
                        helperText={touched.sorting && errors.sorting}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                name="exportWeb"
                                checked={valueExportWeb}
                                onChange={(e) => {
                                    setValueExportWeb(e.target.checked);
                                    onChange(e)
                                }}
                            />
                        } label="Export to Web"/>
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="title"
                        label="Title *"
                        value={values.title ? values.title : ''}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Product</InputLabel>
                        <Select
                            name="product"
                            value={selectedProduct}
                            label="Product"
                            onChange={(event: any) => {
                                setSelectedProduct(event.target.value);
                                onChange(event)
                            }}
                        >
                            {products.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="type"
                        label="Type"
                        value={values.type ? values.type : ''}
                        error={Boolean(touched.type && errors.type)}
                        fullWidth
                        helperText={touched.type && errors.type}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="application"
                        label="Application"
                        value={values.application ? values.application : ''}
                        error={Boolean(touched.application && errors.application)}
                        fullWidth
                        helperText={touched.application && errors.application}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="eanUpc"
                        label="EAN/UPC"
                        value={values.eanUpc ? values.eanUpc : ''}
                        error={Boolean(touched.eanUpc && errors.eanUpc)}
                        fullWidth
                        helperText={touched.eanUpc && errors.eanUpc}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="buyLink"
                        label="Buy link"
                        value={values.buyLink ? values.buyLink : ''}
                        error={Boolean(touched.buyLink && errors.buyLink)}
                        fullWidth
                        helperText={touched.buyLink && errors.buyLink}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>

            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={6} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Phases</InputLabel>
                        <Select
                            name="phases"
                            value={selectedPhases}
                            label="Phases"
                            onChange={(event: any) => {
                                setSelectedPhases(event.target.value);
                                onChange(event)
                            }}
                        >
                            {phases.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={6} mb={5}>
                    <Autocomplete
                        multiple
                        options={platforms}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title ? option.title : ''}
                        defaultValue={selectedPlatforms || []}
                        value={selectedPlatforms || []}
                        onChange={(event: any, value: any) => {
                            setSelectedPlatforms(value)
                            onChange(event, 'platforms', 'autocomplete', value.map((item: any) => item.id))
                        }}
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Platforms (Website) " placeholder="Platforms (Website)"
                                       name="platforms"/>
                        )}
                    />

                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} mb={5}>
                    <TextField
                        label="Description"
                        name="description"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.description ? values.description : ''}
                        onChange={onChange}
                    />
                </Grid>
                {/*<Grid item xs={12} mb={5}>*/}
                {/*    <QuillWrapper className="full-width">*/}
                {/*        <InputLabel className="mb-5">Description</InputLabel>*/}
                {/*        <ReactQuill*/}
                {/*            theme="snow"*/}
                {/*            value={description}*/}
                {/*            onChange={(e) => {*/}
                {/*                handleChangeDescription(e);*/}
                {/*                onChange(e, 'description')*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </QuillWrapper>*/}
                {/*</Grid>*/}
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoArticle;
