import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    InputLabel, FormControl, Select, MenuItem, Divider as MuiDivider,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";

const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const PQArticle: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const ratedLoadPowerUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'ratedLoadPowerUnit');
    const motorDriveInputCurrentUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'motorDriveInputCurrentUnit');
    const ratedFilterInputFilterUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'ratedFilterInputFilterUnit');
    const ldcUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'ldcUnit');
    const lacUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'lacUnit');
    const nominalInductanceUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'nominalInductanceUnit');
    const nominalCapacitanceUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'nominalCapacitanceUnit');

    const ratedLoadPowerUnitsDefault: { id: string }[] = ratedLoadPowerUnits.filter((item: any) => item.default);
    const motorDriveInputCurrentUnitsDefault: { id: string }[] = motorDriveInputCurrentUnits.filter((item: any) => item.default);
    const ratedFilterInputFilterUnitsDefault: { id: string }[] = ratedFilterInputFilterUnits.filter((item: any) => item.default);
    const ldcUnitsDefault: { id: string }[] = ldcUnits.filter((item: any) => item.default);
    const lacUnitsDefault: { id: string }[] = lacUnits.filter((item: any) => item.default);
    const nominalInductanceUnitsDefault: { id: string }[] = nominalInductanceUnits.filter((item: any) => item.default);
    const nominalCapacitanceUnitsDefault: { id: string }[] = nominalCapacitanceUnits.filter((item: any) => item.default);

    // States
    const [ratedLoadPowerUnit, setRatedLoadPowerUnit] = useState(state.ratedLoadPowerUnit ? ( state.ratedLoadPowerUnit.length == 0 ? ratedLoadPowerUnitsDefault[0]?.id : state.ratedLoadPowerUnit) : (values.ratedLoadPowerUnit ? values.ratedLoadPowerUnit[0] : ratedLoadPowerUnitsDefault[0]?.id));
    const [motorDriveInputCurrentUnit, setMotorDriveInputCurrentUnit] = useState(state.motorDriveInputCurrentUnit ? ( state.motorDriveInputCurrentUnit.length == 0 ? motorDriveInputCurrentUnitsDefault[0]?.id : state.motorDriveInputCurrentUnit) : (values.motorDriveInputCurrentUnit ? values.motorDriveInputCurrentUnit[0] : motorDriveInputCurrentUnitsDefault[0]?.id));
    const [ratedFilterInputFilterUnit, setRatedFilterInputFilterUnit] = useState(state.ratedFilterInputFilterUnit ? ( state.ratedFilterInputFilterUnit.length == 0 ? ratedFilterInputFilterUnitsDefault[0]?.id : state.ratedFilterInputFilterUnit) : (values.ratedFilterInputFilterUnit ? values.ratedFilterInputFilterUnit[0] : ratedFilterInputFilterUnitsDefault[0]?.id));
    const [ldcUnit, setLdcUnit] = useState(state.ldcUnit ? ( state.ldcUnit.length == 0 ? ldcUnitsDefault[0]?.id : state.ldcUnit) : (values.ldcUnit ? values.ldcUnit[0] : ldcUnitsDefault[0]?.id));
    const [lacUnit, setLacUnit] = useState(state.lacUnit ? ( state.lacUnit.length == 0 ? lacUnitsDefault[0]?.id : state.lacUnit) : (values.lacUnit ? values.lacUnit[0] : lacUnitsDefault[0]?.id));
    const [nominalInductanceUnit, setNominalInductanceUnit] = useState(state.nominalInductanceUnit ? ( state.nominalInductanceUnit.length == 0 ? nominalInductanceUnitsDefault[0]?.id : state.nominalInductanceUnit) : (values.nominalInductanceUnit ? values.nominalInductanceUnit[0] : nominalInductanceUnitsDefault[0]?.id));
    const [nominalCapacitanceUnit, setNominalCapacitanceUnit] = useState(state.nominalCapacitanceUnit ? ( state.nominalCapacitanceUnit.length == 0 ? nominalCapacitanceUnitsDefault[0]?.id : state.nominalCapacitanceUnit) : (values.nominalCapacitanceUnit ? values.nominalCapacitanceUnit[0] : nominalCapacitanceUnitsDefault[0]?.id));

    //------------------------------- Public methods -------------------------------------



    return (
        <React.Fragment>
            <Grid container spacing={6} >
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="ratedLoadPower"
                        label="Rated load power"
                        value={values.ratedLoadPower ? values.ratedLoadPower : ''}
                        error={Boolean(touched.ratedLoadPower && errors.ratedLoadPower)}
                        fullWidth
                        helperText={touched.ratedLoadPower && errors.ratedLoadPower}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Rated load power Unit</InputLabel>
                        <Select
                            name="ratedLoadPowerUnit"
                            value={ratedLoadPowerUnit}
                            label="Rated load power Unit"
                            onChange={(event: any) => {
                                setRatedLoadPowerUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {ratedLoadPowerUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6} >
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="motorDriveInputCurrent"
                        label="Motor drive input current"
                        value={values.motorDriveInputCurrent ? values.motorDriveInputCurrent : ''}
                        error={Boolean(touched.motorDriveInputCurrent && errors.motorDriveInputCurrent)}
                        fullWidth
                        helperText={touched.motorDriveInputCurrent && errors.motorDriveInputCurrent}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Motor drive input current Unit</InputLabel>
                        <Select
                            name="motorDriveInputCurrentUnit"
                            value={motorDriveInputCurrentUnit}
                            label="Motor drive input current Unit"
                            onChange={(event: any) => {
                                setMotorDriveInputCurrentUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {motorDriveInputCurrentUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6} >
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="ratedFilterInputFilter"
                        label="Rated filter input filter"
                        value={values.ratedFilterInputFilter ? values.ratedFilterInputFilter : ''}
                        error={Boolean(touched.ratedFilterInputFilter && errors.ratedFilterInputFilter)}
                        fullWidth
                        helperText={touched.ratedFilterInputFilter && errors.ratedFilterInputFilter}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Rated filter input filter Unit</InputLabel>
                        <Select
                            name="ratedFilterInputFilterUnit"
                            value={ratedFilterInputFilterUnit}
                            label="Rated filter input filter Unit"
                            onChange={(event: any) => {
                                setRatedFilterInputFilterUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {ratedFilterInputFilterUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="ldc"
                        label="Min. required LDC"
                        value={values.ldc ? values.ldc : ''}
                        error={Boolean(touched.ldc && errors.ldc)}
                        fullWidth
                        helperText={touched.ldc && errors.ldc}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Min. required LDC Unit</InputLabel>
                        <Select
                            name="ldcUnit"
                            value={ldcUnit}
                            label="Min. required LDC Unit"
                            onChange={(event: any) => {
                                setLdcUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {ldcUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="lac"
                        label="Min. required LAC"
                        value={values.lac ? values.lac : ''}
                        error={Boolean(touched.lac && errors.lac)}
                        fullWidth
                        helperText={touched.lac && errors.lac}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Min. required LAC Unit</InputLabel>
                        <Select
                            name="lacUnit"
                            value={lacUnit}
                            label="Min. required LAC Unit"
                            onChange={(event: any) => {
                                setLacUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {lacUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>


            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="terminal"
                        label="Terminal"
                        value={values.terminal ? values.terminal : ''}
                        error={Boolean(touched.terminal && errors.terminal)}
                        fullWidth
                        helperText={touched.terminal && errors.terminal}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="nominalInductance"
                        label="Nominal Inductance"
                        value={values.nominalInductance ? values.nominalInductance : ''}
                        error={Boolean(touched.nominalInductance && errors.nominalInductance)}
                        fullWidth
                        helperText={touched.nominalInductance && errors.nominalInductance}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Nominal Inductance Unit</InputLabel>
                        <Select
                            name="nominalInductanceUnit"
                            value={nominalInductanceUnit}
                            label="Nominal Inductance Unit"
                            onChange={(event: any) => {
                                setNominalInductanceUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {nominalInductanceUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="nominalCapacitance"
                        label="Nominal capacitance"
                        value={values.nominalCapacitance ? values.nominalCapacitance : ''}
                        error={Boolean(touched.nominalCapacitance && errors.nominalCapacitance)}
                        fullWidth
                        helperText={touched.nominalCapacitance && errors.nominalCapacitance}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Nominal capacitance Unit</InputLabel>
                        <Select
                            name="nominalCapacitanceUnit"
                            value={nominalCapacitanceUnit}
                            label="Nominal capacitance Unit"
                            onChange={(event: any) => {
                                setNominalCapacitanceUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {nominalCapacitanceUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>



        </React.Fragment>
    );
}


export default PQArticle;
