import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab, Chip, Avatar
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../../components/Loader";
import SnackbarNotification from "../../../components/SnackbarNotification";
import InlineMedia from "../../../components/inline-media/InlineMedia";
import TabPanel from "../../components/TabPanel";

// GraphQL
import {FetchResult} from "@apollo/client";
import {
    ApprovalQuery,
    NewApprovalQuery,
    useApprovalQuery,
    useCreateApprovalMutation,
    useNewApprovalQuery,
    useUpdateApprovalMutation,
    CreateApprovalMutation,
    useInlineMediaUploadApprovalMutation, InlineMediaUploadApprovalMutation
} from "../../../graphql/settings/approval/approval.graphql-gen";
import {
    CreateDownloadMutation,
    DownloadQuery, InlineMediaUploadDownloadMutation, NewDownloadQuery, useCreateDownloadMutation,
    useDownloadQuery, useInlineMediaUploadDownloadMutation,
    useNewDownloadQuery, useUpdateDownloadMutation
} from "../../../graphql/settings/download/download.graphql-gen";

// Types
import {MediaItemType} from "../../../types/inlineMedia";

// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import GeneralInfoDownload from "./fieldGroups/GeneralInfoDownload";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";
import england from "../../../assets/img/england.svg";
import germany from "../../../assets/img/germany.svg";
import china from "../../../assets/img/china.svg";

const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
});

const Download: React.FC = () => {
    let title: string = 'New download'
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const inlineModels: string[] = ['file'];
    const navigate = useNavigate();
    const model: string = 'Download';

    // Queries
    const {data: data, error: error, loading: loading} = useDownloadQuery({
        variables: {id, model},
        skip: id === '',
        onCompleted: (data: DownloadQuery) => {
            setFile(data.download.file);
            setFileItems(data.download.file?.items);
            if (data.download && data.download.download) {
                setState(data.download.download);
                setInicialValue({
                    "data": data.download.download,
                    "file": data.download.file?.items
                });
            }
        }
    });
    const {data: dataNew, error: errorNew, loading: loadingNew} = useNewDownloadQuery({
        variables: {
            fields: inlineModels,
            model,
        },
        skip: id !== '',
        onCompleted: (data: NewDownloadQuery) => {
            setState({});
            setInicialValue({})
            if (data.inlineMedia) {
                data.inlineMedia.map(item => {
                    switch (item.field) {
                        case 'file':
                            setFile(item.inlineMedia);
                            break;
                    }
                })
            }
        }
    });
    const [updateDownload] = useUpdateDownloadMutation();
    const [createDownload] = useCreateDownloadMutation();
    const [inlineMediaUpload] = useInlineMediaUploadDownloadMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [file, setFile] = useState<any>(dataObj.download ? dataObj.download.file : {});
    const [fileItems, setFileItems] = useState<any>(dataObj.download ? dataObj.download.file.items : []);
    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [lang, setLang] = useState<string>('');
    const [isLang, setIsLang] = useState<boolean>(false);

    // Set loading
    if (loading || loadingNew || loader) {
        return <Loader/>
    }

    // Handle error
    if (error || errorNew) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data || dataNew) {
        dataObj = data || dataNew
    }

    // Set title
    if (dataObj.download && dataObj.download.download) {
        title = dataObj.download.download.title;
    }

    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveDownload = () => {
        if (id !== '') {
            updateDownloadMutation(state);
        } else {
            createDownloadMutation(state);
        }
        setInicialValue({
            "data": state, "file": fileItems
        });
    }

    // Update download mutation
    const updateDownloadMutation = (data: any) => {
        updateDownload({
            variables: {
                model,
                id,
                data,
                file: fileItems,
                removedMedia
            }
        }).then(r => {
            setUpdated(true)
            setRemovedMedia([])

            setTimeout(() => {
                setUpdated(false);
            }, 3000)

        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }

    // Create download mutation
    const createDownloadMutation = (data: any) => {
        createDownload({
            variables: {
                model,
                data,
                file: fileItems,
                removedMedia
            },
        })
            .then((res: FetchResult<CreateDownloadMutation>) => {
                if (res.data && res.data.createDownload.download) {
                    setUpdated(true);
                    navigate(`/settings/downloads/${res.data.createDownload.download.id}`, {replace: true})
                }
            })
            .catch(error => {
                return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
            });
    }

    //Back to ListView
    const backToListView = () => {
        if(!isLang){
            navigate(`/settings/downloads`, {replace: true})
        }else{
            navigate(`/settings/downloads/${lang}/${id}`, {replace: true})
        }
    }
    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, {"data": state, "file": fileItems});
        setIsLang(false);
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    const openTranslateDialog = (lang: any) => {
        const hasChanged = !shallowEqual(inicialValue, {"data": state, "file": fileItems});
        setLang(lang);
        setIsLang(true);
        if (hasChanged) {
            setOpen(true);
        } else {
            navigate(`/settings/downloads/${lang}/${id}`, {replace: true})
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }


    const onAddFileHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'file',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadDownloadMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFileItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update media
    const onFileUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = fileItems.findIndex((item: MediaItemType) => item.id === updateData.id);
        fileItems[foundIndex] = updateData;
        setFileItems(fileItems)
    }

    // Remove media
    const onFileRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setFileItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?:any[]) => {
        let fieldName: any | string = '';
        let value: any;

        if (type) {
            fieldName = field;
            value = values
        }else{

            // Input fields not checkbox
            if (event.target && event.target.type !== 'checkbox')  {
                fieldName = event.target.name;
                value = event.target.value;
            }

            // Input fields checkbox
            if (event.target && event.target.type === 'checkbox')  {
                fieldName = event.target.name;
                // @ts-ignore
                value = event.target.checked;
            }

            if (field) {
                fieldName = field
                value = event

            }
        }

        // Update select value
        setState({...state, [fieldName]: value});

    };

    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <React.Fragment>
            <Helmet title="Download"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        {id &&
                            <div>
                                <Chip className="lang-nav active"  variant="outlined" avatar={<Avatar src={england} />} label="En" />
                                <Chip className="lang-nav" variant="outlined" onClick={(lang) => {openTranslateDialog('de') }} clickable={true} avatar={<Avatar src={germany} />} label="De" />
                                <Chip className="lang-nav" variant="outlined" onClick={(lang) => {openTranslateDialog('cn') }} clickable={true} avatar={<Avatar src={china} />} label="Cn" />
                            </div>
                        }
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveDownload}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wraper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" allowScrollButtonsMobile>
                                                    <Tab label="General" {...tabsProps(0)} />
                                                    <Tab label="Media" {...tabsProps(1)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoDownload
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={1}>


                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                File
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={fileItems}
                                                        fieldType={file.fieldType}
                                                        allowedExtensions={file.allowedExtensions}
                                                        isMultiple={file.isMultiple}
                                                        onAdd={onAddFileHandler}
                                                        onUpdate={onFileUpdateHandler}
                                                        onDelete={onFileRemoveHandler}/>
                                                </TabPanel>

                                            </div>

                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Saved successfully" open={updated}/>
            }
            <ConfirmationDialog
                message="Are you sure you want to leave without saving?"
                title="Leave page"
                open={open}
                button="Confirm"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default Download;
