import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab, Chip, Avatar, Link
} from "@mui/material";

import {Formik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import InlineMedia from "../../components/inline-media/InlineMedia";
import TabPanel from "../components/TabPanel";
import GeneralInfoArticle from "./fieldGroups/GeneralInfoArticle";
import ElectricArticle from "./fieldGroups/ElectricArticle";
import AttenuationArticle from "./fieldGroups/AttenuationArticle";
import PQArticle from "./fieldGroups/PQArticle";

// GraphQL
import {FetchResult} from "@apollo/client";
import {
    ArticleQuery,
    CreateArticleMutation, InlineMediaUploadArticleMutation, NewArticleQuery,
    useArticleQuery, useCreateArticleMutation, useInlineMediaUploadArticleMutation,
    useNewArticleQuery,
    useUpdateArticleMutation
} from "../../graphql/article/article.graphql-gen";

// Types
import {MediaItemType} from "../../types/inlineMedia";

// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {ContentElementItemType} from "../../types/inlineContentElementType";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";
import MechanicArticle from "./fieldGroups/MechanicArticle";
import england from "../../assets/img/england.svg";
import germany from "../../assets/img/germany.svg";
import china from "../../assets/img/china.svg";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);


const validationSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    articleNo: Yup.string().required("Required"),
});

const Article: React.FC = () => {
    let title: string = 'New article'
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const inlineModels: string[] = ['images', 'graphImages', 'mechanicalImages', 'busbarConnectionImages', 'files'];
    const navigate = useNavigate();
    const model: string = 'Article';
    const multipleSelect: boolean = false;

    // Queries
    const {data: data, error: error, loading: loading} = useArticleQuery({
        variables: {id, model},
        skip: id === '',
        onCompleted: (data: ArticleQuery) => {
            setImages(data.article.images);
            setMediaItems(data.article.images?.items);

            setGraphImages(data.article.graphImages);
            setGraphImagesItems(data.article.graphImages?.items);

            setMechanicalImages(data.article.mechanicalImages);
            setMechanicalImagesItems(data.article.mechanicalImages?.items);

            setBusbarConnectionImages(data.article.busbarConnectionImages);
            setBusbarConnectionImagesItems(data.article.busbarConnectionImages?.items);

            setFiles(data.article.files);
            setFilesItems(data.article.files?.items);

            // console.log(data.article.article?.pdfLink);

            if (data.article && data.article.article) {
                setState(data.article.article);
                setInicialValue({
                    "data": data.article.article,
                    "images": data.article.images?.items,
                    "graphImages": data.article.graphImages?.items,
                    "mechanicalImages": data.article.mechanicalImages?.items,
                    "busbarConnectionImages": data.article.busbarConnectionImages?.items,
                    "files": data.article.files?.items,
                });
            }
        }
    });
    const {data: dataNew, error: errorNew, loading: loadingNew} = useNewArticleQuery({
        variables: {
            fields: inlineModels,
            model
        },
        skip: id !== '',
        onCompleted: (data: NewArticleQuery) => {
            setState({});
            setInicialValue({});
            if (data.inlineMedia) {
                data.inlineMedia.map(item => {
                    switch (item.field) {
                        case 'images':
                            setImages(item.inlineMedia);
                            break;
                        case 'graphImages':
                            setGraphImages(item.inlineMedia);
                            break;
                        case 'mechanicalImages':
                            setMechanicalImages(item.inlineMedia);
                            break;
                        case 'busbarConnectionImages':
                            setBusbarConnectionImages(item.inlineMedia);
                            break;
                        case 'files':
                            setFiles(item.inlineMedia);
                            break;
                    }
                })
            }
        }
    });
    const [updateArticle] = useUpdateArticleMutation();
    const [createArticle] = useCreateArticleMutation();
    const [inlineMediaUpload] = useInlineMediaUploadArticleMutation();

    // States
    const [updated, setUpdated] = useState(false);
    const [images, setImages] = useState<any>(dataObj.article ? dataObj.article.images : {});
    const [mediaItems, setMediaItems] = useState<any>(dataObj.article ? dataObj.article.images.items : []);
    const [graphImages, setGraphImages] = useState<any>(dataObj.article ? dataObj.article.graphImages : {});
    const [graphImagesItems, setGraphImagesItems] = useState<any>(dataObj.article ? dataObj.article.graphImages.items : []);
    const [mechanicalImages, setMechanicalImages] = useState<any>(dataObj.article ? dataObj.article.mechanicalImages : {});
    const [mechanicalImagesItems, setMechanicalImagesItems] = useState<any>(dataObj.article ? dataObj.article.mechanicalImages.items : []);
    const [busbarConnectionImages, setBusbarConnectionImages] = useState<any>(dataObj.article ? dataObj.article.busbarConnectionImages : {});
    const [busbarConnectionImagesItems, setBusbarConnectionImagesItems] = useState<any>(dataObj.article ? dataObj.article.busbarConnectionImages.items : []);
    const [files, setFiles] = useState<any>(dataObj.article ? dataObj.article.files : {});
    const [filesItems, setFilesItems] = useState<any>(dataObj.article ? dataObj.article.files.items : []);
    const [contentElements, setContentElements] = useState<any>(dataObj.article ? dataObj.article.contentElements : []);
    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [lang, setLang] = useState<string>('');
    const [isLang, setIsLang] = useState<boolean>(false);


    // Set loading
    if (loading || loadingNew || loader) {
        return <Loader/>
    }

    // Handle error
    if (error || errorNew) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data || dataNew) {
        dataObj = data || dataNew
    }

    // Set title
    if (dataObj.article && dataObj.article.article) {
        title = dataObj.article.article.title;
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveArticle = () => {
        if (id != '') {
            updateArticleMutation(state);
        } else {
            createArticleMutation(state);
        }
        setInicialValue({
            "data": state,
            "images": mediaItems,
            "graphImages": graphImagesItems,
            "mechanicalImages": mechanicalImagesItems,
            "busbarConnectionImages": busbarConnectionImagesItems,
            "files": filesItems
        });
    }

    // Update article mutation
    const updateArticleMutation = (data: any) => {

        updateArticle({
            variables: {
                model,
                id,
                data,
                images: mediaItems,
                graphImages: graphImagesItems,
                mechanicalImages: mechanicalImagesItems,
                busbarConnectionImages: busbarConnectionImagesItems,
                files: filesItems,
                removedMedia,
            }
        }).then(r => {
            setUpdated(true)
            setRemovedMedia([])

            setTimeout(() => {
                setUpdated(false);
            }, 3000)

        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }

    // Create article mutation
    const createArticleMutation = (data: any) => {
        createArticle({
            variables: {
                model,
                data,
                images: mediaItems,
                graphImages: graphImagesItems,
                mechanicalImages: mechanicalImagesItems,
                busbarConnectionImages: busbarConnectionImagesItems,
                files: filesItems,
                removedMedia,
            },
        })
            .then((res: FetchResult<CreateArticleMutation>) => {
                if (res.data && res.data.createArticle.article) {
                    setUpdated(true);
                    navigate(`/articles/${res.data.createArticle.article.id}`, {replace: true})
                }
            })
            .catch(error => {
                return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
            });
    }

    //Back to ListView
    const backToListView = () => {
        if (!isLang) {
            navigate(`/articles`, {replace: true})
        } else {
            navigate(`/articles/${lang}/${id}`, {replace: true})
        }
    }
    const openDialog = () => {
        const hasChanged = !shallowEqual(inicialValue, {
            "data": state,
            "images": mediaItems,
            "graphImages": graphImagesItems,
            "mechanicalImages": mechanicalImagesItems,
            "busbarConnectionImages": busbarConnectionImagesItems,
            "files": filesItems
        });
        setIsLang(false);
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    const openTranslateDialog = (lang: any) => {
        const hasChanged = !shallowEqual(inicialValue, {
            "data": state,
            "images": mediaItems,
            "graphImages": graphImagesItems,
            "mechanicalImages": mechanicalImagesItems,
            "busbarConnectionImages": busbarConnectionImagesItems,
            "files": filesItems
        });
        setLang(lang);
        setIsLang(true);
        if (hasChanged) {
            setOpen(true);
        } else {
            navigate(`/articles/${lang}/${id}`, {replace: true})
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // Add media
    const onAddMediaHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'images',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadArticleMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setMediaItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }
    const onAddGraphImagesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'graphImages',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadArticleMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setGraphImagesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }
    const onAddMechanicalImagesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'mechanicalImages',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadArticleMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setMechanicalImagesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }
    const onAddBusbarConnectionImagesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'busbarConnectionImages',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadArticleMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setBusbarConnectionImagesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }
    const onAddFilesHandler = (files: any) => {
        setLoader(true);
        inlineMediaUpload({
            variables: {
                id,
                model,
                field: 'files',
                images: files
            }
        }).then((res: FetchResult<InlineMediaUploadArticleMutation>) => {
                const addedFiles = res.data?.inlineMediaUpload;
                setFilesItems((prevState: any) => {
                    if (addedFiles) {
                        return [...prevState, ...addedFiles];
                    }
                });
                setLoader(false);
            }
        ).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        })
    }

    // Update media
    const onMediaUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = mediaItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        mediaItems[foundIndex] = updateData;
        setMediaItems(mediaItems)
    }
    const onGraphImagesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = graphImagesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        graphImagesItems[foundIndex] = updateData;
        setGraphImagesItems(graphImagesItems)
    }
    const onMechanicalImagesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = mechanicalImagesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        mechanicalImagesItems[foundIndex] = updateData;
        setMechanicalImagesItems(mechanicalImagesItems)
    }
    const onBusbarConnectionImagesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = busbarConnectionImagesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        busbarConnectionImagesItems[foundIndex] = updateData;
        setBusbarConnectionImagesItems(busbarConnectionImagesItems)
    }
    const onFilesUpdateHandler = (updateData: MediaItemType) => {
        const foundIndex = filesItems.findIndex((item: MediaItemType) => item.id == updateData.id);
        filesItems[foundIndex] = updateData;
        setFilesItems(filesItems)
    }

    // Remove media
    const onMediaRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setMediaItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }
    const onGraphImagesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setGraphImagesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }
    const onMechanicalImagesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setMechanicalImagesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }
    const onBusbarConnectionImagesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setBusbarConnectionImagesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }
    const onFilesRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
        setFilesItems((prevState: MediaItemType[]) => prevState.filter(item => item.id !== id));
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?: any[]) => {
        let fieldName = '';
        let value: any;


        if (type) {

            // @ts-ignore
            fieldName = field;
            value = values
        } else {

            // Input fields not checkbox
            if (event.target && event.target.type !== 'checkbox') {
                fieldName = event.target.name;
                value = event.target.value;
            }

            // Input fields checkbox
            if (event.target && event.target.type === 'checkbox') {
                fieldName = event.target.name;
                // @ts-ignore
                value = event.target.checked;
            }

            if (field) {
                fieldName = field
                value = event

            }

        }

        // Update select value
        setState({...state, [fieldName]: value});

    };


    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    return (
        <React.Fragment>
            <Helmet title="Article"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        {id &&
                            <div>
                                <Chip className="lang-nav active" variant="outlined" avatar={<Avatar src={england}/>}
                                      label="En"/>
                                <Chip className="lang-nav" variant="outlined" onClick={(lang) => {
                                    openTranslateDialog('de')
                                }} clickable={true} avatar={<Avatar src={germany}/>} label="De"/>
                                <Chip className="lang-nav" variant="outlined" onClick={(lang) => {
                                    openTranslateDialog('cn')
                                }} clickable={true} avatar={<Avatar src={china}/>} label="Cn"/>
                            </div>
                        }
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveArticle}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wrapper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable"
                                                      allowScrollButtonsMobile>
                                                    <Tab label="General" {...tabsProps(0)} />
                                                    <Tab label="Media" {...tabsProps(1)} />
                                                    <Tab label="Electric" {...tabsProps(2)} />
                                                    <Tab label="Attenuation" {...tabsProps(3)} />
                                                    <Tab label="Mechanic" {...tabsProps(4)} />
                                                    <Tab label="PQ" {...tabsProps(5)} />
                                                </Tabs>
                                            </div>
                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoArticle
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            inputHandler(e, field, type, values);
                                                            handleChange(e);
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={1}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Images
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={mediaItems}
                                                        fieldType={images.fieldType}
                                                        allowedExtensions={images.allowedExtensions}
                                                        isMultiple={images.isMultiple}
                                                        onAdd={onAddMediaHandler}
                                                        onUpdate={onMediaUpdateHandler}
                                                        onDelete={onMediaRemoveHandler}/>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Graph images
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={graphImagesItems}
                                                        fieldType={graphImages.fieldType}
                                                        allowedExtensions={graphImages.allowedExtensions}
                                                        isMultiple={graphImages.isMultiple}
                                                        onAdd={onAddGraphImagesHandler}
                                                        onUpdate={onGraphImagesUpdateHandler}
                                                        onDelete={onGraphImagesRemoveHandler}/>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Mechanical images
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={mechanicalImagesItems}
                                                        fieldType={mechanicalImages.fieldType}
                                                        allowedExtensions={mechanicalImages.allowedExtensions}
                                                        isMultiple={mechanicalImages.isMultiple}
                                                        onAdd={onAddMechanicalImagesHandler}
                                                        onUpdate={onMechanicalImagesUpdateHandler}
                                                        onDelete={onMechanicalImagesRemoveHandler}/>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Busbar Connection images
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={busbarConnectionImagesItems}
                                                        fieldType={busbarConnectionImages.fieldType}
                                                        allowedExtensions={busbarConnectionImages.allowedExtensions}
                                                        isMultiple={busbarConnectionImages.isMultiple}
                                                        onAdd={onAddBusbarConnectionImagesHandler}
                                                        onUpdate={onBusbarConnectionImagesUpdateHandler}
                                                        onDelete={onBusbarConnectionImagesRemoveHandler}/>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Files
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineMedia
                                                        items={filesItems}
                                                        fieldType={files.fieldType}
                                                        allowedExtensions={files.allowedExtensions}
                                                        isMultiple={files.isMultiple}
                                                        onAdd={onAddFilesHandler}
                                                        onUpdate={onFilesUpdateHandler}
                                                        onDelete={onFilesRemoveHandler}/>

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10}/>
                                                        </Grid>
                                                    </Grid>
                                                    {dataObj.article?.article?.pdfLink &&
                                                        <div>
                                                            <Grid container spacing={6}>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h6" mb={5}>
                                                                        Pdf Link
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={6}>
                                                                <Grid item xs={12} md={12} mb={5}>
                                                                    <Link href={dataObj.article.article?.pdfLink} target="_blank"
                                                                          underline="hover">
                                                                        {dataObj.article.article?.pdfLink}
                                                                    </Link>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container spacing={6}>
                                                                <Grid item xs={12}>
                                                                    <Divider mb={10}/>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    }

                                                </TabPanel>
                                                <TabPanel value={tabValue} index={2}>

                                                    <ElectricArticle
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={3}>
                                                    <AttenuationArticle
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={4}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Mechanic
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <MechanicArticle
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={5}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                PQ
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <PQArticle
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                            </div>

                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Saved successfully" open={updated}/>
            }

            <ConfirmationDialog
                message="Are you sure you want to leave without saving?"
                title="Leave page"
                open={open}
                button="Confirm"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default Article;
