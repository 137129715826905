import React, {ChangeEvent, EventHandler, useState} from "react";
import {Button, FormControl, InputBase, Stack} from "@mui/material";

// Styles
import {alpha, styled} from "@mui/system";
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%'
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(3, 1, 3, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(8)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        minWidth: '300px',
        borderBottom: '1px solid rgba(0,0,0,0.12)'
    },
}));

const SearchBar: React.FC<{ text: string, onSearch: (text: string) => void }> = (props) => {
    const [text, setText] = useState<string>(props.text);
    const {onSearch} = props;

    const search = (event: ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value;
        setText(searchText);
    }

    const onKeyUp = () => {
        onSearch(text);
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            onSearch(text);
        }
    }


    return (
        <React.Fragment>
            <Search>
                <Stack direction="row" spacing={5}>
                    <SearchIconWrapper>
                        <SearchIcon/>
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        type="search"
                        value={text}
                        name="search"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => search(e)}
                        onKeyDown={(e: any) => handleKeyDown(e)}
                        inputProps={{'aria-label': 'search'}}
                    />
                    <Button variant="contained" onClick={onKeyUp}>
                        Search
                    </Button>
                </Stack>
            </Search>
        </React.Fragment>
    );
}

export default SearchBar;
