import React from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {useParams} from "react-router-dom";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const TechnicalSpecificationProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange} = props;
    const params = useParams();
    const lang = params.lang ? params.lang : '';


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
               Note
            </Typography>
            {lang == 'de' &&
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="Technical specifications Note De"
                            name="technicalSpecificationsNoteDe"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.technicalSpecificationsNoteDe ? values.technicalSpecificationsNoteDe : ''}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="Technical specifications Note"
                            name="technicalSpecificationsNote"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.technicalSpecificationsNote ? values.technicalSpecificationsNote : ''}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
            }
            {lang == 'cn' &&
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="Technical specifications Note Cn"
                            name="technicalSpecificationsNoteCn"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.technicalSpecificationsNoteCn ? values.technicalSpecificationsNoteCn : ''}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="Technical specifications Note"
                            name="technicalSpecificationsNote"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.technicalSpecificationsNote ? values.technicalSpecificationsNote : ''}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}


export default TechnicalSpecificationProduct;
