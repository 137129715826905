import React from "react";

import {
    Grid,
    TextField as MuiTextField, Typography,

} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {useParams} from "react-router-dom";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const SeoCategory: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange} = props;
    const params = useParams();
    const lang = params.lang ? params.lang : '';


    //------------------------------- Public methods -------------------------------------


    return (

        <React.Fragment>
            {lang == 'de' &&
                <React.Fragment>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Title De"
                                name="seoTitleDe"
                                value={values.seoTitleDe ? values.seoTitleDe : ''}
                                error={Boolean(touched.seoTitleDe && errors.seoTitleDe)}
                                fullWidth
                                helperText={touched.seoTitleDe && errors.seoTitleDe}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Title"
                                name="seoTitle"
                                value={values.seoTitle ? values.seoTitle : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Description De"
                                name="seoDescriptionDe"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoDescriptionDe ? values.seoDescriptionDe : ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Description"
                                name="seoDescription"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoDescription ? values.seoDescription : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Keywords De"
                                name="seoKeywordsDe"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoKeywordsDe ? values.seoKeywordsDe : ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Keywords"
                                name="seoKeywords"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoKeywords ? values.seoKeywords : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="h6" mb={5}>
                        Video
                    </Typography>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="videoDe"
                                label="Video Link De"
                                value={values.videoDe ? values.videoDe : ''}
                                error={Boolean(touched.videoDe && errors.videoDe)}
                                fullWidth
                                helperText={touched.videoDe && errors.videoDe}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="video"
                                label="Video Link"
                                value={values.video ? values.video : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            {lang == 'cn' &&
                <React.Fragment>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Title Cn"
                                name="seoTitleCn"
                                value={values.seoTitleCn ? values.seoTitleCn : ''}
                                error={Boolean(touched.seoTitleCn && errors.seoTitleCn)}
                                fullWidth
                                helperText={touched.seoTitleCn && errors.seoTitleCn}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Title"
                                name="seoTitle"
                                value={values.seoTitle ? values.seoTitle : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Description Cn"
                                name="seoDescriptionCn"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoDescriptionCn ? values.seoDescriptionCn : ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Description"
                                name="seoDescription"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoDescription ? values.seoDescription : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Keywords Cn"
                                name="seoKeywordsCn"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoKeywordsCn ? values.seoKeywordsCn : ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Keywords"
                                name="seoKeywords"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoKeywords ? values.seoKeywords : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="h6" mb={5}>
                        Video
                    </Typography>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="videoCn"
                                label="Video Link Cn"
                                value={values.videoCn ? values.videoCn : ''}
                                error={Boolean(touched.videoCn && errors.videoCn)}
                                fullWidth
                                helperText={touched.videoCn && errors.videoCn}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="video"
                                label="Video Link"
                                value={values.video ? values.video : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>

    );
}


export default SeoCategory;
