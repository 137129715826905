import React, {ChangeEvent, useEffect, useState} from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider as MuiDivider, FormControl,
    Grid,
    Stack,
    TextField as MuiTextField,
    Typography
} from "@mui/material";

// Types
import {TechnicalSpecificationItemType} from "../../../types/inlineTechnicalSpecificationsType";

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {useParams} from "react-router-dom";


const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const TechnicalSpecificationItemTranslate: React.FC<{ item: TechnicalSpecificationItemType, types: any, onUpdate: (data: any) => void }> = (props) => {
    const [item, setItem] = useState(props.item);
    const types = props.types;
    const params = useParams();
    const lang = params.lang ? params.lang : '';

    //------------------------------- Public methods -------------------------------------

    useEffect(() => {
        props.onUpdate(item);
    }, [item]);

    // This function is called when the select changes
    const inputHandler = (event: any) => {
        const fieldName = event.target.name;
        const value = event.target.value;

        // Update select value
        setItem({...item, [fieldName]: value});
    };

    // trigger update
    const updateHandler = () => {
        props.onUpdate(item);
    };


    // Update options title
    const onUpdateOptionHandlerDe = (event: ChangeEvent<HTMLInputElement>, id: string) => {
        const value = event.target.value;
        const foundIndex = item.options.findIndex((option: { id: string, titleDe: string }) => option.id == id);
        item.options[foundIndex].titleDe = value;
        setItem({...item});
    }
    const onUpdateOptionHandlerCn = (event: ChangeEvent<HTMLInputElement>, id: string) => {
        const value = event.target.value;
        const foundIndex = item.options.findIndex((option: { id: string, titleCn: string }) => option.id == id);
        item.options[foundIndex].titleCn = value;
        setItem({...item});
    }


    return (
        <React.Fragment>
            <Grid container spacing={6} alignItems="center">
                <Grid item xs={12}>
                    <Accordion className="media-item-accordion" sx={{borderRadius: 6, borderColor: 'rgba(0,0,0,0.12)'}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            sx={{margin: '0'}}
                        >

                                <Stack direction="row" spacing={2} alignItems="center" sx={{padding: '0 10px'}}>
                                    {item.type
                                        ? <React.Fragment>
                                            {lang == 'de' &&
                                                <Typography>{types.find((type: any) => type.id === item.type).titleDe}/{types.find((type: any) => type.id === item.type).title}</Typography>
                                            }
                                            {lang == 'cn' &&
                                                <Typography>{types.find((type: any) => type.id === item.type).titleCn}/{types.find((type: any) => type.id === item.type).title}</Typography>
                                            }
                                            <Typography>(order: {item.sorting})</Typography>
                                        </React.Fragment>

                                        : <Typography>New specification</Typography>
                                    }
                                </Stack>

                        </AccordionSummary>
                        <AccordionDetails className="media-item-content">
                            <Grid container spacing={6} mb={5} alignItems="center">
                                {lang == 'de' &&
                                    <Grid item xs={12} md={6}>
                                        <FormControl className="full-width">
                                            <TextField
                                                name="typeDe"
                                                label="Type De"
                                                value={types.find((type: any) => type.id === item.type).titleDe}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                                {lang == 'cn' &&
                                    <Grid item xs={12} md={6}>
                                        <FormControl className="full-width">
                                            <TextField
                                                name="typeCn"
                                                label="Type Cn"
                                                value={types.find((type: any) => type.id === item.type).titleCn}
                                                fullWidth
                                                variant="outlined"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={12} md={6}>
                                    <FormControl className="full-width">
                                        <TextField
                                            name="type"
                                            label="Type"
                                            value={types.find((type: any) => type.id === item.type).title}
                                            fullWidth
                                            variant="outlined"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <Divider mb={10}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} mb={5}>
                                <Typography variant="h6" mb={5}>
                                    Options
                                </Typography>
                            </Grid>

                            {item.options &&
                                item.options.map((option: { id: string, title: string, titleDe: string, titleCn: string }) => {
                                    return (
                                        <Grid container spacing={6} alignItems="center" key={option.id}>
                                            {lang == 'de' &&
                                                <Grid item xs={12} md={6} mb={5}>
                                                    <TextField
                                                        name="titleDe"
                                                        label="Value De"
                                                        value={option.titleDe}
                                                        fullWidth
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => onUpdateOptionHandlerDe(e, option.id)}
                                                        onBlur={() => updateHandler()}
                                                        variant="outlined"
                                                        my={2}
                                                    />
                                                </Grid>
                                            }
                                            {lang == 'cn' &&
                                                <Grid item xs={12} md={6} mb={5}>
                                                    <TextField
                                                        name="titleCn"
                                                        label="Value Cn"
                                                        value={option.titleCn}
                                                        fullWidth
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => onUpdateOptionHandlerCn(e, option.id)}
                                                        onBlur={() => updateHandler()}
                                                        variant="outlined"
                                                        my={2}
                                                    />
                                                </Grid>
                                            }
                                            <Grid item xs={12} md={6} mb={5}>
                                                <TextField
                                                    name="title"
                                                    label="Value"
                                                    value={option.title}
                                                    fullWidth
                                                    variant="outlined"
                                                    my={2}
                                                    required
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }


                        </AccordionDetails>
                    </Accordion>
                </Grid>


            </Grid>
        </React.Fragment>
    );
}

export default TechnicalSpecificationItemTranslate;
