import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    InputLabel,
} from "@mui/material";

import ReactQuill from "react-quill";

// Styles
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {useParams} from "react-router-dom";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {min-height: 200px;}`;


const GeneralInfoProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const params = useParams();
    const lang = params.lang ? params.lang : '';

    // States
    const [descriptionDe, setDescriptionDe] = useState(state.descriptionDe ? state.descriptionDe : (values.descriptionDe ? values.descriptionDe : ''));
    const [descriptionCn, setDescriptionCn] = useState(state.descriptionCn ? state.descriptionCn : (values.descriptionCn ? values.descriptionCn : ''));
    const [featuresAndBenefitsDe, setFeaturesAndBenefitsDe] = useState(state.featuresAndBenefitsDe ? state.featuresAndBenefitsDe : (values.featuresAndBenefitsDe ? values.featuresAndBenefitsDe : ''));
    const [featuresAndBenefitsCn, setFeaturesAndBenefitsCn] = useState(state.featuresAndBenefitsCn ? state.featuresAndBenefitsCn : (values.featuresAndBenefitsCn ? values.featuresAndBenefitsCn : ''));
    const [applicationsDe, setApplicationsDe] = useState(state.applicationsDe ? state.applicationsDe : (values.applicationsDe ? values.applicationsDe : ''));
    const [applicationsCn, setApplicationsCn] = useState(state.applicationsCn ? state.applicationsCn : (values.applicationsCn ? values.applicationsCn : ''));


    //------------------------------- Public methods -------------------------------------


    // Update description on change
    const handleChangeDescriptionDe = (event: any) => {
        event.toString('html');
        setDescriptionDe(event);
    };
    const handleChangeDescriptionCn = (event: any) => {
        event.toString('html');
        setDescriptionCn(event);
    };

    // Update features and benefits on change
    const handleFeaturesAndBenefitsDe = (event: any) => {
        event.toString('html');
        setFeaturesAndBenefitsDe(event);
    };
    const handleFeaturesAndBenefitsCn = (event: any) => {
        event.toString('html');
        setFeaturesAndBenefitsCn(event);
    };

    // Update approvals on change
    const handleApplicationsDe = (event: any) => {
        event.toString('html');
        setApplicationsDe(event);
    };
    const handleApplicationsCn = (event: any) => {
        event.toString('html');
        setApplicationsCn(event);
    };


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations {lang}
            </Typography>
            {lang == 'de' &&
                <Grid container spacing={6} mb={5}>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="skuDe"
                            label="SKU De"
                            value={values.skuDe ? values.skuDe : ''}
                            error={Boolean(touched.skuDe && errors.skuDe)}
                            fullWidth
                            helperText={touched.skuDe && errors.skuDe}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="sku"
                            label="SKU"
                            value={values.sku ? values.sku : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="titleDe"
                            label="Title De"
                            value={values.titleDe ? values.titleDe : ''}
                            error={Boolean(touched.titleDe && errors.titleDe)}
                            fullWidth
                            helperText={touched.titleDe && errors.titleDe}
                            onChange={onChange}
                            variant="outlined"

                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="title"
                            label="Title"
                            value={values.title ? values.title : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="subtitleDe"
                            label="Subtitle De"
                            value={values.subtitleDe ? values.subtitleDe : ''}
                            error={Boolean(touched.subtitleDe && errors.subtitleDe)}
                            fullWidth
                            helperText={touched.subtitleDe && errors.subtitleDe}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="subtitle"
                            label="Subtitle"
                            value={values.subtitle ? values.subtitle : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="Short description De"
                            name="shortDescriptionDe"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.shortDescriptionDe ? values.shortDescriptionDe : ''}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="Short description"
                            name="shortDescription"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.shortDescription ? values.shortDescription : ''}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="List teaser text De"
                            name="teaserDe"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.teaserDe ? values.teaserDe : ''}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="List teaser text"
                            name="teaser"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.teaser ? values.teaser : ''}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <QuillWrapper className="full-width">
                            <InputLabel className="mb-5">Description De</InputLabel>
                            <ReactQuill
                                theme="snow"
                                value={descriptionDe}
                                onChange={(e) => {
                                    handleChangeDescriptionDe(e);
                                    onChange(e, 'descriptionDe')
                                }}
                            />
                        </QuillWrapper>
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <QuillWrapper className="full-width read-only">
                            <InputLabel className="mb-5">Description</InputLabel>
                            <ReactQuill
                                theme="snow"
                                value={values.description}
                                readOnly={true}
                            />
                        </QuillWrapper>
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <QuillWrapper className="full-width">
                            <InputLabel className="mb-5">Features and benefits De</InputLabel>
                            <ReactQuill
                                theme="snow"
                                value={featuresAndBenefitsDe}
                                onChange={(e) => {
                                    handleFeaturesAndBenefitsDe(e);
                                    onChange(e, 'featuresAndBenefitsDe')
                                }}
                            />
                        </QuillWrapper>
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <QuillWrapper className="full-width read-only">
                            <InputLabel className="mb-5">Features and benefits</InputLabel>
                            <ReactQuill
                                theme="snow"
                                value={values.featuresAndBenefits}
                                readOnly={true}
                            />
                        </QuillWrapper>
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <QuillWrapper className="full-width">
                            <InputLabel className="mb-5">Typical applications De</InputLabel>
                            <ReactQuill
                                theme="snow"
                                value={applicationsDe}
                                onChange={(e) => {
                                    handleApplicationsDe(e);
                                    onChange(e, 'applicationsDe')
                                }}
                            />
                        </QuillWrapper>
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <QuillWrapper className="full-width read-only">
                            <InputLabel className="mb-5">Typical applications</InputLabel>
                            <ReactQuill
                                theme="snow"
                                value={values.applications}
                                readOnly={true}
                            />
                        </QuillWrapper>
                    </Grid>
                </Grid>
            }
            {lang == 'cn' &&
                <Grid container spacing={6} mb={5}>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="skuCn"
                            label="SKU Cn"
                            value={values.skuCn ? values.skuCn : ''}
                            error={Boolean(touched.skuCn && errors.skuCn)}
                            fullWidth
                            helperText={touched.skuCn && errors.skuCn}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="sku"
                            label="SKU"
                            value={values.sku ? values.sku : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="titleCn"
                            label="Title Cn"
                            value={values.titleCn ? values.titleCn : ''}
                            error={Boolean(touched.titleCn && errors.titleCn)}
                            fullWidth
                            helperText={touched.titleCn && errors.titleCn}
                            onChange={onChange}
                            variant="outlined"

                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="title"
                            label="Title"
                            value={values.title ? values.title : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="subtitleCn"
                            label="Subtitle Cn"
                            value={values.subtitleCn ? values.subtitleCn : ''}
                            error={Boolean(touched.subtitleCn && errors.subtitleCn)}
                            fullWidth
                            helperText={touched.subtitleCn && errors.subtitleCn}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="subtitle"
                            label="Subtitle"
                            value={values.subtitle ? values.subtitle : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="Short description Cn"
                            name="shortDescriptionCn"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.shortDescriptionCn ? values.shortDescriptionCn : ''}
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="Short description"
                            name="shortDescription"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.shortDescription ? values.shortDescription : ''}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="List teaser text Cn"
                            name="teaserCn"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.teaserCn ? values.teaserCn : ''}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            label="List teaser text"
                            name="teaser"
                            multiline
                            rows={4}
                            fullWidth
                            value={values.teaser ? values.teaser : ''}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <QuillWrapper className="full-width">
                            <InputLabel className="mb-5">Description Cn</InputLabel>
                            <ReactQuill
                                theme="snow"
                                value={descriptionCn}
                                onChange={(e) => {
                                    handleChangeDescriptionCn(e);
                                    onChange(e, 'descriptionCn')
                                }}
                            />
                        </QuillWrapper>
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <QuillWrapper className="full-width read-only">
                            <InputLabel className="mb-5">Description</InputLabel>
                            <ReactQuill
                                theme="snow"
                                value={values.description}
                                readOnly={true}
                            />
                        </QuillWrapper>
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <QuillWrapper className="full-width">
                            <InputLabel className="mb-5">Features and benefits Cn</InputLabel>
                            <ReactQuill
                                theme="snow"
                                value={featuresAndBenefitsCn}
                                onChange={(e) => {
                                    handleFeaturesAndBenefitsCn(e);
                                    onChange(e, 'featuresAndBenefitsCn')
                                }}
                            />
                        </QuillWrapper>
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <QuillWrapper className="full-width read-only">
                            <InputLabel className="mb-5">Features and benefits</InputLabel>
                            <ReactQuill
                                theme="snow"
                                value={values.featuresAndBenefits}
                                readOnly={true}
                            />
                        </QuillWrapper>
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <QuillWrapper className="full-width">
                            <InputLabel className="mb-5">Typical applications Cn</InputLabel>
                            <ReactQuill
                                theme="snow"
                                value={applicationsCn}
                                onChange={(e) => {
                                    handleApplicationsCn(e);
                                    onChange(e, 'applicationsCn')
                                }}
                            />
                        </QuillWrapper>
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <QuillWrapper className="full-width read-only">
                            <InputLabel className="mb-5">Typical applications</InputLabel>
                            <ReactQuill
                                theme="snow"
                                value={values.applications}
                                readOnly={true}
                            />
                        </QuillWrapper>
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}

export default GeneralInfoProduct;
