import React from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
} from "@mui/material";


// Types


// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";



const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const GeneralInfoDynamicSelect: React.FC<{ values: any}> = (props) => {
    const {values} = props;

    //------------------------------- Public methods -------------------------------------

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} sm={6} md={3} mb={5}>
                    <TextField
                        name="field"
                        label="Field"
                        value={values.field ? values.field : ''}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} mb={5}>
                    <TextField
                        name="model"
                        label="Model"
                        value={values.model ? values.model : ''}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoDynamicSelect;
