import React from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Divider as MuiDivider,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";

const Divider = styled(MuiDivider)(spacing);


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const AdditionalsCompany: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange} = props;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Additional info
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={8} mb={5}>
                    <TextField
                        name="additional"
                        label="Additional"
                        value={values.additional ? values.additional : ''}
                        fullWidth
                        multiline
                        rows={4}
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default AdditionalsCompany;
