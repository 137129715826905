import React from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {useParams} from "react-router-dom";



const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const GeneralInfoPlatform: React.FC<FieldsGroupType> = (props) => {
    const {values, touched, errors, onChange} = props;
    const params = useParams();
    const lang = params.lang ? params.lang : '';

    //------------------------------- Public methods -------------------------------------

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations
            </Typography>
            {lang == 'de' &&
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="titleDe"
                        label="Title De"
                        value={values.titleDe ? values.titleDe : ''}
                        error={Boolean(touched.titleDe && errors.titleDe)}
                        fullWidth
                        helperText={touched.titleDe && errors.titleDe}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="title"
                        label="Title"
                        value={values.title ? values.title : ''}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>
            }
            {lang == 'cn' &&
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="titleCn"
                            label="Title Cn"
                            value={values.titleCn ? values.titleCn : ''}
                            error={Boolean(touched.titleCn && errors.titleCn)}
                            fullWidth
                            helperText={touched.titleCn && errors.titleCn}
                            onChange={onChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} mb={5}>
                        <TextField
                            name="title"
                            label="Title"
                            value={values.title ? values.title : ''}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    );
}


export default GeneralInfoPlatform;
