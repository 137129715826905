import React from "react";
import ContentElementItemTranslate from "./ContentElementItemTranslate";

// Types
import {ContentElementItemType, InlineContentElementType} from "../../types/inlineContentElementType";
import {MediaItemType} from "../../types/inlineMedia";


// Styles
import {Button, Grid} from "@mui/material";
import {Plus} from "react-feather";



const InlineContentElementTranslate: React.FC<InlineContentElementType> = (props) => {
    const {onUpdate, onAdd} = props;

       // Trigger element update
    const onUpdateHandler = (data: any) => {
        onUpdate(data);
    }



    return (
        <React.Fragment>
            <Grid container spacing={6}>
                {props.items.length > 0 &&
                    <Grid item xs={12}>
                        {props.items.map((item: ContentElementItemType) => {
                            return (
                                <ContentElementItemTranslate
                                    key={item.id}
                                    item={item}
                                    onUpdate={onUpdateHandler}
                                />
                            )
                        })}
                    </Grid>
                }

            </Grid>
        </React.Fragment>
    );
}

export default InlineContentElementTranslate;
