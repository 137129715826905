import React, {createRef, RefObject, useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button
} from "@mui/material";
import ListViewTable from "../components/ListViewTable";
import {CategoriesContext} from "./store/categories-context";

// Types
import {HeadCell} from "../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon, FileUpload, FileDownload, BrowserUpdated
} from "@mui/icons-material";
import SearchBar from "../components/SearchBar";
import {Plus} from "react-feather";

const Input = styled('input')({display: 'none',});
const Divider = styled(MuiDivider)(spacing);


function Categories() {
    const categoriesCtx = useContext(CategoriesContext);
    const fileInput: RefObject<HTMLInputElement> = createRef();

    // Set table headers data
    const headCells: Array<HeadCell> = categoriesCtx.headers;
    const model = 'categories';

    //------------------------------- Public methods -------------------------------------

    return (
        <React.Fragment>
            <Helmet title="Categories"/>
            <Grid item xs={12} mb={10}>
                <SearchBar text={categoriesCtx.search} onSearch={categoriesCtx.onSearch}/>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        Categories
                    </Typography>
                </Grid>
                <Grid item>
                    <div>
                        {categoriesCtx.selected && categoriesCtx.selected?.length > 0 &&
                            <Button variant="contained" color="primary" onClick={categoriesCtx.publish}
                                    startIcon={<BrowserUpdated/>} className="margin-right-btn" >
                                Publish
                            </Button>
                        }
                        <Button variant="contained" color="primary" onClick={categoriesCtx.exportCsv}
                                startIcon={<FileDownload/>} className="margin-right-btn">
                            CSV Export Categories
                        </Button>
                        <Input
                            ref={fileInput}
                            accept=".csv,.xlsx,.xls"
                            type="file"
                            name="file"
                            onChange={(event: any) => {
                                if (categoriesCtx.importCsv) {
                                    categoriesCtx.importCsv(event.target.files)
                                }
                            }}
                        />
                        <Button variant="contained" color="primary" onClick={(e: any) => {
                            if (fileInput.current) {
                                fileInput.current.click();
                            }
                        }} startIcon={<FileUpload/>} className="margin-right-btn">
                            CSV Import Categories
                        </Button>

                        <Button variant="contained" color="primary" onClick={categoriesCtx.addItem} startIcon={<AddIcon/>}>
                            New Category
                        </Button>
                    </div>
                </Grid>
            </Grid>

            {categoriesCtx.filters
                ? <Divider my={6}/>
                : <Divider my={6} mb={10}/>
            }

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={categoriesCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={categoriesCtx.removeItem}
                    onSelectHandler={categoriesCtx.selectItem}
                    onPaginateHandler={categoriesCtx.onPaginate}
                    onSortHandler={categoriesCtx.onSort}
                    count={categoriesCtx.count}
                    page={categoriesCtx.page}
                    rowsPerPage={categoriesCtx.rowsPerPage}
                    sorting={categoriesCtx.sorting}
                    filters={categoriesCtx.filters}
                    onMultiEditHandler={categoriesCtx.multiSelected}
                    selectedArray={categoriesCtx.selected}
                    model={model}
                />
            </Grid>
        </React.Fragment>
    );
}

export default Categories;
