import React from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Divider as MuiDivider,
} from "@mui/material";

// Styles
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const ReferenceProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange} = props;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
               Reference
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={8} mb={5}>
                    <TextField
                        name="ePlan"
                        label="Eplan"
                        value={values.ePlan ? values.ePlan : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} md={8} mb={5}>
                    <TextField
                        name="cadLink"
                        label="CAD & CAE"
                        value={values.cadLink ? values.cadLink : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} md={8} mb={5}>
                    <TextField
                        name="cadFileLink"
                        label="Cad File Link"
                        value={values.cadFileLink ? values.cadFileLink : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Spice
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="spiceModel"
                        label="Spice Model"
                        value={values.spiceModel ? values.spiceModel : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default ReferenceProduct;
