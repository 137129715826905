import * as Types from '../../../generated/types.graphql-gen';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /** Json scalar */
  Json: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: any;
};

export type Approval = {
  __typename?: 'Approval';
  crUser?: Maybe<User>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  linkCn?: Maybe<Scalars['String']>;
  linkDe?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type ApprovalArray = {
  __typename?: 'ApprovalArray';
  approval?: Maybe<ApprovalData>;
  image?: Maybe<InlineMedia>;
};

export type ApprovalData = {
  __typename?: 'ApprovalData';
  crUser?: Maybe<Scalars['ID']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  linkCn?: Maybe<Scalars['String']>;
  linkDe?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type Article = {
  __typename?: 'Article';
  ambientTemperature?: Maybe<Scalars['String']>;
  ambientTemperatureUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  application?: Maybe<Scalars['String']>;
  applicationCn?: Maybe<Scalars['String']>;
  applicationDe?: Maybe<Scalars['String']>;
  articleNo: Scalars['String'];
  buyLink?: Maybe<Scalars['String']>;
  capacitanceCx1?: Maybe<Scalars['String']>;
  capacitanceCx1Unit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  capacitanceCx2?: Maybe<Scalars['String']>;
  capacitanceCx2Unit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  capacitanceCy1?: Maybe<Scalars['String']>;
  capacitanceCy1Unit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  capacitanceCy2?: Maybe<Scalars['String']>;
  capacitanceCy2Unit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  climaticCategory?: Maybe<Scalars['String']>;
  cmAttenuation?: Maybe<Scalars['String']>;
  cmAttenuationUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  cooling?: Maybe<Scalars['String']>;
  crUser?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  descriptionCn?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  dmAttenuation?: Maybe<Scalars['String']>;
  dmAttenuationUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  eanUpc?: Maybe<Scalars['String']>;
  eanUpcCn?: Maybe<Scalars['String']>;
  eanUpcDe?: Maybe<Scalars['String']>;
  exportWeb?: Maybe<Scalars['Boolean']>;
  frameSize?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  heightUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inductanceL1?: Maybe<Scalars['String']>;
  inductanceL1Unit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  inductanceL2?: Maybe<Scalars['String']>;
  inductanceL2Unit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  inputTerminal?: Maybe<Array<Maybe<Scalars['ID']>>>;
  inputTerminalUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  lac?: Maybe<Scalars['String']>;
  lacUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ldc?: Maybe<Scalars['String']>;
  ldcUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  leakageCurrentIec?: Maybe<Scalars['String']>;
  leakageCurrentIecUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  leakageCurrentSch?: Maybe<Scalars['String']>;
  leakageCurrentSchUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  length?: Maybe<Scalars['String']>;
  lengthUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  motorDriveInputCurrent?: Maybe<Scalars['String']>;
  motorDriveInputCurrentUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  netWeight?: Maybe<Scalars['String']>;
  netWeightUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nominalCapacitance?: Maybe<Scalars['String']>;
  nominalCapacitanceUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nominalFrequency?: Maybe<Scalars['String']>;
  nominalFrequencyUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nominalInductance?: Maybe<Scalars['String']>;
  nominalInductanceUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  outputTerminal?: Maybe<Array<Maybe<Scalars['ID']>>>;
  outputTerminalUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  pdfLink?: Maybe<Scalars['String']>;
  phases?: Maybe<Array<Maybe<Scalars['ID']>>>;
  pin?: Maybe<Scalars['String']>;
  platforms?: Maybe<Array<Maybe<Platform>>>;
  powerLoss?: Maybe<Scalars['String']>;
  powerLossUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  product?: Maybe<Product>;
  ratedCurrent?: Maybe<Scalars['String']>;
  ratedCurrentUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ratedFilterInputFilter?: Maybe<Scalars['String']>;
  ratedFilterInputFilterUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ratedLoadPower?: Maybe<Scalars['String']>;
  ratedLoadPowerUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  resistance?: Maybe<Scalars['String']>;
  resistanceUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  size?: Maybe<Scalars['String']>;
  sizesA?: Maybe<Scalars['String']>;
  sizesB?: Maybe<Scalars['String']>;
  sizesC?: Maybe<Scalars['String']>;
  sizesD?: Maybe<Scalars['String']>;
  sizesE?: Maybe<Scalars['String']>;
  sizesF?: Maybe<Scalars['String']>;
  sizesG?: Maybe<Scalars['String']>;
  sizesH?: Maybe<Scalars['String']>;
  sizesI?: Maybe<Scalars['String']>;
  sizesJ?: Maybe<Scalars['String']>;
  sizesK?: Maybe<Scalars['String']>;
  sizesL?: Maybe<Scalars['String']>;
  sizesM?: Maybe<Scalars['String']>;
  sizesN?: Maybe<Scalars['String']>;
  sizesO?: Maybe<Scalars['String']>;
  sizesP?: Maybe<Scalars['String']>;
  sizesQ?: Maybe<Scalars['String']>;
  sizesR?: Maybe<Scalars['String']>;
  sizesS?: Maybe<Scalars['String']>;
  sizesT?: Maybe<Scalars['String']>;
  sizesU?: Maybe<Scalars['String']>;
  sizesV?: Maybe<Scalars['String']>;
  sizesW?: Maybe<Scalars['String']>;
  sizesX?: Maybe<Scalars['String']>;
  sizesY?: Maybe<Scalars['String']>;
  sizesZ?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  terminal?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeCn?: Maybe<Scalars['String']>;
  typeDe?: Maybe<Scalars['String']>;
  typicalDrivePowerRating?: Maybe<Scalars['String']>;
  typicalDrivePowerRatingUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  usageStatus?: Maybe<Array<Maybe<Scalars['ID']>>>;
  voltageAc?: Maybe<Scalars['String']>;
  voltageAcUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  voltageDc?: Maybe<Scalars['String']>;
  voltageDcUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  volume?: Maybe<Scalars['String']>;
  volumeUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  width?: Maybe<Scalars['String']>;
  widthUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  xStatus?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ArticleArray = {
  __typename?: 'ArticleArray';
  article?: Maybe<ArticleData>;
  busbarConnectionImages?: Maybe<InlineMedia>;
  files?: Maybe<InlineMedia>;
  graphImages?: Maybe<InlineMedia>;
  images?: Maybe<InlineMedia>;
  mechanicalImages?: Maybe<InlineMedia>;
};

export type ArticleData = {
  __typename?: 'ArticleData';
  ambientTemperature?: Maybe<Scalars['String']>;
  ambientTemperatureUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  application?: Maybe<Scalars['String']>;
  applicationCn?: Maybe<Scalars['String']>;
  applicationDe?: Maybe<Scalars['String']>;
  articleNo?: Maybe<Scalars['String']>;
  buyLink?: Maybe<Scalars['String']>;
  capacitanceCx1?: Maybe<Scalars['String']>;
  capacitanceCx1Unit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  capacitanceCx2?: Maybe<Scalars['String']>;
  capacitanceCx2Unit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  capacitanceCy1?: Maybe<Scalars['String']>;
  capacitanceCy1Unit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  capacitanceCy2?: Maybe<Scalars['String']>;
  capacitanceCy2Unit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  climaticCategory?: Maybe<Scalars['String']>;
  cmAttenuation?: Maybe<Scalars['String']>;
  cmAttenuationUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  cooling?: Maybe<Scalars['String']>;
  crUser?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  descriptionCn?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  dmAttenuation?: Maybe<Scalars['String']>;
  dmAttenuationUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  eanUpc?: Maybe<Scalars['String']>;
  eanUpcCn?: Maybe<Scalars['String']>;
  eanUpcDe?: Maybe<Scalars['String']>;
  exportWeb?: Maybe<Scalars['Boolean']>;
  frameSize?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  heightUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inductanceL1?: Maybe<Scalars['String']>;
  inductanceL1Unit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  inductanceL2?: Maybe<Scalars['String']>;
  inductanceL2Unit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  inputTerminal?: Maybe<Array<Maybe<Scalars['ID']>>>;
  inputTerminalUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  lac?: Maybe<Scalars['String']>;
  lacUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ldc?: Maybe<Scalars['String']>;
  ldcUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  leakageCurrentIec?: Maybe<Scalars['String']>;
  leakageCurrentIecUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  leakageCurrentSch?: Maybe<Scalars['String']>;
  leakageCurrentSchUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  length?: Maybe<Scalars['String']>;
  lengthUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  motorDriveInputCurrent?: Maybe<Scalars['String']>;
  motorDriveInputCurrentUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  netWeight?: Maybe<Scalars['String']>;
  netWeightUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nominalCapacitance?: Maybe<Scalars['String']>;
  nominalCapacitanceUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nominalFrequency?: Maybe<Scalars['String']>;
  nominalFrequencyUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  nominalInductance?: Maybe<Scalars['String']>;
  nominalInductanceUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  outputTerminal?: Maybe<Array<Maybe<Scalars['ID']>>>;
  outputTerminalUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  pdfLink?: Maybe<Scalars['String']>;
  phases?: Maybe<Array<Maybe<Scalars['ID']>>>;
  pin?: Maybe<Scalars['String']>;
  platforms?: Maybe<Array<Maybe<Scalars['ID']>>>;
  powerLoss?: Maybe<Scalars['String']>;
  powerLossUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  product?: Maybe<Scalars['ID']>;
  ratedCurrent?: Maybe<Scalars['String']>;
  ratedCurrentUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ratedFilterInputFilter?: Maybe<Scalars['String']>;
  ratedFilterInputFilterUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ratedLoadPower?: Maybe<Scalars['String']>;
  ratedLoadPowerUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  resistance?: Maybe<Scalars['String']>;
  resistanceUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  size?: Maybe<Scalars['String']>;
  sizesA?: Maybe<Scalars['String']>;
  sizesB?: Maybe<Scalars['String']>;
  sizesC?: Maybe<Scalars['String']>;
  sizesD?: Maybe<Scalars['String']>;
  sizesE?: Maybe<Scalars['String']>;
  sizesF?: Maybe<Scalars['String']>;
  sizesG?: Maybe<Scalars['String']>;
  sizesH?: Maybe<Scalars['String']>;
  sizesI?: Maybe<Scalars['String']>;
  sizesJ?: Maybe<Scalars['String']>;
  sizesK?: Maybe<Scalars['String']>;
  sizesL?: Maybe<Scalars['String']>;
  sizesM?: Maybe<Scalars['String']>;
  sizesN?: Maybe<Scalars['String']>;
  sizesO?: Maybe<Scalars['String']>;
  sizesP?: Maybe<Scalars['String']>;
  sizesQ?: Maybe<Scalars['String']>;
  sizesR?: Maybe<Scalars['String']>;
  sizesS?: Maybe<Scalars['String']>;
  sizesT?: Maybe<Scalars['String']>;
  sizesU?: Maybe<Scalars['String']>;
  sizesV?: Maybe<Scalars['String']>;
  sizesW?: Maybe<Scalars['String']>;
  sizesX?: Maybe<Scalars['String']>;
  sizesY?: Maybe<Scalars['String']>;
  sizesZ?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  terminal?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  typeCn?: Maybe<Scalars['String']>;
  typeDe?: Maybe<Scalars['String']>;
  typicalDrivePowerRating?: Maybe<Scalars['String']>;
  typicalDrivePowerRatingUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  usageStatus?: Maybe<Array<Maybe<Scalars['ID']>>>;
  voltageAc?: Maybe<Scalars['String']>;
  voltageAcUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  voltageDc?: Maybe<Scalars['String']>;
  voltageDcUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  volume?: Maybe<Scalars['String']>;
  volumeUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  width?: Maybe<Scalars['String']>;
  widthUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  xStatus?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type AuthUser = {
  __typename?: 'AuthUser';
  accessToken?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['Json']>;
  expDate?: Maybe<Scalars['String']>;
  tokenType?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Category = {
  __typename?: 'Category';
  crUser?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  descriptionCn?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  headerCn?: Maybe<Scalars['String']>;
  headerDe?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  parent?: Maybe<Category>;
  platforms?: Maybe<Array<Maybe<Platform>>>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionCn?: Maybe<Scalars['String']>;
  seoDescriptionDe?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoKeywordsCn?: Maybe<Scalars['String']>;
  seoKeywordsDe?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleCn?: Maybe<Scalars['String']>;
  seoTitleDe?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionCn?: Maybe<Scalars['String']>;
  shortDescriptionDe?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  subheader?: Maybe<Scalars['String']>;
  subheaderCn?: Maybe<Scalars['String']>;
  subheaderDe?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitleCn?: Maybe<Scalars['String']>;
  subtitleDe?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  videoCn?: Maybe<Scalars['String']>;
  videoDe?: Maybe<Scalars['String']>;
};

export type CategoryArray = {
  __typename?: 'CategoryArray';
  category?: Maybe<CategoryData>;
  contentElements?: Maybe<Array<Maybe<InlineContentElement>>>;
  headerImages?: Maybe<InlineMedia>;
  headerMobileImages?: Maybe<InlineMedia>;
  images?: Maybe<InlineMedia>;
};

export type CategoryData = {
  __typename?: 'CategoryData';
  crUser?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  descriptionCn?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  headerCn?: Maybe<Scalars['String']>;
  headerDe?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  parent?: Maybe<Scalars['ID']>;
  platforms?: Maybe<Array<Maybe<Scalars['ID']>>>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionCn?: Maybe<Scalars['String']>;
  seoDescriptionDe?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoKeywordsCn?: Maybe<Scalars['String']>;
  seoKeywordsDe?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleCn?: Maybe<Scalars['String']>;
  seoTitleDe?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionCn?: Maybe<Scalars['String']>;
  shortDescriptionDe?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  subheader?: Maybe<Scalars['String']>;
  subheaderCn?: Maybe<Scalars['String']>;
  subheaderDe?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitleCn?: Maybe<Scalars['String']>;
  subtitleDe?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  videoCn?: Maybe<Scalars['String']>;
  videoDe?: Maybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  additional?: Maybe<Scalars['String']>;
  additionalCn?: Maybe<Scalars['String']>;
  additionalDe?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityCn?: Maybe<Scalars['String']>;
  cityDe?: Maybe<Scalars['String']>;
  companyRegion?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companySolutions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companySubtype?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companyType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  countries?: Maybe<Array<Maybe<Country>>>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  faxOne?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneOne?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Maybe<State>>>;
  street?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  websiteOne?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type CompanyArray = {
  __typename?: 'CompanyArray';
  company?: Maybe<CompanyData>;
  logo?: Maybe<InlineMedia>;
};

export type CompanyData = {
  __typename?: 'CompanyData';
  additional?: Maybe<Scalars['String']>;
  additionalCn?: Maybe<Scalars['String']>;
  additionalDe?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityCn?: Maybe<Scalars['String']>;
  cityDe?: Maybe<Scalars['String']>;
  companyRegion?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companySolutions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companySubtype?: Maybe<Array<Maybe<Scalars['ID']>>>;
  companyType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  countries?: Maybe<Array<Maybe<Scalars['ID']>>>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  faxOne?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneOne?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Maybe<Scalars['ID']>>>;
  street?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  websiteOne?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type DataApproval = {
  crUser?: InputMaybe<Scalars['ID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<Scalars['String']>;
  linkCn?: InputMaybe<Scalars['String']>;
  linkDe?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleCn?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataArticle = {
  ambientTemperature?: InputMaybe<Scalars['String']>;
  ambientTemperatureUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  application?: InputMaybe<Scalars['String']>;
  applicationCn?: InputMaybe<Scalars['String']>;
  applicationDe?: InputMaybe<Scalars['String']>;
  articleNo?: InputMaybe<Scalars['String']>;
  buyLink?: InputMaybe<Scalars['String']>;
  capacitanceCx1?: InputMaybe<Scalars['String']>;
  capacitanceCx1Unit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  capacitanceCx2?: InputMaybe<Scalars['String']>;
  capacitanceCx2Unit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  capacitanceCy1?: InputMaybe<Scalars['String']>;
  capacitanceCy1Unit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  capacitanceCy2?: InputMaybe<Scalars['String']>;
  capacitanceCy2Unit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  climaticCategory?: InputMaybe<Scalars['String']>;
  cmAttenuation?: InputMaybe<Scalars['String']>;
  cmAttenuationUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  cooling?: InputMaybe<Scalars['String']>;
  crUser?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionCn?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  dmAttenuation?: InputMaybe<Scalars['String']>;
  dmAttenuationUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  eanUpc?: InputMaybe<Scalars['String']>;
  eanUpcCn?: InputMaybe<Scalars['String']>;
  eanUpcDe?: InputMaybe<Scalars['String']>;
  exportWeb?: InputMaybe<Scalars['Boolean']>;
  frameSize?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['String']>;
  heightUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  inductanceL1?: InputMaybe<Scalars['String']>;
  inductanceL1Unit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  inductanceL2?: InputMaybe<Scalars['String']>;
  inductanceL2Unit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  inputTerminal?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  inputTerminalUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lac?: InputMaybe<Scalars['String']>;
  lacUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  ldc?: InputMaybe<Scalars['String']>;
  ldcUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  leakageCurrentIec?: InputMaybe<Scalars['String']>;
  leakageCurrentIecUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  leakageCurrentSch?: InputMaybe<Scalars['String']>;
  leakageCurrentSchUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  length?: InputMaybe<Scalars['String']>;
  lengthUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  motorDriveInputCurrent?: InputMaybe<Scalars['String']>;
  motorDriveInputCurrentUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  netWeight?: InputMaybe<Scalars['String']>;
  netWeightUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  nominalCapacitance?: InputMaybe<Scalars['String']>;
  nominalCapacitanceUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  nominalFrequency?: InputMaybe<Scalars['String']>;
  nominalFrequencyUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  nominalInductance?: InputMaybe<Scalars['String']>;
  nominalInductanceUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  outputTerminal?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  outputTerminalUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  pdfLink?: InputMaybe<Scalars['String']>;
  phases?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  pin?: InputMaybe<Scalars['String']>;
  platforms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  powerLoss?: InputMaybe<Scalars['String']>;
  powerLossUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  product?: InputMaybe<Scalars['ID']>;
  ratedCurrent?: InputMaybe<Scalars['String']>;
  ratedCurrentUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  ratedFilterInputFilter?: InputMaybe<Scalars['String']>;
  ratedFilterInputFilterUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  ratedLoadPower?: InputMaybe<Scalars['String']>;
  ratedLoadPowerUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  resistance?: InputMaybe<Scalars['String']>;
  resistanceUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  size?: InputMaybe<Scalars['String']>;
  sizesA?: InputMaybe<Scalars['String']>;
  sizesB?: InputMaybe<Scalars['String']>;
  sizesC?: InputMaybe<Scalars['String']>;
  sizesD?: InputMaybe<Scalars['String']>;
  sizesE?: InputMaybe<Scalars['String']>;
  sizesF?: InputMaybe<Scalars['String']>;
  sizesG?: InputMaybe<Scalars['String']>;
  sizesH?: InputMaybe<Scalars['String']>;
  sizesI?: InputMaybe<Scalars['String']>;
  sizesJ?: InputMaybe<Scalars['String']>;
  sizesK?: InputMaybe<Scalars['String']>;
  sizesL?: InputMaybe<Scalars['String']>;
  sizesM?: InputMaybe<Scalars['String']>;
  sizesN?: InputMaybe<Scalars['String']>;
  sizesO?: InputMaybe<Scalars['String']>;
  sizesP?: InputMaybe<Scalars['String']>;
  sizesQ?: InputMaybe<Scalars['String']>;
  sizesR?: InputMaybe<Scalars['String']>;
  sizesS?: InputMaybe<Scalars['String']>;
  sizesT?: InputMaybe<Scalars['String']>;
  sizesU?: InputMaybe<Scalars['String']>;
  sizesV?: InputMaybe<Scalars['String']>;
  sizesW?: InputMaybe<Scalars['String']>;
  sizesX?: InputMaybe<Scalars['String']>;
  sizesY?: InputMaybe<Scalars['String']>;
  sizesZ?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  terminal?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleCn?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  typeCn?: InputMaybe<Scalars['String']>;
  typeDe?: InputMaybe<Scalars['String']>;
  typicalDrivePowerRating?: InputMaybe<Scalars['String']>;
  typicalDrivePowerRatingUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  usageStatus?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  voltageAc?: InputMaybe<Scalars['String']>;
  voltageAcUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  voltageDc?: InputMaybe<Scalars['String']>;
  voltageDcUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  volume?: InputMaybe<Scalars['String']>;
  volumeUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  width?: InputMaybe<Scalars['String']>;
  widthUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  xStatus?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type DataCategory = {
  crUser?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionCn?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  header?: InputMaybe<Scalars['String']>;
  headerCn?: InputMaybe<Scalars['String']>;
  headerDe?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  parent?: InputMaybe<Scalars['ID']>;
  platforms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoDescriptionCn?: InputMaybe<Scalars['String']>;
  seoDescriptionDe?: InputMaybe<Scalars['String']>;
  seoKeywords?: InputMaybe<Scalars['String']>;
  seoKeywordsCn?: InputMaybe<Scalars['String']>;
  seoKeywordsDe?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  seoTitleCn?: InputMaybe<Scalars['String']>;
  seoTitleDe?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  shortDescriptionCn?: InputMaybe<Scalars['String']>;
  shortDescriptionDe?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  subheader?: InputMaybe<Scalars['String']>;
  subheaderCn?: InputMaybe<Scalars['String']>;
  subheaderDe?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitleCn?: InputMaybe<Scalars['String']>;
  subtitleDe?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleCn?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Scalars['String']>;
  videoCn?: InputMaybe<Scalars['String']>;
  videoDe?: InputMaybe<Scalars['String']>;
};

export type DataCompany = {
  additional?: InputMaybe<Scalars['String']>;
  additionalCn?: InputMaybe<Scalars['String']>;
  additionalDe?: InputMaybe<Scalars['String']>;
  building?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cityCn?: InputMaybe<Scalars['String']>;
  cityDe?: InputMaybe<Scalars['String']>;
  companyRegion?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  companySolutions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  companySubtype?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  companyType?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  countries?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  faxOne?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  person?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneOne?: InputMaybe<Scalars['String']>;
  states?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  street?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleCn?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  websiteOne?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type DataContentElement = {
  description?: InputMaybe<Scalars['String']>;
  descriptionCn?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  label?: InputMaybe<Scalars['String']>;
  labelCn?: InputMaybe<Scalars['String']>;
  labelDe?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  linkCn?: InputMaybe<Scalars['String']>;
  linkDe?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleCn?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<Scalars['String']>;
  videoCn?: InputMaybe<Scalars['String']>;
  videoDe?: InputMaybe<Scalars['String']>;
};

export type DataDownload = {
  category?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  crUser?: InputMaybe<Scalars['ID']>;
  exportWeb?: InputMaybe<Scalars['Boolean']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  sorting?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleCn?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataMedia = {
  altTitle?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  thumbnail?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DataOption = {
  id?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  textCn?: InputMaybe<Scalars['String']>;
  textDe?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  titleCn?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataPlatform = {
  code?: InputMaybe<Scalars['String']>;
  crUser?: InputMaybe<Scalars['ID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  titleCn?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataProduct = {
  accessories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  applications?: InputMaybe<Scalars['String']>;
  applicationsCn?: InputMaybe<Scalars['String']>;
  applicationsDe?: InputMaybe<Scalars['String']>;
  approvals?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  approvalsNote?: InputMaybe<Scalars['String']>;
  approvalsNoteCn?: InputMaybe<Scalars['String']>;
  approvalsNoteDe?: InputMaybe<Scalars['String']>;
  articles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  attenuationPerf?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  cadData?: InputMaybe<Scalars['String']>;
  cadFileLink?: InputMaybe<Scalars['String']>;
  cadFileLinkCn?: InputMaybe<Scalars['String']>;
  cadFileLinkDe?: InputMaybe<Scalars['String']>;
  cadLink?: InputMaybe<Scalars['String']>;
  cadLinkCn?: InputMaybe<Scalars['String']>;
  cadLinkDe?: InputMaybe<Scalars['String']>;
  capacitanceFrom?: InputMaybe<Scalars['String']>;
  capacitanceTo?: InputMaybe<Scalars['String']>;
  capacitanceUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  chassisMount?: InputMaybe<Scalars['Boolean']>;
  crUser?: InputMaybe<Scalars['ID']>;
  currentConfig?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  currentType?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  description?: InputMaybe<Scalars['String']>;
  descriptionAdditional?: InputMaybe<Scalars['String']>;
  descriptionAdditionalCn?: InputMaybe<Scalars['String']>;
  descriptionAdditionalDe?: InputMaybe<Scalars['String']>;
  descriptionCn?: InputMaybe<Scalars['String']>;
  descriptionDe?: InputMaybe<Scalars['String']>;
  downloads?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  ePlan?: InputMaybe<Scalars['String']>;
  ePlanCn?: InputMaybe<Scalars['String']>;
  ePlanDe?: InputMaybe<Scalars['String']>;
  exportWeb?: InputMaybe<Scalars['Boolean']>;
  featuresAndBenefits?: InputMaybe<Scalars['String']>;
  featuresAndBenefitsCn?: InputMaybe<Scalars['String']>;
  featuresAndBenefitsDe?: InputMaybe<Scalars['String']>;
  frequencyDcToKhz?: InputMaybe<Scalars['String']>;
  frequencyDcToKhzUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  frequencyFrom?: InputMaybe<Scalars['String']>;
  frequencyTo?: InputMaybe<Scalars['String']>;
  frequencyUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  headquarters?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  inductanceFrom?: InputMaybe<Scalars['String']>;
  inductanceTo?: InputMaybe<Scalars['String']>;
  inductanceUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  introText?: InputMaybe<Scalars['String']>;
  introTextCn?: InputMaybe<Scalars['String']>;
  introTextDe?: InputMaybe<Scalars['String']>;
  leakageCurrentA?: InputMaybe<Scalars['String']>;
  leakageCurrentAUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  leakageCurrentB?: InputMaybe<Scalars['String']>;
  leakageCurrentBUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  leakageCurrentFrom?: InputMaybe<Scalars['String']>;
  leakageCurrentMikroampereB?: InputMaybe<Scalars['String']>;
  leakageCurrentMikroampereBUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  leakageCurrentOnlyOne?: InputMaybe<Scalars['String']>;
  leakageCurrentOnlyOneMikroampere?: InputMaybe<Scalars['String']>;
  leakageCurrentOnlyOneUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  leakageCurrentTo?: InputMaybe<Scalars['String']>;
  leakageCurrentUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  motorCableLength?: InputMaybe<Scalars['String']>;
  motorCableLengthUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  motorFrequencyFrom?: InputMaybe<Scalars['String']>;
  motorFrequencyTo?: InputMaybe<Scalars['String']>;
  motorFrequencyUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  motorPowerFrom?: InputMaybe<Scalars['String']>;
  motorPowerTo?: InputMaybe<Scalars['String']>;
  motorPowerUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  new?: InputMaybe<Scalars['Boolean']>;
  newToDate?: InputMaybe<Scalars['String']>;
  platforms?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  productType?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  qrCodeDescription?: InputMaybe<Scalars['String']>;
  qrCodeDescriptionCn?: InputMaybe<Scalars['String']>;
  qrCodeDescriptionDe?: InputMaybe<Scalars['String']>;
  ratedCurrentTemperature?: InputMaybe<Scalars['String']>;
  ratedCurrentTemperatureUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  ratedCurrentsFrom?: InputMaybe<Scalars['String']>;
  ratedCurrentsTo?: InputMaybe<Scalars['String']>;
  ratedCurrentsUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  sales?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  seoDescription?: InputMaybe<Scalars['String']>;
  seoDescriptionCn?: InputMaybe<Scalars['String']>;
  seoDescriptionDe?: InputMaybe<Scalars['String']>;
  seoKeywords?: InputMaybe<Scalars['String']>;
  seoKeywordsCn?: InputMaybe<Scalars['String']>;
  seoKeywordsDe?: InputMaybe<Scalars['String']>;
  seoTitle?: InputMaybe<Scalars['String']>;
  seoTitleCn?: InputMaybe<Scalars['String']>;
  seoTitleDe?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  shortDescriptionCn?: InputMaybe<Scalars['String']>;
  shortDescriptionDe?: InputMaybe<Scalars['String']>;
  sku: Scalars['String'];
  skuCn?: InputMaybe<Scalars['String']>;
  skuDe?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Scalars['String']>;
  spiceModel?: InputMaybe<Scalars['String']>;
  spiceModelCn?: InputMaybe<Scalars['String']>;
  spiceModelDe?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  subtitleCn?: InputMaybe<Scalars['String']>;
  subtitleDe?: InputMaybe<Scalars['String']>;
  supply?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  switchingFrequencyFrom?: InputMaybe<Scalars['String']>;
  switchingFrequencyTo?: InputMaybe<Scalars['String']>;
  switchingFrequencyUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  teaser?: InputMaybe<Scalars['String']>;
  teaserCn?: InputMaybe<Scalars['String']>;
  teaserDe?: InputMaybe<Scalars['String']>;
  technicalSpecificationsNote?: InputMaybe<Scalars['String']>;
  technicalSpecificationsNoteCn?: InputMaybe<Scalars['String']>;
  technicalSpecificationsNoteDe?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  titleCn?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
  topologyEmcConfig?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  video?: InputMaybe<Scalars['String']>;
  videoCn?: InputMaybe<Scalars['String']>;
  videoDe?: InputMaybe<Scalars['String']>;
  voltageEmcConfig?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  voltageFrom?: InputMaybe<Scalars['String']>;
  voltageTo?: InputMaybe<Scalars['String']>;
  voltageUnit?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type DataTechnicalOption = {
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  titleCn?: InputMaybe<Scalars['String']>;
  titleDe?: InputMaybe<Scalars['String']>;
};

export type DataTechnicalSpecification = {
  id: Scalars['ID'];
  options?: InputMaybe<Array<InputMaybe<DataTechnicalOption>>>;
  sorting?: InputMaybe<Scalars['String']>;
  type: Scalars['ID'];
};

export type DataUser = {
  crUser?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type DataUserGroup = {
  crUser?: InputMaybe<Scalars['ID']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type Download = {
  __typename?: 'Download';
  category?: Maybe<Array<Maybe<Scalars['ID']>>>;
  crUser?: Maybe<User>;
  exportWeb?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type DownloadArray = {
  __typename?: 'DownloadArray';
  download?: Maybe<DownloadData>;
  file?: Maybe<InlineMedia>;
};

export type DownloadData = {
  __typename?: 'DownloadData';
  category?: Maybe<Array<Maybe<Scalars['ID']>>>;
  crUser?: Maybe<Scalars['ID']>;
  exportWeb?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  sorting?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type Filter = {
  fieldName?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<InputMaybe<FilterOption>>>;
  selectedOption?: InputMaybe<FilterOption>;
};

export type FilterOption = {
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type InitInlineMedia = {
  __typename?: 'InitInlineMedia';
  field: Scalars['String'];
  inlineMedia: InlineMedia;
};

export type InlineContentElement = {
  __typename?: 'InlineContentElement';
  description?: Maybe<Scalars['String']>;
  descriptionCn?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<MediaItem>>>;
  label?: Maybe<Scalars['String']>;
  labelCn?: Maybe<Scalars['String']>;
  labelDe?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  linkCn?: Maybe<Scalars['String']>;
  linkDe?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  videoCn?: Maybe<Scalars['String']>;
  videoDe?: Maybe<Scalars['String']>;
};

export type InlineMedia = {
  __typename?: 'InlineMedia';
  allowedExtensions?: Maybe<Scalars['String']>;
  fieldType?: Maybe<Scalars['String']>;
  isMultiple?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Array<Maybe<MediaItem>>>;
};

export type InlineOptions = {
  __typename?: 'InlineOptions';
  id?: Maybe<Scalars['ID']>;
  sorting?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  textCn?: Maybe<Scalars['String']>;
  textDe?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type InlineTechnicalSpecification = {
  __typename?: 'InlineTechnicalSpecification';
  id?: Maybe<Scalars['ID']>;
  options?: Maybe<Array<Maybe<TechnicalOption>>>;
  sorting?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['ID']>;
};

export type MediaItem = {
  __typename?: 'MediaItem';
  altTitle?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createApproval: ApprovalArray;
  createArticle: ArticleArray;
  createCategory: CategoryArray;
  createCompany: CompanyArray;
  createDownload: DownloadArray;
  createPlatform: PlatformArray;
  createProduct: ProductArray;
  createUser?: Maybe<UserArray>;
  createUserGroup: UserGroupArray;
  deleteApproval: Approval;
  deleteArticle?: Maybe<Article>;
  deleteCategory?: Maybe<Category>;
  deleteCompany: Company;
  deleteDownload: Download;
  deletePlatform: Platform;
  deleteProduct?: Maybe<Product>;
  deleteUser?: Maybe<User>;
  deleteUserGroup: UserGroup;
  exportModelData: Scalars['String'];
  forgotPassword?: Maybe<Scalars['Boolean']>;
  generatePdfArticles?: Maybe<Scalars['Boolean']>;
  generatePdfProducts?: Maybe<Scalars['Boolean']>;
  importModelData: Scalars['Boolean'];
  inlineMediaUpload: Array<Maybe<MediaItem>>;
  publishApprovals?: Maybe<Scalars['String']>;
  publishArticles?: Maybe<Scalars['Boolean']>;
  publishCategories?: Maybe<Scalars['Boolean']>;
  publishCompanies?: Maybe<Scalars['Boolean']>;
  publishDownload?: Maybe<Scalars['String']>;
  publishPlatforms?: Maybe<Scalars['Boolean']>;
  publishProducts?: Maybe<Scalars['Boolean']>;
  refreshAccessToken: AuthUser;
  resetPassword?: Maybe<Scalars['Boolean']>;
  resetProfilePassword?: Maybe<ResetResponse>;
  signIn: AuthUser;
  signOut?: Maybe<Scalars['Boolean']>;
  updateApproval: ApprovalArray;
  updateArticle: ArticleArray;
  updateCategory: CategoryArray;
  updateCompany: CompanyArray;
  updateDownload: DownloadArray;
  updatePlatform: PlatformArray;
  updateProduct: ProductArray;
  updateSelectField: SelectFieldArray;
  updateUser?: Maybe<UserArray>;
  updateUserGroup: UserGroupArray;
};


export type MutationCreateApprovalArgs = {
  data: DataApproval;
  image?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationCreateArticleArgs = {
  busbarConnectionImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  data: DataArticle;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  graphImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  mechanicalImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationCreateCategoryArgs = {
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataCategory;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerMobileImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  parents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationCreateCompanyArgs = {
  data: DataCompany;
  logo?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationCreateDownloadArgs = {
  data: DataDownload;
  file?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationCreatePlatformArgs = {
  data: DataPlatform;
  model: Scalars['String'];
};


export type MutationCreateProductArgs = {
  cadData?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataProduct;
  dataSheet?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  datasheetImage?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  installationManual?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  ogImage?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  pcnFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  performanceIndicatorsImage?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  technicalSpecifications?: InputMaybe<Array<InputMaybe<DataTechnicalSpecification>>>;
  typicalElectricalSchematicImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
};


export type MutationCreateUserArgs = {
  data: DataUser;
  model: Scalars['String'];
};


export type MutationCreateUserGroupArgs = {
  data: DataUserGroup;
  model: Scalars['String'];
};


export type MutationDeleteApprovalArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteArticleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDownloadArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePlatformArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserGroupArgs = {
  id: Scalars['ID'];
};


export type MutationExportModelDataArgs = {
  model: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGeneratePdfArticlesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationGeneratePdfProductsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationImportModelDataArgs = {
  file?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  model: Scalars['String'];
};


export type MutationInlineMediaUploadArgs = {
  field: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['Upload']>>>;
  model: Scalars['String'];
};


export type MutationPublishApprovalsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationPublishArticlesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationPublishCategoriesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationPublishCompaniesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationPublishDownloadArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationPublishPlatformsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationPublishProductsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResetProfilePasswordArgs = {
  email: Scalars['String'];
  oldPassword: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateApprovalArgs = {
  data: DataApproval;
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationUpdateArticleArgs = {
  busbarConnectionImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  data?: InputMaybe<DataArticle>;
  files?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  graphImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  mechanicalImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationUpdateCategoryArgs = {
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataCategory;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerMobileImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  parents?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationUpdateCompanyArgs = {
  data: DataCompany;
  id?: InputMaybe<Scalars['ID']>;
  logo?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationUpdateDownloadArgs = {
  data: DataDownload;
  file?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type MutationUpdatePlatformArgs = {
  data: DataPlatform;
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type MutationUpdateProductArgs = {
  cadData?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contentElements?: InputMaybe<Array<InputMaybe<DataContentElement>>>;
  data: DataProduct;
  dataSheet?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  datasheetImage?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  headerImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  id?: InputMaybe<Scalars['ID']>;
  images?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  installationManual?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  model: Scalars['String'];
  ogImage?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  pcnFiles?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  performanceIndicatorsImage?: InputMaybe<Array<InputMaybe<DataMedia>>>;
  removedMedia?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  technicalSpecifications?: InputMaybe<Array<InputMaybe<DataTechnicalSpecification>>>;
  typicalElectricalSchematicImages?: InputMaybe<Array<InputMaybe<DataMedia>>>;
};


export type MutationUpdateSelectFieldArgs = {
  id?: InputMaybe<Scalars['ID']>;
  options?: InputMaybe<Array<InputMaybe<DataOption>>>;
};


export type MutationUpdateUserArgs = {
  data: DataUser;
  model: Scalars['String'];
};


export type MutationUpdateUserGroupArgs = {
  data: DataUserGroup;
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

export type Pagination = {
  limit: Scalars['Int'];
  page: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Platform = {
  __typename?: 'Platform';
  code?: Maybe<Scalars['String']>;
  crUser?: Maybe<User>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type PlatformArray = {
  __typename?: 'PlatformArray';
  platform?: Maybe<PlatformData>;
};

export type PlatformData = {
  __typename?: 'PlatformData';
  code?: Maybe<Scalars['String']>;
  crUser?: Maybe<Scalars['ID']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  accessories?: Maybe<Array<Maybe<Product>>>;
  applications?: Maybe<Scalars['String']>;
  applicationsCn?: Maybe<Scalars['String']>;
  applicationsDe?: Maybe<Scalars['String']>;
  approvals?: Maybe<Array<Maybe<Approval>>>;
  approvalsNote?: Maybe<Scalars['String']>;
  approvalsNoteCn?: Maybe<Scalars['String']>;
  approvalsNoteDe?: Maybe<Scalars['String']>;
  articles?: Maybe<Array<Maybe<Article>>>;
  attenuationPerf?: Maybe<Array<Maybe<Scalars['ID']>>>;
  cadData?: Maybe<Scalars['String']>;
  cadFileLink?: Maybe<Scalars['String']>;
  cadFileLinkCn?: Maybe<Scalars['String']>;
  cadFileLinkDe?: Maybe<Scalars['String']>;
  cadLink?: Maybe<Scalars['String']>;
  cadLinkCn?: Maybe<Scalars['String']>;
  cadLinkDe?: Maybe<Scalars['String']>;
  capacitanceFrom?: Maybe<Scalars['String']>;
  capacitanceTo?: Maybe<Scalars['String']>;
  capacitanceUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  chassisMount?: Maybe<Scalars['Boolean']>;
  crUser?: Maybe<User>;
  currentConfig?: Maybe<Array<Maybe<Scalars['ID']>>>;
  currentType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  description?: Maybe<Scalars['String']>;
  descriptionAdditional?: Maybe<Scalars['String']>;
  descriptionAdditionalCn?: Maybe<Scalars['String']>;
  descriptionAdditionalDe?: Maybe<Scalars['String']>;
  descriptionCn?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  downloads?: Maybe<Array<Maybe<Download>>>;
  ePlan?: Maybe<Scalars['String']>;
  ePlanCn?: Maybe<Scalars['String']>;
  ePlanDe?: Maybe<Scalars['String']>;
  exportWeb?: Maybe<Scalars['Boolean']>;
  featuresAndBenefits?: Maybe<Scalars['String']>;
  featuresAndBenefitsCn?: Maybe<Scalars['String']>;
  featuresAndBenefitsDe?: Maybe<Scalars['String']>;
  frequencyDcToKhz?: Maybe<Scalars['String']>;
  frequencyDcToKhzUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  frequencyFrom?: Maybe<Scalars['String']>;
  frequencyTo?: Maybe<Scalars['String']>;
  frequencyUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  headquarters?: Maybe<Array<Maybe<Company>>>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inductanceFrom?: Maybe<Scalars['String']>;
  inductanceTo?: Maybe<Scalars['String']>;
  inductanceUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  introText?: Maybe<Scalars['String']>;
  introTextCn?: Maybe<Scalars['String']>;
  introTextDe?: Maybe<Scalars['String']>;
  leakageCurrentA?: Maybe<Scalars['String']>;
  leakageCurrentAUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  leakageCurrentB?: Maybe<Scalars['String']>;
  leakageCurrentBUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  leakageCurrentFrom?: Maybe<Scalars['String']>;
  leakageCurrentMikroampereB?: Maybe<Scalars['String']>;
  leakageCurrentMikroampereBUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  leakageCurrentOnlyOne?: Maybe<Scalars['String']>;
  leakageCurrentOnlyOneMikroampere?: Maybe<Scalars['String']>;
  leakageCurrentOnlyOneUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  leakageCurrentTo?: Maybe<Scalars['String']>;
  leakageCurrentUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  motorCableLength?: Maybe<Scalars['String']>;
  motorCableLengthUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  motorFrequencyFrom?: Maybe<Scalars['String']>;
  motorFrequencyTo?: Maybe<Scalars['String']>;
  motorFrequencyUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  motorPowerFrom?: Maybe<Scalars['String']>;
  motorPowerTo?: Maybe<Scalars['String']>;
  motorPowerUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  new?: Maybe<Scalars['Boolean']>;
  newToDate?: Maybe<Scalars['String']>;
  platforms?: Maybe<Array<Maybe<Platform>>>;
  productType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  qrCodeDescription?: Maybe<Scalars['String']>;
  qrCodeDescriptionCn?: Maybe<Scalars['String']>;
  qrCodeDescriptionDe?: Maybe<Scalars['String']>;
  ratedCurrentTemperature?: Maybe<Scalars['String']>;
  ratedCurrentTemperatureUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ratedCurrentsFrom?: Maybe<Scalars['String']>;
  ratedCurrentsTo?: Maybe<Scalars['String']>;
  ratedCurrentsUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sales?: Maybe<Array<Maybe<Company>>>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionCn?: Maybe<Scalars['String']>;
  seoDescriptionDe?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoKeywordsCn?: Maybe<Scalars['String']>;
  seoKeywordsDe?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleCn?: Maybe<Scalars['String']>;
  seoTitleDe?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionCn?: Maybe<Scalars['String']>;
  shortDescriptionDe?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  skuCn?: Maybe<Scalars['String']>;
  skuDe?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  spiceModel?: Maybe<Scalars['String']>;
  spiceModelCn?: Maybe<Scalars['String']>;
  spiceModelDe?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitleCn?: Maybe<Scalars['String']>;
  subtitleDe?: Maybe<Scalars['String']>;
  switchingFrequencyFrom?: Maybe<Scalars['String']>;
  switchingFrequencyTo?: Maybe<Scalars['String']>;
  switchingFrequencyUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  teaser?: Maybe<Scalars['String']>;
  teaserDe?: Maybe<Scalars['String']>;
  technicalSpecificationsNote?: Maybe<Scalars['String']>;
  technicalSpecificationsNoteCn?: Maybe<Scalars['String']>;
  technicalSpecificationsNoteDe?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  topologyEmcConfig?: Maybe<Array<Maybe<Scalars['ID']>>>;
  video?: Maybe<Scalars['String']>;
  videoCn?: Maybe<Scalars['String']>;
  videoDe?: Maybe<Scalars['String']>;
  voltageEmcConfig?: Maybe<Array<Maybe<Scalars['ID']>>>;
  voltageFrom?: Maybe<Scalars['String']>;
  voltageTo?: Maybe<Scalars['String']>;
  voltageUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ProductArray = {
  __typename?: 'ProductArray';
  cadData?: Maybe<InlineMedia>;
  contentElements?: Maybe<Array<Maybe<InlineContentElement>>>;
  dataSheet?: Maybe<InlineMedia>;
  datasheetImage?: Maybe<InlineMedia>;
  headerImages?: Maybe<InlineMedia>;
  images?: Maybe<InlineMedia>;
  installationManual?: Maybe<InlineMedia>;
  ogImage?: Maybe<InlineMedia>;
  pcnFiles?: Maybe<InlineMedia>;
  performanceIndicatorsImage?: Maybe<InlineMedia>;
  product?: Maybe<ProductData>;
  technicalSpecifications?: Maybe<Array<Maybe<InlineTechnicalSpecification>>>;
  typicalElectricalSchematicImages?: Maybe<InlineMedia>;
};

export type ProductData = {
  __typename?: 'ProductData';
  accessories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  applications?: Maybe<Scalars['String']>;
  applicationsCn?: Maybe<Scalars['String']>;
  applicationsDe?: Maybe<Scalars['String']>;
  approvals?: Maybe<Array<Maybe<Scalars['ID']>>>;
  approvalsNote?: Maybe<Scalars['String']>;
  approvalsNoteCn?: Maybe<Scalars['String']>;
  approvalsNoteDe?: Maybe<Scalars['String']>;
  articles?: Maybe<Array<Maybe<Scalars['ID']>>>;
  attenuationPerf?: Maybe<Array<Maybe<Scalars['ID']>>>;
  cadData?: Maybe<Scalars['String']>;
  cadFileLink?: Maybe<Scalars['String']>;
  cadFileLinkCn?: Maybe<Scalars['String']>;
  cadFileLinkDe?: Maybe<Scalars['String']>;
  cadLink?: Maybe<Scalars['String']>;
  cadLinkCn?: Maybe<Scalars['String']>;
  cadLinkDe?: Maybe<Scalars['String']>;
  capacitanceFrom?: Maybe<Scalars['String']>;
  capacitanceTo?: Maybe<Scalars['String']>;
  capacitanceUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  chassisMount?: Maybe<Scalars['Boolean']>;
  crUser?: Maybe<Scalars['ID']>;
  currentConfig?: Maybe<Array<Maybe<Scalars['ID']>>>;
  currentType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  description?: Maybe<Scalars['String']>;
  descriptionAdditional?: Maybe<Scalars['String']>;
  descriptionAdditionalCn?: Maybe<Scalars['String']>;
  descriptionAdditionalDe?: Maybe<Scalars['String']>;
  descriptionCn?: Maybe<Scalars['String']>;
  descriptionDe?: Maybe<Scalars['String']>;
  downloads?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ePlan?: Maybe<Scalars['String']>;
  ePlanCn?: Maybe<Scalars['String']>;
  ePlanDe?: Maybe<Scalars['String']>;
  exportWeb?: Maybe<Scalars['Boolean']>;
  featuresAndBenefits?: Maybe<Scalars['String']>;
  featuresAndBenefitsCn?: Maybe<Scalars['String']>;
  featuresAndBenefitsDe?: Maybe<Scalars['String']>;
  frequencyDcToKhz?: Maybe<Scalars['String']>;
  frequencyDcToKhzUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  frequencyFrom?: Maybe<Scalars['String']>;
  frequencyTo?: Maybe<Scalars['String']>;
  frequencyUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  headquarters?: Maybe<Array<Maybe<Scalars['ID']>>>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  inductanceFrom?: Maybe<Scalars['String']>;
  inductanceTo?: Maybe<Scalars['String']>;
  inductanceUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  introText?: Maybe<Scalars['String']>;
  introTextCn?: Maybe<Scalars['String']>;
  introTextDe?: Maybe<Scalars['String']>;
  leakageCurrentA?: Maybe<Scalars['String']>;
  leakageCurrentAUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  leakageCurrentB?: Maybe<Scalars['String']>;
  leakageCurrentBUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  leakageCurrentFrom?: Maybe<Scalars['String']>;
  leakageCurrentMikroampereB?: Maybe<Scalars['String']>;
  leakageCurrentMikroampereBUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  leakageCurrentOnlyOne?: Maybe<Scalars['String']>;
  leakageCurrentOnlyOneMikroampere?: Maybe<Scalars['String']>;
  leakageCurrentOnlyOneUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  leakageCurrentTo?: Maybe<Scalars['String']>;
  leakageCurrentUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  motorCableLength?: Maybe<Scalars['String']>;
  motorCableLengthUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  motorFrequencyFrom?: Maybe<Scalars['String']>;
  motorFrequencyTo?: Maybe<Scalars['String']>;
  motorFrequencyUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  motorPowerFrom?: Maybe<Scalars['String']>;
  motorPowerTo?: Maybe<Scalars['String']>;
  motorPowerUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  new?: Maybe<Scalars['Boolean']>;
  newToDate?: Maybe<Scalars['String']>;
  platforms?: Maybe<Array<Maybe<Scalars['ID']>>>;
  productType?: Maybe<Array<Maybe<Scalars['ID']>>>;
  qrCodeDescription?: Maybe<Scalars['String']>;
  qrCodeDescriptionCn?: Maybe<Scalars['String']>;
  qrCodeDescriptionDe?: Maybe<Scalars['String']>;
  ratedCurrentTemperature?: Maybe<Scalars['String']>;
  ratedCurrentTemperatureUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  ratedCurrentsFrom?: Maybe<Scalars['String']>;
  ratedCurrentsTo?: Maybe<Scalars['String']>;
  ratedCurrentsUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sales?: Maybe<Array<Maybe<Scalars['ID']>>>;
  seoDescription?: Maybe<Scalars['String']>;
  seoDescriptionCn?: Maybe<Scalars['String']>;
  seoDescriptionDe?: Maybe<Scalars['String']>;
  seoKeywords?: Maybe<Scalars['String']>;
  seoKeywordsCn?: Maybe<Scalars['String']>;
  seoKeywordsDe?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  seoTitleCn?: Maybe<Scalars['String']>;
  seoTitleDe?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescriptionCn?: Maybe<Scalars['String']>;
  shortDescriptionDe?: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  skuCn?: Maybe<Scalars['String']>;
  skuDe?: Maybe<Scalars['String']>;
  sorting?: Maybe<Scalars['String']>;
  spiceModel?: Maybe<Scalars['String']>;
  spiceModelCn?: Maybe<Scalars['String']>;
  spiceModelDe?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  subtitleCn?: Maybe<Scalars['String']>;
  subtitleDe?: Maybe<Scalars['String']>;
  supply?: Maybe<Array<Maybe<Scalars['ID']>>>;
  switchingFrequencyFrom?: Maybe<Scalars['String']>;
  switchingFrequencyTo?: Maybe<Scalars['String']>;
  switchingFrequencyUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
  teaser?: Maybe<Scalars['String']>;
  teaserCn?: Maybe<Scalars['String']>;
  teaserDe?: Maybe<Scalars['String']>;
  technicalSpecificationsNote?: Maybe<Scalars['String']>;
  technicalSpecificationsNoteCn?: Maybe<Scalars['String']>;
  technicalSpecificationsNoteDe?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
  topologyEmcConfig?: Maybe<Array<Maybe<Scalars['ID']>>>;
  video?: Maybe<Scalars['String']>;
  videoCn?: Maybe<Scalars['String']>;
  videoDe?: Maybe<Scalars['String']>;
  voltageEmcConfig?: Maybe<Array<Maybe<Scalars['ID']>>>;
  voltageFrom?: Maybe<Scalars['String']>;
  voltageTo?: Maybe<Scalars['String']>;
  voltageUnit?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Query = {
  __typename?: 'Query';
  approval: ApprovalArray;
  article: ArticleArray;
  categoriesThree?: Maybe<Scalars['Json']>;
  category: CategoryArray;
  company: CompanyArray;
  download: DownloadArray;
  getDynamicTable?: Maybe<Scalars['Json']>;
  getSelectField?: Maybe<SelectFieldArray>;
  getSelectFields?: Maybe<Array<Maybe<SelectField>>>;
  getSpecificSelect?: Maybe<Array<Maybe<SelectOption>>>;
  getTechnicalSpecificationSelect?: Maybe<Array<Maybe<SelectOption>>>;
  getUnitSelect?: Maybe<Array<Maybe<UnitOption>>>;
  inlineMedia?: Maybe<Array<InitInlineMedia>>;
  platform: PlatformArray;
  product: ProductArray;
  selectAccessories: Array<Product>;
  selectApprovals: Array<Approval>;
  selectArticles?: Maybe<Array<Maybe<Article>>>;
  selectCompanies: Array<Company>;
  selectCountries: Array<Country>;
  selectDownloads: Array<Download>;
  selectPlatforms: Array<Platform>;
  selectProduct: Array<Product>;
  selectStates: Array<State>;
  translateApproval: ApprovalArray;
  translateArticle: ArticleArray;
  translateCategory: CategoryArray;
  translateCompany: CompanyArray;
  translateDownload: DownloadArray;
  translatePlatform: PlatformArray;
  translateProduct: ProductArray;
  user: UserArray;
  userGroup: UserGroupArray;
};


export type QueryApprovalArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryArticleArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryCategoriesThreeArgs = {
  id?: InputMaybe<Scalars['ID']>;
  model?: InputMaybe<Scalars['String']>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryDownloadArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryGetDynamicTableArgs = {
  filter?: InputMaybe<Scalars['Json']>;
  model: Scalars['String'];
  pagination: Pagination;
  search?: InputMaybe<Scalars['String']>;
  sorting?: InputMaybe<Sorting>;
};


export type QueryGetSelectFieldArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetSpecificSelectArgs = {
  model: Scalars['String'];
};


export type QueryGetTechnicalSpecificationSelectArgs = {
  model: Scalars['String'];
};


export type QueryGetUnitSelectArgs = {
  model: Scalars['String'];
};


export type QueryInlineMediaArgs = {
  fields?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryPlatformArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QueryProductArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};


export type QuerySelectAccessoriesArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectApprovalsArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectArticlesArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySelectCompaniesArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectCountriesArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectDownloadsArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectPlatformsArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QuerySelectStatesArgs = {
  trashed?: InputMaybe<Trashed>;
};


export type QueryTranslateApprovalArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslateArticleArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslateCategoryArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslateCompanyArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslateDownloadArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslatePlatformArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryTranslateProductArgs = {
  id: Scalars['ID'];
  language: Scalars['String'];
  model: Scalars['String'];
};


export type QueryUserArgs = {
  model: Scalars['String'];
};


export type QueryUserGroupArgs = {
  id: Scalars['ID'];
  model: Scalars['String'];
};

export type ResetResponse = {
  __typename?: 'ResetResponse';
  error?: Maybe<Scalars['Json']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SelectField = {
  __typename?: 'SelectField';
  field: Scalars['String'];
  id: Scalars['ID'];
  model: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type SelectFieldArray = {
  __typename?: 'SelectFieldArray';
  options?: Maybe<Array<Maybe<InlineOptions>>>;
  selectField?: Maybe<SelectField>;
};

export type SelectOption = {
  __typename?: 'SelectOption';
  field: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type Sorting = {
  field?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['String']>;
};

export type State = {
  __typename?: 'State';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export type TechnicalOption = {
  __typename?: 'TechnicalOption';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  titleCn?: Maybe<Scalars['String']>;
  titleDe?: Maybe<Scalars['String']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UnitOption = {
  __typename?: 'UnitOption';
  default?: Maybe<Scalars['Boolean']>;
  field: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  crUser?: Maybe<User>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isOnline?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type UserArray = {
  __typename?: 'UserArray';
  user?: Maybe<UserData>;
};

export type UserData = {
  __typename?: 'UserData';
  crUser?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isOnline?: Maybe<Scalars['Boolean']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  cr_user?: Maybe<User>;
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type UserGroupArray = {
  __typename?: 'UserGroupArray';
  images?: Maybe<InlineMedia>;
  userGroup?: Maybe<UserGroupData>;
};

export type UserGroupData = {
  __typename?: 'UserGroupData';
  crUser?: Maybe<Scalars['ID']>;
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type ApprovalQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  model: Types.Scalars['String'];
}>;


export type ApprovalQuery = { __typename?: 'Query', approval: { __typename?: 'ApprovalArray', approval?: { __typename?: 'ApprovalData', title?: string | null, sorting?: string | null, link?: string | null } | null, image?: { __typename?: 'InlineMedia', fieldType?: string | null, allowedExtensions?: string | null, isMultiple?: boolean | null, items?: Array<{ __typename?: 'MediaItem', id: string, fileName?: string | null, filePath?: string | null, thumbnail?: string | null, title?: string | null, altTitle?: string | null, description?: string | null, author?: string | null } | null> | null } | null }, selectPlatforms: Array<{ __typename?: 'Platform', id: string, title?: string | null }> };

export type NewApprovalQueryVariables = Types.Exact<{
  fields?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
}>;


export type NewApprovalQuery = { __typename?: 'Query', inlineMedia?: Array<{ __typename?: 'InitInlineMedia', field: string, inlineMedia: { __typename?: 'InlineMedia', fieldType?: string | null, allowedExtensions?: string | null, isMultiple?: boolean | null } }> | null, selectPlatforms: Array<{ __typename?: 'Platform', id: string, title?: string | null }> };

export type TranslateApprovalQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  model: Types.Scalars['String'];
  language: Types.Scalars['String'];
}>;


export type TranslateApprovalQuery = { __typename?: 'Query', translateApproval: { __typename?: 'ApprovalArray', approval?: { __typename?: 'ApprovalData', title?: string | null, titleDe?: string | null, titleCn?: string | null, link?: string | null, linkDe?: string | null, linkCn?: string | null } | null } };

export type ApprovalsListQueryVariables = Types.Exact<{
  model: Types.Scalars['String'];
  search?: Types.InputMaybe<Types.Scalars['String']>;
  pagination: Types.Pagination;
  sorting?: Types.InputMaybe<Types.Sorting>;
}>;


export type ApprovalsListQuery = { __typename?: 'Query', getDynamicTable?: any | null };

export type UpdateApprovalMutationVariables = Types.Exact<{
  model: Types.Scalars['String'];
  id: Types.Scalars['ID'];
  data: Types.DataApproval;
  image?: Types.InputMaybe<Array<Types.InputMaybe<Types.DataMedia>> | Types.InputMaybe<Types.DataMedia>>;
  removedMedia?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
}>;


export type UpdateApprovalMutation = { __typename?: 'Mutation', updateApproval: { __typename?: 'ApprovalArray', approval?: { __typename?: 'ApprovalData', id: string } | null } };

export type CreateApprovalMutationVariables = Types.Exact<{
  model: Types.Scalars['String'];
  data: Types.DataApproval;
  image?: Types.InputMaybe<Array<Types.InputMaybe<Types.DataMedia>> | Types.InputMaybe<Types.DataMedia>>;
  removedMedia?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
}>;


export type CreateApprovalMutation = { __typename?: 'Mutation', createApproval: { __typename?: 'ApprovalArray', approval?: { __typename?: 'ApprovalData', id: string } | null } };

export type InlineMediaUploadApprovalMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  model: Types.Scalars['String'];
  field: Types.Scalars['String'];
  images?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Upload']>> | Types.InputMaybe<Types.Scalars['Upload']>>;
}>;


export type InlineMediaUploadApprovalMutation = { __typename?: 'Mutation', inlineMediaUpload: Array<{ __typename?: 'MediaItem', id: string, fileName?: string | null, filePath?: string | null, thumbnail?: string | null, title?: string | null, altTitle?: string | null, description?: string | null, author?: string | null } | null> };

export type DeleteApprovalMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteApprovalMutation = { __typename?: 'Mutation', deleteApproval: { __typename?: 'Approval', id: string } };

export type PublishApprovalsMutationVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
}>;


export type PublishApprovalsMutation = { __typename?: 'Mutation', publishApprovals?: string | null };


export const ApprovalDocument = gql`
    query Approval($id: ID!, $model: String!) {
  approval(id: $id, model: $model) {
    approval {
      title
      sorting
      link
    }
    image {
      items {
        id
        fileName
        filePath
        thumbnail
        title
        altTitle
        description
        author
      }
      fieldType
      allowedExtensions
      isMultiple
    }
  }
  selectPlatforms {
    id
    title
  }
}
    `;

/**
 * __useApprovalQuery__
 *
 * To run a query within a React component, call `useApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalQuery({
 *   variables: {
 *      id: // value for 'id'
 *      model: // value for 'model'
 *   },
 * });
 */
export function useApprovalQuery(baseOptions: Apollo.QueryHookOptions<ApprovalQuery, ApprovalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovalQuery, ApprovalQueryVariables>(ApprovalDocument, options);
      }
export function useApprovalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovalQuery, ApprovalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovalQuery, ApprovalQueryVariables>(ApprovalDocument, options);
        }
export type ApprovalQueryHookResult = ReturnType<typeof useApprovalQuery>;
export type ApprovalLazyQueryHookResult = ReturnType<typeof useApprovalLazyQuery>;
export type ApprovalQueryResult = Apollo.QueryResult<ApprovalQuery, ApprovalQueryVariables>;
export const NewApprovalDocument = gql`
    query newApproval($fields: [String!]) {
  inlineMedia(fields: $fields) {
    field
    inlineMedia {
      fieldType
      allowedExtensions
      isMultiple
    }
  }
  selectPlatforms {
    id
    title
  }
}
    `;

/**
 * __useNewApprovalQuery__
 *
 * To run a query within a React component, call `useNewApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewApprovalQuery({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useNewApprovalQuery(baseOptions?: Apollo.QueryHookOptions<NewApprovalQuery, NewApprovalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewApprovalQuery, NewApprovalQueryVariables>(NewApprovalDocument, options);
      }
export function useNewApprovalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewApprovalQuery, NewApprovalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewApprovalQuery, NewApprovalQueryVariables>(NewApprovalDocument, options);
        }
export type NewApprovalQueryHookResult = ReturnType<typeof useNewApprovalQuery>;
export type NewApprovalLazyQueryHookResult = ReturnType<typeof useNewApprovalLazyQuery>;
export type NewApprovalQueryResult = Apollo.QueryResult<NewApprovalQuery, NewApprovalQueryVariables>;
export const TranslateApprovalDocument = gql`
    query TranslateApproval($id: ID!, $model: String!, $language: String!) {
  translateApproval(id: $id, model: $model, language: $language) {
    approval {
      title
      titleDe
      titleCn
      link
      linkDe
      linkCn
    }
  }
}
    `;

/**
 * __useTranslateApprovalQuery__
 *
 * To run a query within a React component, call `useTranslateApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslateApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslateApprovalQuery({
 *   variables: {
 *      id: // value for 'id'
 *      model: // value for 'model'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useTranslateApprovalQuery(baseOptions: Apollo.QueryHookOptions<TranslateApprovalQuery, TranslateApprovalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TranslateApprovalQuery, TranslateApprovalQueryVariables>(TranslateApprovalDocument, options);
      }
export function useTranslateApprovalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TranslateApprovalQuery, TranslateApprovalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TranslateApprovalQuery, TranslateApprovalQueryVariables>(TranslateApprovalDocument, options);
        }
export type TranslateApprovalQueryHookResult = ReturnType<typeof useTranslateApprovalQuery>;
export type TranslateApprovalLazyQueryHookResult = ReturnType<typeof useTranslateApprovalLazyQuery>;
export type TranslateApprovalQueryResult = Apollo.QueryResult<TranslateApprovalQuery, TranslateApprovalQueryVariables>;
export const ApprovalsListDocument = gql`
    query ApprovalsList($model: String!, $search: String, $pagination: Pagination!, $sorting: Sorting) {
  getDynamicTable(
    model: $model
    search: $search
    pagination: $pagination
    sorting: $sorting
  )
}
    `;

/**
 * __useApprovalsListQuery__
 *
 * To run a query within a React component, call `useApprovalsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovalsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovalsListQuery({
 *   variables: {
 *      model: // value for 'model'
 *      search: // value for 'search'
 *      pagination: // value for 'pagination'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useApprovalsListQuery(baseOptions: Apollo.QueryHookOptions<ApprovalsListQuery, ApprovalsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovalsListQuery, ApprovalsListQueryVariables>(ApprovalsListDocument, options);
      }
export function useApprovalsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovalsListQuery, ApprovalsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovalsListQuery, ApprovalsListQueryVariables>(ApprovalsListDocument, options);
        }
export type ApprovalsListQueryHookResult = ReturnType<typeof useApprovalsListQuery>;
export type ApprovalsListLazyQueryHookResult = ReturnType<typeof useApprovalsListLazyQuery>;
export type ApprovalsListQueryResult = Apollo.QueryResult<ApprovalsListQuery, ApprovalsListQueryVariables>;
export const UpdateApprovalDocument = gql`
    mutation UpdateApproval($model: String!, $id: ID!, $data: DataApproval!, $image: [DataMedia], $removedMedia: [ID]) {
  updateApproval(
    model: $model
    id: $id
    data: $data
    image: $image
    removedMedia: $removedMedia
  ) {
    approval {
      id
    }
  }
}
    `;
export type UpdateApprovalMutationFn = Apollo.MutationFunction<UpdateApprovalMutation, UpdateApprovalMutationVariables>;

/**
 * __useUpdateApprovalMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalMutation, { data, loading, error }] = useUpdateApprovalMutation({
 *   variables: {
 *      model: // value for 'model'
 *      id: // value for 'id'
 *      data: // value for 'data'
 *      image: // value for 'image'
 *      removedMedia: // value for 'removedMedia'
 *   },
 * });
 */
export function useUpdateApprovalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApprovalMutation, UpdateApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApprovalMutation, UpdateApprovalMutationVariables>(UpdateApprovalDocument, options);
      }
export type UpdateApprovalMutationHookResult = ReturnType<typeof useUpdateApprovalMutation>;
export type UpdateApprovalMutationResult = Apollo.MutationResult<UpdateApprovalMutation>;
export type UpdateApprovalMutationOptions = Apollo.BaseMutationOptions<UpdateApprovalMutation, UpdateApprovalMutationVariables>;
export const CreateApprovalDocument = gql`
    mutation CreateApproval($model: String!, $data: DataApproval!, $image: [DataMedia], $removedMedia: [ID]) {
  createApproval(
    model: $model
    data: $data
    image: $image
    removedMedia: $removedMedia
  ) {
    approval {
      id
    }
  }
}
    `;
export type CreateApprovalMutationFn = Apollo.MutationFunction<CreateApprovalMutation, CreateApprovalMutationVariables>;

/**
 * __useCreateApprovalMutation__
 *
 * To run a mutation, you first call `useCreateApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApprovalMutation, { data, loading, error }] = useCreateApprovalMutation({
 *   variables: {
 *      model: // value for 'model'
 *      data: // value for 'data'
 *      image: // value for 'image'
 *      removedMedia: // value for 'removedMedia'
 *   },
 * });
 */
export function useCreateApprovalMutation(baseOptions?: Apollo.MutationHookOptions<CreateApprovalMutation, CreateApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApprovalMutation, CreateApprovalMutationVariables>(CreateApprovalDocument, options);
      }
export type CreateApprovalMutationHookResult = ReturnType<typeof useCreateApprovalMutation>;
export type CreateApprovalMutationResult = Apollo.MutationResult<CreateApprovalMutation>;
export type CreateApprovalMutationOptions = Apollo.BaseMutationOptions<CreateApprovalMutation, CreateApprovalMutationVariables>;
export const InlineMediaUploadApprovalDocument = gql`
    mutation InlineMediaUploadApproval($id: ID, $model: String!, $field: String!, $images: [Upload]) {
  inlineMediaUpload(id: $id, model: $model, field: $field, images: $images) {
    id
    fileName
    filePath
    thumbnail
    title
    altTitle
    description
    author
  }
}
    `;
export type InlineMediaUploadApprovalMutationFn = Apollo.MutationFunction<InlineMediaUploadApprovalMutation, InlineMediaUploadApprovalMutationVariables>;

/**
 * __useInlineMediaUploadApprovalMutation__
 *
 * To run a mutation, you first call `useInlineMediaUploadApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInlineMediaUploadApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inlineMediaUploadApprovalMutation, { data, loading, error }] = useInlineMediaUploadApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      model: // value for 'model'
 *      field: // value for 'field'
 *      images: // value for 'images'
 *   },
 * });
 */
export function useInlineMediaUploadApprovalMutation(baseOptions?: Apollo.MutationHookOptions<InlineMediaUploadApprovalMutation, InlineMediaUploadApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InlineMediaUploadApprovalMutation, InlineMediaUploadApprovalMutationVariables>(InlineMediaUploadApprovalDocument, options);
      }
export type InlineMediaUploadApprovalMutationHookResult = ReturnType<typeof useInlineMediaUploadApprovalMutation>;
export type InlineMediaUploadApprovalMutationResult = Apollo.MutationResult<InlineMediaUploadApprovalMutation>;
export type InlineMediaUploadApprovalMutationOptions = Apollo.BaseMutationOptions<InlineMediaUploadApprovalMutation, InlineMediaUploadApprovalMutationVariables>;
export const DeleteApprovalDocument = gql`
    mutation DeleteApproval($id: ID!) {
  deleteApproval(id: $id) {
    id
  }
}
    `;
export type DeleteApprovalMutationFn = Apollo.MutationFunction<DeleteApprovalMutation, DeleteApprovalMutationVariables>;

/**
 * __useDeleteApprovalMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalMutation, { data, loading, error }] = useDeleteApprovalMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApprovalMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApprovalMutation, DeleteApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApprovalMutation, DeleteApprovalMutationVariables>(DeleteApprovalDocument, options);
      }
export type DeleteApprovalMutationHookResult = ReturnType<typeof useDeleteApprovalMutation>;
export type DeleteApprovalMutationResult = Apollo.MutationResult<DeleteApprovalMutation>;
export type DeleteApprovalMutationOptions = Apollo.BaseMutationOptions<DeleteApprovalMutation, DeleteApprovalMutationVariables>;
export const PublishApprovalsDocument = gql`
    mutation PublishApprovals($ids: [ID]) {
  publishApprovals(ids: $ids)
}
    `;
export type PublishApprovalsMutationFn = Apollo.MutationFunction<PublishApprovalsMutation, PublishApprovalsMutationVariables>;

/**
 * __usePublishApprovalsMutation__
 *
 * To run a mutation, you first call `usePublishApprovalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishApprovalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishApprovalsMutation, { data, loading, error }] = usePublishApprovalsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePublishApprovalsMutation(baseOptions?: Apollo.MutationHookOptions<PublishApprovalsMutation, PublishApprovalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishApprovalsMutation, PublishApprovalsMutationVariables>(PublishApprovalsDocument, options);
      }
export type PublishApprovalsMutationHookResult = ReturnType<typeof usePublishApprovalsMutation>;
export type PublishApprovalsMutationResult = Apollo.MutationResult<PublishApprovalsMutation>;
export type PublishApprovalsMutationOptions = Apollo.BaseMutationOptions<PublishApprovalsMutation, PublishApprovalsMutationVariables>;