import React, {createRef, RefObject} from "react";
import MediaItem from "./MediaItem";
import {Button, Grid} from "@mui/material";

// Types
import {InlineMediaType, MediaItemType} from "../../types/inlineMedia";

// Styling
import styled from "styled-components/macro";
import {Plus} from "react-feather";

const Input = styled('input')({display: 'none',});

const InlineMedia: React.FC<InlineMediaType> = (props) => {
    const {fieldType, allowedExtensions, isMultiple, onUpdate, onAdd, onDelete} = props;
    const fileInput: RefObject<HTMLInputElement> = createRef();

    // Prepare files for upload
    const filesHandler = (event: any) => {
        const files = event;
        if (!files) return;
        onAdd(files);
    }

    // Trigger file update
    const onUpdateHandler = (data: any) => {
        onUpdate(data);
    }

    // Trigger file delete
    const onDeleteHandler = (data: MediaItemType) => {
        onDelete(data.id);
    }

    const addFile = () => {
        if (!isMultiple && props.items.length > 0) {
            return
        }

        if (fileInput.current) {
            fileInput.current.click();
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={6}>
                {props.items.length > 0 &&
                    <Grid item xs={12}>
                        {props.items.map((item: MediaItemType) => {
                            return (
                                <MediaItem key={item.id} item={item} fieldType={fieldType} onUpdate={onUpdateHandler}
                                           onDelete={onDeleteHandler}/>
                            )
                        })}
                    </Grid>
                }

                <Grid item xs={12} mb={5}>
                    <Input
                        ref={fileInput}
                        accept={allowedExtensions}
                        multiple={isMultiple}
                        type="file"
                        name="images"
                        onChange={(e: any) => {
                            filesHandler(e.target.files)
                        }}/>
                    <Button variant="contained" component="span" startIcon={<Plus/>}
                            disabled={!isMultiple && props.items.length > 0} onClick={addFile}>Add
                        media</Button>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}

export default InlineMedia;
