import React from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Divider as MuiDivider,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";


const Divider = styled(MuiDivider)(spacing);


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const PromotionProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange} = props;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Intro text
            </Typography>

            <Grid container spacing={6}>

                <Grid item xs={12} mb={5}>
                    <TextField
                        label="Intro text"
                        name="introText"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.introText ? values.introText : ''}
                        onChange={onChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
            <Typography variant="h6" mb={5}>
                SEO (Website)
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="seoTitle"
                        label="Seo Title"
                        value={values.seoTitle ? values.seoTitle : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} mb={5}>
                    <TextField
                        label="Seo Description"
                        name="seoDescription"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.seoDescription ? values.seoDescription : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} mb={5}>
                    <TextField
                        label="Seo Keywords"
                        name="seoKeywords"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.seoKeywords ? values.seoKeywords : ''}
                        onChange={onChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default PromotionProduct;
