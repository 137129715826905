import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    InputLabel, FormControl, Select, MenuItem, Divider as MuiDivider,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";

const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const MechanicArticle: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const lengthUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'lengthUnit');
    const widthUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'widthUnit');
    const heightUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'heightUnit');
    const volumeUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'volumeUnit');
    const netWeightUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'netWeightUnit');
    const powerLossUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'powerLossUnit');
    const typicalDrivePowerRatingUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'typicalDrivePowerRatingUnit');

    const lengthUnitsDefault: { id: string }[] = lengthUnits.filter((item: any) => item.default);
    const widthUnitsDefault: { id: string }[] = widthUnits.filter((item: any) => item.default);
    const heightUnitsDefault: { id: string }[] = heightUnits.filter((item: any) => item.default);
    const volumeUnitsDefault: { id: string }[] = volumeUnits.filter((item: any) => item.default);
    const netWeightUnitsDefault: { id: string }[] = netWeightUnits.filter((item: any) => item.default);
    const powerLossUnitsDefault: { id: string }[] = powerLossUnits.filter((item: any) => item.default);
    const typicalDrivePowerRatingUnitsDefault: { id: string }[] = typicalDrivePowerRatingUnits.filter((item: any) => item.default);

    // States
    const [lengthUnit, setLengthUnit] = useState(state.lengthUnit ? ( state.lengthUnit.length == 0 ? lengthUnitsDefault[0]?.id : state.lengthUnit) : (values.lengthUnit ? values.lengthUnit[0] : lengthUnitsDefault[0]?.id));
    const [widthUnit, setWidthUnit] = useState(state.widthUnit ? ( state.widthUnit.length == 0 ? widthUnitsDefault[0]?.id : state.widthUnit) : (values.widthUnit ? values.widthUnit[0] : widthUnitsDefault[0]?.id));
    const [heightUnit, setHeightUnit] = useState(state.heightUnit ? ( state.heightUnit.length == 0 ? heightUnitsDefault[0]?.id : state.heightUnit) : (values.heightUnit ? values.heightUnit[0] : heightUnitsDefault[0]?.id));
    const [volumeUnit, setVolumeUnit] = useState(state.volumeUnit ? ( state.volumeUnit.length == 0 ? volumeUnitsDefault[0]?.id : state.volumeUnit) : (values.volumeUnit ? values.volumeUnit[0] : volumeUnitsDefault[0]?.id));
    const [netWeightUnit, setNetWeightUnit] = useState(state.netWeightUnit ? ( state.netWeightUnit.length == 0 ? netWeightUnitsDefault[0]?.id : state.netWeightUnit) : (values.netWeightUnit ? values.netWeightUnit[0] : netWeightUnitsDefault[0]?.id));
    const [powerLossUnit, setPowerLossUnit] = useState(state.powerLossUnit ? ( state.powerLossUnit.length == 0 ? powerLossUnitsDefault[0]?.id : state.powerLossUnit) : (values.powerLossUnit ? values.powerLossUnit[0] : powerLossUnitsDefault[0]?.id));
    const [typicalDrivePowerRatingUnit, setTypicalDrivePowerRatingUnit] = useState(state.typicalDrivePowerRatingUnit ? ( state.typicalDrivePowerRatingUnit.length == 0 ? typicalDrivePowerRatingUnitsDefault[0]?.id : state.typicalDrivePowerRatingUnit) : (values.typicalDrivePowerRatingUnit ? values.typicalDrivePowerRatingUnit[0] : typicalDrivePowerRatingUnitsDefault[0]?.id));


    //------------------------------- Public methods -------------------------------------



    return (
        <React.Fragment>

            <Grid container spacing={6} >
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="length"
                        label="Length"
                        value={values.length ? values.length : ''}
                        error={Boolean(touched.length && errors.length)}
                        fullWidth
                        helperText={touched.length && errors.length}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Length Unit</InputLabel>
                        <Select
                            name="lengthUnit"
                            value={lengthUnit}
                            label="Length Unit"
                            onChange={(event: any) => {
                                setLengthUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {lengthUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6} >
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="width"
                        label="Width"
                        value={values.width ? values.width : ''}
                        error={Boolean(touched.width && errors.width)}
                        fullWidth
                        helperText={touched.width && errors.width}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Width Unit</InputLabel>
                        <Select
                            name="widthUnit"
                            value={widthUnit}
                            label="Width Unit"
                            onChange={(event: any) => {
                                setWidthUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {widthUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6} >
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="height"
                        label="Height"
                        value={values.height ? values.height : ''}
                        error={Boolean(touched.height && errors.height)}
                        fullWidth
                        helperText={touched.height && errors.height}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Height Unit</InputLabel>
                        <Select
                            name="heightUnit"
                            value={heightUnit}
                            label="Height Unit"
                            onChange={(event: any) => {
                                setHeightUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {heightUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="volume"
                        label="Volume"
                        value={values.volume ? values.volume : ''}
                        error={Boolean(touched.volume && errors.volume)}
                        fullWidth
                        helperText={touched.volume && errors.volume}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Volume Unit</InputLabel>
                        <Select
                            name="volumeUnit"
                            value={volumeUnit}
                            label="Volume Unit"
                            onChange={(event: any) => {
                                setVolumeUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {volumeUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="netWeight"
                        label="NetWeight"
                        value={values.netWeight ? values.netWeight : ''}
                        error={Boolean(touched.netWeight && errors.netWeight)}
                        fullWidth
                        helperText={touched.netWeight && errors.netWeight}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>NetWeight Unit</InputLabel>
                        <Select
                            name="netWeightUnit"
                            value={netWeightUnit}
                            label="NetWeight Unit"
                            onChange={(event: any) => {
                                setNetWeightUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {netWeightUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="powerLoss"
                        label="Power Loss"
                        value={values.powerLoss ? values.powerLoss : ''}
                        error={Boolean(touched.powerLoss && errors.powerLoss)}
                        fullWidth
                        helperText={touched.powerLoss && errors.powerLoss}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Power Loss Unit</InputLabel>
                        <Select
                            name="powerLossUnit"
                            value={powerLossUnit}
                            label="Power Loss Unit"
                            onChange={(event: any) => {
                                setPowerLossUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {powerLossUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="pin"
                        label="ø Pin"
                        value={values.pin ? values.pin : ''}
                        error={Boolean(touched.pin && errors.pin)}
                        fullWidth
                        helperText={touched.pin && errors.pin}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="size"
                        label="Size"
                        value={values.size ? values.size : ''}
                        error={Boolean(touched.size && errors.size)}
                        fullWidth
                        helperText={touched.size && errors.size}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="frameSize"
                        label="Frame size"
                        value={values.frameSize ? values.frameSize : ''}
                        error={Boolean(touched.frameSize && errors.frameSize)}
                        fullWidth
                        helperText={touched.frameSize && errors.frameSize}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="typicalDrivePowerRating"
                        label="Typical drive power rating"
                        value={values.typicalDrivePowerRating ? values.typicalDrivePowerRating : ''}
                        error={Boolean(touched.typicalDrivePowerRating && errors.typicalDrivePowerRating)}
                        fullWidth
                        helperText={touched.typicalDrivePowerRating && errors.typicalDrivePowerRating}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Typical drive power rating Unit</InputLabel>
                        <Select
                            name="typicalDrivePowerRatingUnit"
                            value={typicalDrivePowerRatingUnit}
                            label="Typical drive power rating Unit"
                            onChange={(event: any) => {
                                setTypicalDrivePowerRatingUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {typicalDrivePowerRatingUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Config Sizes
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesA"
                        label="Sizes [mm] A"
                        value={values.sizesA ? values.sizesA : ''}
                        error={Boolean(touched.sizesA && errors.sizesA)}
                        fullWidth
                        helperText={touched.sizesA && errors.sizesA}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesB"
                        label="Sizes [mm] B"
                        value={values.sizesB ? values.sizesB : ''}
                        error={Boolean(touched.sizesB && errors.sizesB)}
                        fullWidth
                        helperText={touched.sizesB && errors.sizesB}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesC"
                        label="Sizes [mm] C"
                        value={values.sizesC ? values.sizesC : ''}
                        error={Boolean(touched.sizesC && errors.sizesC)}
                        fullWidth
                        helperText={touched.sizesC && errors.sizesC}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesD"
                        label="Sizes [mm] D"
                        value={values.sizesD ? values.sizesD : ''}
                        error={Boolean(touched.sizesD && errors.sizesD)}
                        fullWidth
                        helperText={touched.sizesD && errors.sizesD}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesE"
                        label="Sizes [mm] E"
                        value={values.sizesE ? values.sizesE : ''}
                        error={Boolean(touched.sizesE && errors.sizesE)}
                        fullWidth
                        helperText={touched.sizesE && errors.sizesE}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesF"
                        label="Sizes [mm] F"
                        value={values.sizesF ? values.sizesF : ''}
                        error={Boolean(touched.sizesF && errors.sizesF)}
                        fullWidth
                        helperText={touched.sizesF && errors.sizesF}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesG"
                        label="Sizes [mm] G"
                        value={values.sizesG ? values.sizesG : ''}
                        error={Boolean(touched.sizesG && errors.sizesG)}
                        fullWidth
                        helperText={touched.sizesG && errors.sizesG}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesH"
                        label="Sizes [mm] H"
                        value={values.sizesH ? values.sizesH : ''}
                        error={Boolean(touched.sizesH && errors.sizesH)}
                        fullWidth
                        helperText={touched.sizesH && errors.sizesH}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesI"
                        label="Sizes [mm] I"
                        value={values.sizesI ? values.sizesI : ''}
                        error={Boolean(touched.sizesI && errors.sizesI)}
                        fullWidth
                        helperText={touched.sizesI && errors.sizesI}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesJ"
                        label="Sizes [mm] J"
                        value={values.sizesJ ? values.sizesJ : ''}
                        error={Boolean(touched.sizesJ && errors.sizesJ)}
                        fullWidth
                        helperText={touched.sizesJ && errors.sizesJ}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesK"
                        label="Sizes [mm] K"
                        value={values.sizesK ? values.sizesK : ''}
                        error={Boolean(touched.sizesK && errors.sizesK)}
                        fullWidth
                        helperText={touched.sizesK && errors.sizesK}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesL"
                        label="Sizes [mm] L"
                        value={values.sizesL ? values.sizesL : ''}
                        error={Boolean(touched.sizesL && errors.sizesL)}
                        fullWidth
                        helperText={touched.sizesL && errors.sizesL}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesM"
                        label="Sizes [mm] M"
                        value={values.sizesM ? values.sizesM : ''}
                        error={Boolean(touched.sizesM && errors.sizesM)}
                        fullWidth
                        helperText={touched.sizesM && errors.sizesM}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesN"
                        label="Sizes [mm] N"
                        value={values.sizesN ? values.sizesN : ''}
                        error={Boolean(touched.sizesN && errors.sizesN)}
                        fullWidth
                        helperText={touched.sizesN && errors.sizesN}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesO"
                        label="Sizes [mm] O"
                        value={values.sizesO ? values.sizesO : ''}
                        error={Boolean(touched.sizesO && errors.sizesO)}
                        fullWidth
                        helperText={touched.sizesO && errors.sizesO}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesP"
                        label="Sizes [mm] P"
                        value={values.sizesP ? values.sizesP : ''}
                        error={Boolean(touched.sizesP && errors.sizesP)}
                        fullWidth
                        helperText={touched.sizesP && errors.sizesP}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesQ"
                        label="Sizes [mm] Q"
                        value={values.sizesQ ? values.sizesQ : ''}
                        error={Boolean(touched.sizesQ && errors.sizesQ)}
                        fullWidth
                        helperText={touched.sizesQ && errors.sizesQ}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesR"
                        label="Sizes [mm] R"
                        value={values.sizesR ? values.sizesR : ''}
                        error={Boolean(touched.sizesR && errors.sizesR)}
                        fullWidth
                        helperText={touched.sizesR && errors.sizesR}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesS"
                        label="Sizes [mm] S"
                        value={values.sizesS ? values.sizesS : ''}
                        error={Boolean(touched.sizesS && errors.sizesS)}
                        fullWidth
                        helperText={touched.sizesS && errors.sizesS}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesT"
                        label="Sizes [mm] T"
                        value={values.sizesT ? values.sizesT : ''}
                        error={Boolean(touched.sizesT && errors.sizesT)}
                        fullWidth
                        helperText={touched.sizesT && errors.sizesT}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesU"
                        label="Sizes [mm] U"
                        value={values.sizesU ? values.sizesU : ''}
                        error={Boolean(touched.sizesU && errors.sizesU)}
                        fullWidth
                        helperText={touched.sizesU && errors.sizesU}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesV"
                        label="Sizes [mm] V"
                        value={values.sizesV ? values.sizesV : ''}
                        error={Boolean(touched.sizesV && errors.sizesV)}
                        fullWidth
                        helperText={touched.sizesV && errors.sizesV}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesW"
                        label="Sizes [mm] W"
                        value={values.sizesW ? values.sizesW : ''}
                        error={Boolean(touched.sizesW && errors.sizesW)}
                        fullWidth
                        helperText={touched.sizesW && errors.sizesW}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesX"
                        label="Sizes [mm] X"
                        value={values.sizesX ? values.sizesX : ''}
                        error={Boolean(touched.sizesX && errors.sizesX)}
                        fullWidth
                        helperText={touched.sizesX && errors.sizesX}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesY"
                        label="Sizes [mm] Y"
                        value={values.sizesY ? values.sizesY : ''}
                        error={Boolean(touched.sizesY && errors.sizesY)}
                        fullWidth
                        helperText={touched.sizesY && errors.sizesY}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="sizesZ"
                        label="Sizes [mm] Z"
                        value={values.sizesZ ? values.sizesZ : ''}
                        error={Boolean(touched.sizesZ && errors.sizesZ)}
                        fullWidth
                        helperText={touched.sizesZ && errors.sizesZ}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>

        </React.Fragment>
    );
}


export default MechanicArticle;
