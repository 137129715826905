import React from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const GeneralInfoApproval: React.FC<FieldsGroupType> = (props) => {
    const {values, touched, errors, onChange} = props;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="title"
                        label="Title *"
                        value={values.title ? values.title : ''}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="sorting"
                        label="Sorting"
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        value={values.sorting ? values.sorting : ''}
                        error={Boolean(touched.sorting && errors.sorting)}
                        fullWidth
                        helperText={touched.sorting && errors.sorting}
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>

                <Grid item xs={12} md={8} mb={5}>
                    <TextField
                        name="link"
                        label="Link"
                        value={values.link ? values.link : ''}
                        error={Boolean(touched.link && errors.link)}
                        fullWidth
                        helperText={touched.link && errors.link}
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoApproval;
