import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    InputLabel,
} from "@mui/material";

import ReactQuill from "react-quill";

// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";


// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {useParams} from "react-router-dom";



const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {min-height: 200px;}`;


const GeneralInfoCategory: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const params = useParams();
    const lang = params.lang ? params.lang : '';

    // States
    const [descriptionDe, setDescriptionDe] = useState(state.descriptionDe ? state.descriptionDe : (values.descriptionDe ? values.descriptionDe : ''));
    const [descriptionCn, setDescriptionCn] = useState(state.descriptionCn ? state.descriptionCn : (values.descriptionCn ? values.descriptionCn : ''));

    //------------------------------- Public methods -------------------------------------

    // Update description on change

    const handleChangeDescriptionDe = (event: any) => {
        event.toString('html');
        setDescriptionDe(event);
    };
    const handleChangeDescriptionCn = (event: any) => {
        event.toString('html');
        setDescriptionCn(event);
    };


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations {lang}
            </Typography>

                {lang == 'de' &&
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="titleDe"
                                label="Title De"
                                value={values.titleDe ? values.titleDe : ''}
                                error={Boolean(touched.titleDe && errors.titleDe)}
                                fullWidth
                                helperText={touched.titleDe && errors.titleDe}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="title"
                                label="Title"
                                value={values.title ? values.title : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="subtitleDe"
                                label="Subtitle De"
                                value={values.subtitleDe ? values.subtitleDe : ''}
                                error={Boolean(touched.subtitleDe && errors.subtitleDe)}
                                fullWidth
                                helperText={touched.subtitleDe && errors.subtitleDe}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="subtitle"
                                label="Subtitle"
                                value={values.subtitle ? values.subtitle : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="headerDe"
                                label="Intro title De"
                                value={values.headerDe ? values.headerDe : ''}
                                error={Boolean(touched.headerDe && errors.headerDe)}
                                fullWidth
                                helperText={touched.headerDe && errors.headerDe}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="header"
                                label="Intro title"
                                value={values.header ? values.header : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="subheaderDe"
                                label="Intro subtitle De"
                                value={values.subheaderDe ? values.subheaderDe : ''}
                                error={Boolean(touched.subheaderDe && errors.subheaderDe)}
                                fullWidth
                                helperText={touched.subheaderDe && errors.subheaderDe}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="subheader"
                                label="Intro subtitle"
                                value={values.subheader ? values.subheader : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Short description De"
                                name="shortDescriptionDe"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.shortDescriptionDe ? values.shortDescriptionDe : ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Short description"
                                name="shortDescription"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.shortDescription ? values.shortDescription : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <QuillWrapper className="full-width">
                                <InputLabel className="mb-5">Description De</InputLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={descriptionDe}
                                    onChange={(e) => {
                                        handleChangeDescriptionDe(e);
                                        onChange(e, 'descriptionDe')
                                    }}
                                />
                            </QuillWrapper>
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <QuillWrapper className="full-width read-only">
                                <InputLabel className="mb-5">Description</InputLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={values.description}
                                    readOnly={true}
                                />
                            </QuillWrapper>
                        </Grid>
                    </Grid>
                }
                {lang == 'cn' &&
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="titleCn"
                                label="Title Cn"
                                value={values.titleCn ? values.titleCn : ''}
                                error={Boolean(touched.titleCn && errors.titleCn)}
                                fullWidth
                                helperText={touched.titleCn && errors.titleCn}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="title"
                                label="Title"
                                value={values.title ? values.title : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="subtitleCn"
                                label="Subtitle Cn"
                                value={values.subtitleCn ? values.subtitleCn : ''}
                                error={Boolean(touched.subtitleCn && errors.subtitleCn)}
                                fullWidth
                                helperText={touched.subtitleCn && errors.subtitleCn}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="subtitle"
                                label="Subtitle"
                                value={values.subtitle ? values.subtitle : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="headerCn"
                                label="Intro title Cn"
                                value={values.headerCn ? values.headerCn : ''}
                                error={Boolean(touched.headerCn && errors.headerCn)}
                                fullWidth
                                helperText={touched.headerCn && errors.headerCn}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="header"
                                label="Intro title"
                                value={values.header ? values.header : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="subheaderCn"
                                label="Intro subtitle Cn"
                                value={values.subheaderCn ? values.subheaderCn : ''}
                                error={Boolean(touched.subheaderCn && errors.subheaderCn)}
                                fullWidth
                                helperText={touched.subheaderCn && errors.subheaderCn}
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="subheader"
                                label="Intro subtitle"
                                value={values.subheader ? values.subheader : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Short description Cn"
                                name="shortDescriptionCn"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.shortDescriptionCn ? values.shortDescriptionCn : ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Short description"
                                name="shortDescription"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.shortDescription ? values.shortDescription : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <QuillWrapper className="full-width">
                                <InputLabel className="mb-5">Description Cn</InputLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={descriptionCn}
                                    onChange={(e) => {
                                        handleChangeDescriptionCn(e);
                                        onChange(e, 'descriptionCn')
                                    }}
                                />
                            </QuillWrapper>
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <QuillWrapper className="full-width read-only">
                                <InputLabel className="mb-5">Description</InputLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={values.description}
                                    readOnly={true}
                                />
                            </QuillWrapper>
                        </Grid>
                    </Grid>
                }

        </React.Fragment>
    );
}


export default GeneralInfoCategory;
