import React, {createRef, RefObject, useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button
} from "@mui/material";
import ListViewTable from "../components/ListViewTable";
import {ArticlesContext} from "./store/articles-context";

// Types
import {HeadCell} from "../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {
    Add as AddIcon, FileUpload, FileDownload, BrowserUpdated, PictureAsPdf
} from "@mui/icons-material";
import SearchBar from "../components/SearchBar";
import {Plus} from "react-feather";


const Input = styled('input')({display: 'none',});
const Divider = styled(MuiDivider)(spacing);


function Articles() {
    const articlesCtx = useContext(ArticlesContext);
    const fileInput: RefObject<HTMLInputElement> = createRef();

    // Set table headers data
    const headCells: Array<HeadCell> = articlesCtx.headers;
    const model = 'articles';

    //------------------------------- Public methods -------------------------------------

    return (
        <React.Fragment>
            <Helmet title="Articles"/>
            <Grid item xs={12} mb={10}>
                <SearchBar text={articlesCtx.search} onSearch={articlesCtx.onSearch}/>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        Articles
                    </Typography>
                </Grid>
                <Grid item>
                    <div>
                        {articlesCtx.selected && articlesCtx.selected?.length > 0 &&
                            <Button variant="contained" color="primary" onClick={articlesCtx.generatePdf}
                                    startIcon={<PictureAsPdf/>} className="margin-right-btn">
                                Generate Pdf
                            </Button>
                        }
                        {articlesCtx.selected && articlesCtx.selected?.length > 0 &&
                            <Button variant="contained" color="primary" onClick={articlesCtx.publish}
                                    startIcon={<BrowserUpdated/>} className="margin-right-btn">
                                Publish
                            </Button>
                        }
                        <Button variant="contained" color="primary" onClick={articlesCtx.exportCsv}
                                startIcon={<FileDownload/>} className="margin-right-btn">
                            CSV Export Articles
                        </Button>
                        <Input
                            ref={fileInput}
                            accept=".csv,.xlsx,.xls"
                            type="file"
                            name="file"
                            onChange={(event: any) => {
                                if (articlesCtx.importCsv) {
                                    articlesCtx.importCsv(event.target.files)
                                }
                            }}
                        />
                        <Button variant="contained" color="primary" onClick={(e: any) => {
                            if (fileInput.current) {
                                fileInput.current.click();
                            }
                        }} startIcon={<FileUpload/>} className="margin-right-btn">
                            CSV Import Articles
                        </Button>
                        <Button variant="contained" color="primary" onClick={articlesCtx.addItem}
                                startIcon={<AddIcon/>}>
                            New Article
                        </Button>
                    </div>
                </Grid>
            </Grid>

            {articlesCtx.filters
                ? <Divider my={6}/>
                : <Divider my={6} mb={10}/>
            }

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={articlesCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={articlesCtx.removeItem}
                    onSelectHandler={articlesCtx.selectItem}
                    onPaginateHandler={articlesCtx.onPaginate}
                    onSortHandler={articlesCtx.onSort}
                    onFilterHandler={articlesCtx.onFilter}
                    count={articlesCtx.count}
                    page={articlesCtx.page}
                    rowsPerPage={articlesCtx.rowsPerPage}
                    sorting={articlesCtx.sorting}
                    filters={articlesCtx.filters}
                    filter={articlesCtx.filter}
                    onMultiEditHandler={articlesCtx.multiSelected}
                    selectedArray={articlesCtx.selected}
                    model={model}
                />
            </Grid>
        </React.Fragment>
    );
}

export default Articles;
