import React from "react";

import {
    Grid,
    TextField as MuiTextField,

} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";



const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const SeoCategory: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange} = props;



    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Seo Title"
                        name="seoTitle"
                        value={values.seoTitle ? values.seoTitle : ''}
                        error={Boolean(touched.seoTitle && errors.seoTitle)}
                        fullWidth
                        helperText={touched.seoTitle && errors.seoTitle}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={6} />
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Seo Description"
                        name="seoDescription"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.seoDescription ? values.seoDescription : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        label="Seo Keywords"
                        name="seoKeywords"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.seoKeywords ? values.seoKeywords : ''}
                        onChange={onChange}
                    />
                </Grid>


            </Grid>
        </React.Fragment>
    );
}


export default SeoCategory;
