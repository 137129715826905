import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    InputLabel, FormControl, Select, MenuItem, Divider as MuiDivider,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";

const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const AttenuationArticle: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const cmAttenuationUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'cmAttenuationUnit');
    const dmAttenuationUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'dmAttenuationUnit');
    const inductanceL1Units: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'inductanceL1Unit');
    const inductanceL2Units: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'inductanceL2Unit');
    const capacitanceCx1Units: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'capacitanceCx1Unit');
    const capacitanceCx2Units: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'capacitanceCx2Unit');
    const capacitanceCy1Units: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'capacitanceCy1Unit');
    const capacitanceCy2Units: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'capacitanceCy2Unit');

    const cmAttenuationUnitsDefault: { id: string }[] = cmAttenuationUnits.filter((item: any) => item.default);
    const dmAttenuationUnitsDefault: { id: string }[] = dmAttenuationUnits.filter((item: any) => item.default);
    const inductanceL1UnitsDefault: { id: string }[] = inductanceL1Units.filter((item: any) => item.default);
    const inductanceL2UnitsDefault: { id: string }[] = inductanceL2Units.filter((item: any) => item.default);
    const capacitanceCx1UnitsDefault: { id: string }[] = capacitanceCx1Units.filter((item: any) => item.default);
    const capacitanceCx2UnitsDefault: { id: string }[] = capacitanceCx2Units.filter((item: any) => item.default);
    const capacitanceCy1UnitsDefault: { id: string }[] = capacitanceCy1Units.filter((item: any) => item.default);
    const capacitanceCy2UnitsDefault: { id: string }[] = capacitanceCy2Units.filter((item: any) => item.default);

    // States
    const [cmAttenuationUnit, setCmAttenuationUnit] = useState(state.cmAttenuationUnit ? ( state.cmAttenuationUnit.length == 0 ? cmAttenuationUnitsDefault[0]?.id : state.cmAttenuationUnit) : (values.cmAttenuationUnit ? values.cmAttenuationUnit[0] : cmAttenuationUnitsDefault[0]?.id));
    const [dmAttenuationUnit, setDmAttenuationUnit] = useState(state.dmAttenuationUnit ? ( state.dmAttenuationUnit.length == 0 ? dmAttenuationUnitsDefault[0]?.id : state.dmAttenuationUnit) : (values.dmAttenuationUnit ? values.dmAttenuationUnit[0] : dmAttenuationUnitsDefault[0]?.id));
    const [inductanceL1Unit, setInductanceL1Unit] = useState(state.inductanceL1Unit ? ( state.inductanceL1Unit.length == 0 ? inductanceL1UnitsDefault[0]?.id : state.inductanceL1Unit) : (values.inductanceL1Unit ? values.inductanceL1Unit[0] : inductanceL1UnitsDefault[0]?.id));
    const [inductanceL2Unit, setInductanceL2Unit] = useState(state.inductanceL2Unit ? ( state.inductanceL2Unit.length == 0 ? inductanceL2UnitsDefault[0]?.id : state.inductanceL2Unit) : (values.inductanceL2Unit ? values.inductanceL2Unit[0] : inductanceL2UnitsDefault[0]?.id));
    const [capacitanceCx1Unit, setCapacitanceCx1Unit] = useState(state.capacitanceCx1Unit ? ( state.capacitanceCx1Unit.length == 0 ? capacitanceCx1UnitsDefault[0]?.id : state.capacitanceCx1Unit) : (values.capacitanceCx1Unit ? values.capacitanceCx1Unit[0] : capacitanceCx1UnitsDefault[0]?.id));
    const [capacitanceCx2Unit, setCapacitanceCx2Unit] = useState(state.capacitanceCx2Unit ? ( state.capacitanceCx2Unit.length == 0 ? capacitanceCx2UnitsDefault[0]?.id : state.capacitanceCx2Unit) : (values.capacitanceCx2Unit ? values.capacitanceCx2Unit[0] : capacitanceCx2UnitsDefault[0]?.id));
    const [capacitanceCy1Unit, setCapacitanceCy1Unit] = useState(state.capacitanceCy1Unit ? ( state.capacitanceCy1Unit.length == 0 ? capacitanceCy1UnitsDefault[0]?.id : state.capacitanceCy1Unit) : (values.capacitanceCy1Unit ? values.capacitanceCy1Unit[0] : capacitanceCy1UnitsDefault[0]?.id));
    const [capacitanceCy2Unit, setCapacitanceCy2Unit] = useState(state.capacitanceCy2Unit ? ( state.capacitanceCy2Unit.length == 0 ? capacitanceCy2UnitsDefault[0]?.id : state.capacitanceCy2Unit) : (values.capacitanceCy2Unit ? values.capacitanceCy2Unit[0] : capacitanceCy2UnitsDefault[0]?.id));


    //------------------------------- Public methods -------------------------------------

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Config attenuation
            </Typography>

            <Grid container spacing={6} >
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="cmAttenuation"
                        label="CM attenuation @ 150kHz"
                        value={values.cmAttenuation ? values.cmAttenuation : ''}
                        error={Boolean(touched.cmAttenuation && errors.cmAttenuation)}
                        fullWidth
                        helperText={touched.cmAttenuation && errors.cmAttenuation}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>CM attenuation Unit</InputLabel>
                        <Select
                            name="cmAttenuationUnit"
                            value={cmAttenuationUnit}
                            label="CM attenuation Unit"
                            onChange={(event: any) => {
                                setCmAttenuationUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {cmAttenuationUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6} >
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="dmAttenuation"
                        label="DM attenuation @ 150kHz"
                        value={values.dmAttenuation ? values.dmAttenuation : ''}
                        error={Boolean(touched.dmAttenuation && errors.dmAttenuation)}
                        fullWidth
                        helperText={touched.dmAttenuation && errors.dmAttenuation}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>DM attenuation Unit</InputLabel>
                        <Select
                            name="dmAttenuationUnit"
                            value={dmAttenuationUnit}
                            label="Unit DM attenuation"
                            onChange={(event: any) => {
                                setDmAttenuationUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {dmAttenuationUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Config inductance
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="inductanceL1"
                        label="Inductance L1"
                        value={values.inductanceL1 ? values.inductanceL1 : ''}
                        error={Boolean(touched.inductanceL1 && errors.inductanceL1)}
                        fullWidth
                        helperText={touched.inductanceL1 && errors.inductanceL1}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Inductance L1 Unit</InputLabel>
                        <Select
                            name="inductanceL1Unit"
                            value={inductanceL1Unit}
                            label="Inductance L1 Unit"
                            onChange={(event: any) => {
                                setInductanceL1Unit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {inductanceL1Units.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="inductanceL2"
                        label="Inductance L2"
                        value={values.inductanceL2 ? values.inductanceL2 : ''}
                        error={Boolean(touched.inductanceL2 && errors.inductanceL2)}
                        fullWidth
                        helperText={touched.inductanceL2 && errors.inductanceL2}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Inductance L2 Unit</InputLabel>
                        <Select
                            name="inductanceL2Unit"
                            value={inductanceL2Unit}
                            label="Inductance L2 Unit"
                            onChange={(event: any) => {
                                setInductanceL2Unit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {inductanceL2Units.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Config capacitance
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="capacitanceCx1"
                        label="Capacitance Cx1"
                        value={values.capacitanceCx1 ? values.capacitanceCx1 : ''}
                        error={Boolean(touched.capacitanceCx1 && errors.capacitanceCx1)}
                        fullWidth
                        helperText={touched.capacitanceCx1 && errors.capacitanceCx1}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Capacitance Cx1 Unit</InputLabel>
                        <Select
                            name="capacitanceCx1Unit"
                            value={capacitanceCx1Unit}
                            label="Capacitance Cx1 Unit"
                            onChange={(event: any) => {
                                setCapacitanceCx1Unit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {capacitanceCx1Units.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="capacitanceCx2"
                        label="Capacitance Cx2"
                        value={values.capacitanceCx2 ? values.capacitanceCx2 : ''}
                        error={Boolean(touched.capacitanceCx2 && errors.capacitanceCx2)}
                        fullWidth
                        helperText={touched.capacitanceCx2 && errors.capacitanceCx2}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Capacitance Cx2 Unit</InputLabel>
                        <Select
                            name="capacitanceCx2Unit"
                            value={capacitanceCx2Unit}
                            label="Capacitance Cx2 Unit"
                            onChange={(event: any) => {
                                setCapacitanceCx2Unit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {capacitanceCx2Units.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="capacitanceCy1"
                        label="Capacitance Cy1"
                        value={values.capacitanceCy1 ? values.capacitanceCy1 : ''}
                        error={Boolean(touched.capacitanceCy1 && errors.capacitanceCy1)}
                        fullWidth
                        helperText={touched.capacitanceCy1 && errors.capacitanceCy1}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Capacitance Cy1 Unit</InputLabel>
                        <Select
                            name="capacitanceCy1Unit"
                            value={capacitanceCy1Unit}
                            label="Capacitance Cy1 Unit"
                            onChange={(event: any) => {
                                setCapacitanceCy1Unit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {capacitanceCy1Units.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="capacitanceCy2"
                        label="Capacitance Cy2"
                        value={values.capacitanceCy2 ? values.capacitanceCy2 : ''}
                        error={Boolean(touched.capacitanceCy2 && errors.capacitanceCy2)}
                        fullWidth
                        helperText={touched.capacitanceCy2 && errors.capacitanceCy2}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Capacitance Cy2 Unit</InputLabel>
                        <Select
                            name="capacitanceCy2Unit"
                            value={capacitanceCy2Unit}
                            label="Capacitance Cy2 Unit"
                            onChange={(event: any) => {
                                setCapacitanceCy2Unit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {capacitanceCy2Units.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>



        </React.Fragment>
    );
}


export default AttenuationArticle;
