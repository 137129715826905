import React, {useState} from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Divider as MuiDivider, InputLabel,
} from "@mui/material";
import ReactQuill from "react-quill";

// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";


// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {useParams} from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {min-height: 200px;}`;

const AdditionalProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const params = useParams();
    const lang = params.lang ? params.lang : '';

    // States
    const [descriptionAdditionalDe, setDescriptionAdditionalDe] = useState(state.descriptionAdditionalDe ? state.descriptionAdditionalDe : (values.descriptionAdditionalDe ? values.descriptionAdditionalDe : ''));
    const [descriptionAdditionalCn, setDescriptionAdditionalCn] = useState(state.descriptionAdditionalCn ? state.descriptionAdditionalCn : (values.descriptionAdditionalCn ? values.descriptionAdditionalCn : ''));
    const [qrCodeDescriptionDe, setQrCodeDescriptionDe] = useState(state.qrCodeDescriptionDe ? state.qrCodeDescriptionDe : (values.qrCodeDescriptionDe ? values.qrCodeDescriptionDe : ''));
    const [qrCodeDescriptionCn, setQrCodeDescriptionCn] = useState(state.qrCodeDescriptionCn ? state.qrCodeDescriptionCn : (values.qrCodeDescriptionCn ? values.qrCodeDescriptionCn : ''));


    //------------------------------- Public methods -------------------------------------

    // Update description on change
    const handleChangeDescriptionAdditionalDe = (event: any) => {
        event.toString('html');
        setDescriptionAdditionalDe(event);
    };
    const handleChangeDescriptionAdditionalCn = (event: any) => {
        event.toString('html');
        setDescriptionAdditionalCn(event);
    };
    const handleChangeQrCodeDescriptionDe = (event: any) => {
        event.toString('html');
        setQrCodeDescriptionDe(event);
    };
    const handleChangeQrCodeDescriptionCn = (event: any) => {
        event.toString('html');
        setQrCodeDescriptionCn(event);
    };


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Approvals
            </Typography>

            {lang == 'de' &&
                <React.Fragment>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Approvals & Compliances note De"
                                name="approvalsNoteDe"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.approvalsNoteDe ? values.approvalsNoteDe : ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Approvals & Compliances note"
                                name="approvalsNote"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.approvalsNote ? values.approvalsNote : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider mb={10}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <QuillWrapper className="full-width">
                                <InputLabel className="mb-5">Power quality(new for AHF SYNC) De</InputLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={descriptionAdditionalDe}
                                    onChange={(e) => {
                                        handleChangeDescriptionAdditionalDe(e);
                                        onChange(e, 'descriptionAdditionalDe')
                                    }}
                                />
                            </QuillWrapper>
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <QuillWrapper className="full-width read-only">
                                <InputLabel className="mb-5">Power quality(new for AHF SYNC)</InputLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={values.descriptionAdditional}
                                    readOnly={true}
                                />
                            </QuillWrapper>
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <QuillWrapper className="full-width">
                                <InputLabel className="mb-5">QR-Code Description De</InputLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={qrCodeDescriptionDe}
                                    onChange={(e) => {
                                        handleChangeQrCodeDescriptionDe(e);
                                        onChange(e, 'qrCodeDescriptionDe')
                                    }}
                                />
                            </QuillWrapper>
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <QuillWrapper className="full-width read-only">
                                <InputLabel className="mb-5">QR-Code Description</InputLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={values.qrCodeDescription}
                                    readOnly={true}
                                />
                            </QuillWrapper>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            {lang == 'cn' &&
                <React.Fragment>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Approvals & Compliances note Cn"
                                name="approvalsNoteCn"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.approvalsNoteCn ? values.approvalsNoteCn : ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Approvals & Compliances note"
                                name="approvalsNote"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.approvalsNote ? values.approvalsNote : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider mb={10}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <QuillWrapper className="full-width">
                                <InputLabel className="mb-5">Power quality(new for AHF SYNC) Cn</InputLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={descriptionAdditionalCn}
                                    onChange={(e) => {
                                        handleChangeDescriptionAdditionalCn(e);
                                        onChange(e, 'descriptionAdditionalCn')
                                    }}
                                />
                            </QuillWrapper>
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <QuillWrapper className="full-width read-only">
                                <InputLabel className="mb-5">Power quality(new for AHF SYNC)</InputLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={values.descriptionAdditional}
                                    readOnly={true}
                                />
                            </QuillWrapper>
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <QuillWrapper className="full-width">
                                <InputLabel className="mb-5">QR-Code Description Cn</InputLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={qrCodeDescriptionCn}
                                    onChange={(e) => {
                                        handleChangeQrCodeDescriptionCn(e);
                                        onChange(e, 'qrCodeDescriptionCn')
                                    }}
                                />
                            </QuillWrapper>
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <QuillWrapper className="full-width read-only">
                                <InputLabel className="mb-5">QR-Code Description</InputLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={values.qrCodeDescription}
                                    readOnly={true}
                                />
                            </QuillWrapper>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }

        </React.Fragment>
    );
}

export default AdditionalProduct;
