import React from "react";

import {
    Grid,
    TextField as MuiTextField,

} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const GeneralInfoCategory: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange} = props;



    //------------------------------- Public methods -------------------------------------



    return (
        <React.Fragment>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="video"
                        label="Video Link"
                        value={values.video ? values.video : ''}
                        error={Boolean(touched.video && errors.video)}
                        fullWidth
                        helperText={touched.video && errors.video}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoCategory;
