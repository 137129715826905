import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    InputLabel,
    Checkbox,
    Autocomplete, FormGroup, FormControlLabel, FormControl, Select, MenuItem, Divider as MuiDivider,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const Divider = styled(MuiDivider)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const ElectricArticle: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const voltageAcUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'voltageAcUnit');
    const voltageDcUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'voltageDcUnit');
    const nominalFrequencyUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'nominalFrequencyUnit');
    const ratedCurrentUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'ratedCurrentUnit');
    const ambientTemperatureUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'ambientTemperatureUnit');
    const leakageCurrentIecUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'leakageCurrentIecUnit');
    const leakageCurrentSchUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'leakageCurrentSchUnit');
    const inputTerminals: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'inputTerminal');
    const inputTerminalUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'inputTerminalUnit');
    const outputTerminals: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'outputTerminal');
    const outputTerminalUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'outputTerminalUnit');
    const resistanceUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'resistanceUnit');

    const voltageAcUnitsDefault: { id: string }[] = voltageAcUnits.filter((item: any) => item.default);
    const voltageDcUnitsDefault: { id: string }[] = voltageDcUnits.filter((item: any) => item.default);
    const nominalFrequencyUnitsDefault: { id: string }[] = nominalFrequencyUnits.filter((item: any) => item.default);
    const ratedCurrentUnitsDefault: { id: string }[] = ratedCurrentUnits.filter((item: any) => item.default);
    const ambientTemperatureUnitsDefault: { id: string }[] = ambientTemperatureUnits.filter((item: any) => item.default);
    const leakageCurrentIecUnitsDefault: { id: string }[] = leakageCurrentIecUnits.filter((item: any) => item.default);
    const leakageCurrentSchUnitsDefault: { id: string }[] = leakageCurrentSchUnits.filter((item: any) => item.default);
    const inputTerminalUnitsDefault: { id: string }[] = inputTerminalUnits.filter((item: any) => item.default);
    const outputTerminalUnitsDefault: { id: string }[] = outputTerminalUnits.filter((item: any) => item.default);
    const resistanceUnitsDefault: { id: string }[] = resistanceUnits.filter((item: any) => item.default);

    // States
    const [voltageAcUnit, setVoltageAcUnit] = useState(state.voltageAcUnit ? ( state.voltageAcUnit.length == 0 ? voltageAcUnitsDefault[0]?.id : state.voltageAcUnit) : (values.voltageAcUnit ? values.voltageAcUnit[0] : voltageAcUnitsDefault[0]?.id));
    const [voltageDcUnit, setVoltageDcUnit] = useState(state.voltageDcUnit ? ( state.voltageDcUnit.length == 0 ? voltageDcUnitsDefault[0]?.id : state.voltageDcUnit) : (values.voltageDcUnit ? values.voltageDcUnit[0] : voltageDcUnitsDefault[0]?.id));
    const [nominalFrequencyUnit, setNominalFrequencyUnit] = useState(state.nominalFrequencyUnit ? (state.nominalFrequencyUnit.length == 0 ? nominalFrequencyUnitsDefault[0]?.id : state.nominalFrequencyUnit) : (values.nominalFrequencyUnit ? values.nominalFrequencyUnit[0] : nominalFrequencyUnitsDefault[0]?.id));
    const [ratedCurrentUnit, setRatedCurrentUnit] = useState(state.ratedCurrentUnit ? (state.ratedCurrentUnit.length == 0 ? ratedCurrentUnitsDefault[0]?.id : state.ratedCurrentUnit) : (values.ratedCurrentUnit ? values.ratedCurrentUnit[0] : ratedCurrentUnitsDefault[0]?.id));
    const [ambientTemperatureUnit, setAmbientTemperatureUnit] = useState(state.ambientTemperatureUnit ? (state.ambientTemperatureUnit.length == 0 ? ambientTemperatureUnitsDefault[0]?.id : state.ambientTemperatureUnit) : (values.ambientTemperatureUnit ? values.ambientTemperatureUnit[0] : ambientTemperatureUnitsDefault[0]?.id));
    const [leakageCurrentIecUnit, setLeakageCurrentIecUnit] = useState(state.leakageCurrentIecUnit ? (state.leakageCurrentIecUnit.length == 0 ? leakageCurrentIecUnitsDefault[0]?.id : state.leakageCurrentIecUnit) : (values.leakageCurrentIecUnit ? values.leakageCurrentIecUnit[0] : leakageCurrentIecUnitsDefault[0]?.id));
    const [leakageCurrentSchUnit, setLeakageCurrentSchUnit] = useState(state.leakageCurrentSchUnit ? (state.leakageCurrentSchUnit.length == 0 ? leakageCurrentSchUnitsDefault[0]?.id : state.leakageCurrentSchUnit) : (values.leakageCurrentSchUnit ? values.leakageCurrentSchUnit[0] : leakageCurrentSchUnitsDefault[0]?.id));
    const [inputTerminal, setInputTerminal] = useState<any[]>(state.inputTerminal ? state.inputTerminal  : (values.inputTerminal ? values.inputTerminal[0] : ''));
    const [inputTerminalUnit, setInputTerminalUnit] = useState(state.inputTerminalUnit ? (state.inputTerminalUnit.length == 0 ? inputTerminalUnitsDefault[0]?.id : state.inputTerminalUnit) : (values.inputTerminalUnit ? values.inputTerminalUnit[0] : inputTerminalUnitsDefault[0]?.id));
    const [outputTerminal, setOutputTerminal] = useState<any[]>(state.outputTerminal ? state.outputTerminal  : (values.outputTerminal ? values.outputTerminal[0] : ''));
    const [outputTerminalUnit, setOutputTerminalUnit] = useState(state.outputTerminalUnit ? (state.outputTerminalUnit.length == 0 ? outputTerminalUnitsDefault[0]?.id : state.outputTerminalUnit) : (values.outputTerminalUnit ? values.outputTerminalUnit[0] : outputTerminalUnitsDefault[0]?.id));
    const [resistanceUnit, setResistanceUnit] = useState(state.resistanceUnit ? (state.resistanceUnit.length == 0 ? resistanceUnitsDefault[0]?.id : state.resistanceUnit) : (values.resistanceUnit ? values.resistanceUnit[0] : resistanceUnitsDefault[0]?.id));

    //------------------------------- Public methods -------------------------------------

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Config voltage
            </Typography>

            <Grid container spacing={6} >
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="voltageAc"
                        label="Voltage AC"
                        value={values.voltageAc ? values.voltageAc : ''}
                        error={Boolean(touched.voltageAc && errors.voltageAc)}
                        fullWidth
                        helperText={touched.voltageAc && errors.voltageAc}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Voltage AC Unit</InputLabel>
                        <Select
                            name="voltageAcUnit"
                            value={voltageAcUnit}
                            label="Voltage AC Unit"
                            onChange={(event: any) => {
                                setVoltageAcUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {voltageAcUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6} >
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="voltageDc"
                        label="Voltage DC"
                        value={values.voltageDc ? values.voltageDc : ''}
                        error={Boolean(touched.voltageDc && errors.voltageDc)}
                        fullWidth
                        helperText={touched.voltageDc && errors.voltageDc}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Voltage DC Unit</InputLabel>
                        <Select
                            name="voltageDcUnit"
                            value={voltageDcUnit}
                            label="Voltage DC Unit"
                            onChange={(event: any) => {
                                setVoltageDcUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {voltageDcUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Config frequency
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="nominalFrequency"
                        label="Nominal Frequency"
                        value={values.nominalFrequency ? values.nominalFrequency : ''}
                        error={Boolean(touched.nominalFrequency && errors.nominalFrequency)}
                        fullWidth
                        helperText={touched.nominalFrequency && errors.nominalFrequency}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Nominal Frequency Unit</InputLabel>
                        <Select
                            name="nominalFrequencyUnit"
                            value={nominalFrequencyUnit}
                            label="Nominal Frequency Unit"
                            onChange={(event: any) => {
                                setNominalFrequencyUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {nominalFrequencyUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Config rated Current
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="ratedCurrent"
                        label="Rated Current @ambient"
                        value={values.ratedCurrent ? values.ratedCurrent : ''}
                        error={Boolean(touched.ratedCurrent && errors.ratedCurrent)}
                        fullWidth
                        helperText={touched.ratedCurrent && errors.ratedCurrent}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Rated Current Unit</InputLabel>
                        <Select
                            name="ratedCurrentUnit"
                            value={ratedCurrentUnit}
                            label="Rated Current Unit"
                            onChange={(event: any) => {
                                setRatedCurrentUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {ratedCurrentUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Config temperature
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="ambientTemperature"
                        label="Ambient temperature"
                        value={values.ambientTemperature ? values.ambientTemperature : ''}
                        error={Boolean(touched.ambientTemperature && errors.ambientTemperature)}
                        fullWidth
                        helperText={touched.ambientTemperature && errors.ambientTemperature}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Ambient temperature Unit</InputLabel>
                        <Select
                            name="ambientTemperatureUnit"
                            value={ambientTemperatureUnit}
                            label="Ambient temperature Unit"
                            onChange={(event: any) => {
                                setAmbientTemperatureUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {ambientTemperatureUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Config leakage
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="leakageCurrentIec"
                        label="Leakage current (IEC60939)"
                        value={values.leakageCurrentIec ? values.leakageCurrentIec : ''}
                        error={Boolean(touched.leakageCurrentIec && errors.leakageCurrentIec)}
                        fullWidth
                        helperText={touched.leakageCurrentIec && errors.leakageCurrentIec}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Leakage current (IEC60939) Unit</InputLabel>
                        <Select
                            name="leakageCurrentIecUnit"
                            value={leakageCurrentIecUnit}
                            label="Leakage current (IEC60939) Unit"
                            onChange={(event: any) => {
                                setLeakageCurrentIecUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {leakageCurrentIecUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="leakageCurrentSch"
                        label="Leakage current (Schaffner)"
                        value={values.leakageCurrentSch ? values.leakageCurrentSch : ''}
                        error={Boolean(touched.leakageCurrentSch && errors.leakageCurrentSch)}
                        fullWidth
                        helperText={touched.leakageCurrentSch && errors.leakageCurrentSch}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Leakage current (Schaffner) Unit</InputLabel>
                        <Select
                            name="leakageCurrentSchUnit"
                            value={leakageCurrentSchUnit}
                            label="Leakage current (Schaffner) Unit"
                            onChange={(event: any) => {
                                setLeakageCurrentSchUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {leakageCurrentSchUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Config terminal
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Input terminal</InputLabel>
                        <Select
                            name="inputTerminal"
                            value={inputTerminal}
                            label="Input terminal"
                            onChange={(event: any) => {
                                setInputTerminal(event.target.value);
                                onChange(event)
                            }}
                        >
                            {inputTerminals.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Input terminal Unit</InputLabel>
                        <Select
                            name="inputTerminalUnit"
                            value={inputTerminalUnit}
                            label="Input terminal Unit"
                            onChange={(event: any) => {
                                setInputTerminalUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {inputTerminalUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Output terminal</InputLabel>
                        <Select
                            name="outputTerminal"
                            value={outputTerminal}
                            label="Output terminal"
                            onChange={(event: any) => {
                                setOutputTerminal(event.target.value);
                                onChange(event)
                            }}
                        >
                            {outputTerminals.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Output terminal Unit</InputLabel>
                        <Select
                            name="outputTerminalUnit"
                            value={outputTerminalUnit}
                            label="Output terminal Unit"
                            onChange={(event: any) => {
                                setOutputTerminalUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {outputTerminalUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Config resistance
            </Typography>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="resistance"
                        label="Resistance"
                        value={values.resistance ? values.resistance : ''}
                        error={Boolean(touched.resistance && errors.resistance)}
                        fullWidth
                        helperText={touched.resistance && errors.resistance}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Resistance Unit</InputLabel>
                        <Select
                            name="resistanceUnit"
                            value={resistanceUnit}
                            label="Resistance Unit"
                            onChange={(event: any) => {
                                setResistanceUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {resistanceUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

        </React.Fragment>
    );
}


export default ElectricArticle;
