import React, {useState} from "react";

import {
    Grid,
    Typography,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Checkbox,
    ListItemText, Autocomplete, TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// Styles
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;


const CompanyProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const headquarters: { id: string, title: string }[] = data.selectCompanies;
    const selectedHeadquartersObj = state.headquarters ? state.headquarters.map((id: string) => headquarters.filter((item) => item.id === id)[0]) :  (values.headquarters ? values.headquarters.map((id: string) => headquarters.filter((item) => item.id === id)[0]) : []);
    const sales: { id: string, title: string }[] = data.selectCompanies;
    const selectedSalesObj =state.sales ? state.sales.map((id: string) => sales.filter((item) => item.id === id)[0]) :  (values.sales ? values.sales.map((id: string) => sales.filter((item) => item.id === id)[0]) : []);

    // States
    const [selectedHeadquarters, setSelectedHeadquarters] = useState<any[]>(selectedHeadquartersObj);
    const [selectedSales, setSelectedSales] = useState<any[]>(selectedSalesObj);


    //------------------------------- Public methods -------------------------------------

    // Update headquarters on change
    const handleHeadquartersChange = (value: any) => {
        setSelectedHeadquarters(value);
    };

    // Update sales on change
    const handleSalesChange = (value: any) => {
        setSelectedSales(value);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Company (Datasheet)
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <Autocomplete
                        multiple
                        options={headquarters}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedHeadquarters || []}
                        defaultValue={selectedHeadquarters || []}
                        onChange={(event: any, value: any) => {
                            handleHeadquartersChange(value);
                            onChange(event, 'headquarters', 'autocomplete', value.map((item: any) => item.id))
                        }
                        }
                        renderOption={(props, option, {selected}) => (
                            <li {...props} key={option.id}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Headquarters" placeholder="Headquarters" name="headquarters"/>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <Autocomplete
                        multiple
                        options={sales}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedSales || []}
                        defaultValue={selectedSales || []}
                        onChange={(event: any, value: any) => {
                            handleSalesChange(value);
                            onChange(event, 'sales', 'autocomplete', value.map((item: any) => item.id))
                        }
                        }
                        renderOption={(props, option, {selected}) => (
                            <li {...props} key={option.id}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Sales" placeholder="Sales" name="sales"/>
                        )}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default CompanyProduct;
