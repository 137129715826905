import React, {ChangeEvent, useState} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button,
    Stack,
    CardContent,
    Card,
    Alert as MuiAlert, Tabs, Tab, Chip, Avatar
} from "@mui/material";
import {Formik} from "formik";
import * as Yup from "yup";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../components/Loader";
import SnackbarNotification from "../../components/SnackbarNotification";
import TabPanel from "../components/TabPanel";
import GeneralInfoProduct from "./fieldGroups/translate/GeneralInfoProduct";
import AdditionalProduct from "./fieldGroups/translate/AdditionalProduct";
import PromotionProduct from "./fieldGroups/translate/PromotionProduct";
import ReferenceProduct from "./fieldGroups/translate/ReferenceProduct";
import InlineContentElementTranslate from "../../components/inline-content-element/InlineContentElementTranslate";
import InlineTechnicalSpecificationsTranslate from "./inline-technical-specifications/InlineTechnicalSpecificationsTramnslate";
import TechnicalSpecificationProduct from "./fieldGroups/translate/TechnicalSpecificationProduct";

// GraphQL
import {
    useUpdateProductMutation,
    useTranslateProductQuery, TranslateProductQuery
} from "../../graphql/product/product.graphql-gen";

// Types
import {TechnicalSpecificationItemType} from "../../types/inlineTechnicalSpecificationsType";
import {ContentElementItemType} from "../../types/inlineContentElementType";


// Icons
import {Save as SaveIcon} from "react-feather";
import {X as CloseIcon} from "react-feather";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import {shallowEqual} from "react-redux";
import england from "../../assets/img/england.svg";
import germany from "../../assets/img/germany.svg";
import china from "../../assets/img/china.svg";


const Alert = styled(MuiAlert)(spacing);
const Divider = styled(MuiDivider)(spacing);

const validationSchema = Yup.object().shape({
    //sku: Yup.string().required("Required"),
});

const ProductTranslate: React.FC = () => {
    let title: string = 'New product';
    const model: string = 'Product';
    let dataObj: any = {};
    const params = useParams();
    const id = params.id ? params.id : '';
    const navigate = useNavigate();

    // Queries
    const {data: data, error: error, loading: loading} = useTranslateProductQuery({
        variables: {id, model, language: params.lang ? params.lang : '', technicalSpecificationModel: 'TechnicalSpecification'},
        skip: id === '',
        onCompleted: (data: TranslateProductQuery) => {
            setContentElements(data.translateProduct.contentElements);
            setTechnicalSpecifications(data.translateProduct.technicalSpecifications);

            if (data.translateProduct && data.translateProduct.product) {
                setState(data.translateProduct.product);
                setInicialValue({
                    "data": data.translateProduct.product,
                    "contentElements": data.translateProduct.contentElements,
                    "technicalSpecifications": data.translateProduct.technicalSpecifications
                });
            }

        }
    });
    const [updateProduct] = useUpdateProductMutation();

    // States
    const [updated, setUpdated] = useState(false);

    const [contentElements, setContentElements] = useState<any>(dataObj.product ? dataObj.product.contentElements : []);
    const [technicalSpecifications, setTechnicalSpecifications] = useState<any>(dataObj.product ? dataObj.product.technicalSpecifications : []);

    const [removedMedia, setRemovedMedia] = useState<any[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [state, setState] = useState<any>();
    const [tabValue, setTabValue] = useState(0);

    const [checked, setChecked] = useState(dataObj.categoriesThree ? dataObj.categoriesThree.checked : []);
    const [open, setOpen] = useState<boolean>(false);
    const [inicialValue, setInicialValue] = useState<any>();
    const [lang, setLang] = useState<string>(params.lang ? params.lang : '')
    const [isLang, setIsLang] = useState<boolean>(false);

    // Set loading
    if (loading ||  loader) {
        return <Loader/>
    }

    // Handle error
    if (error ) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }

    // Set data
    if (data ) {
        dataObj = data
    }

    // Set title
    if (dataObj.translateProduct && dataObj.translateProduct.product) {
        title = dataObj.translateProduct.product.title;
    }


    //------------------------------- Public methods -------------------------------------

    // Handle save action
    const onSaveProduct = () => {
        if (id != '') {
            updateProductMutation(state);
        }
        setInicialValue({
            "data": state,
            "contentElements": contentElements,
            "technicalSpecifications": technicalSpecifications
        });
    }

    // Update product mutation
    const updateProductMutation = (data: any) => {
        updateProduct({
            variables: {
                model,
                id,
                data,
                contentElements,
                technicalSpecifications
            }
        }).then(r => {
            setUpdated(true)

            setTimeout(() => {
                setUpdated(false);
            }, 3000)

        }).catch(error => {
            return <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    }


    //Back to ListView
    const backToListView = () => {
        if(!isLang){
            navigate(`/products`, {replace: true})
        }else if(lang==''){
            navigate(`/products/${id}`, {replace: true})
        }else{
            navigate(`/products/${lang}/${id}`, {replace: true});
            setOpen(false);
        }
    }
    const openDialog = () => {

        const hasChanged = !shallowEqual(inicialValue, {
            "data": state,
            "contentElements": contentElements,
            "technicalSpecifications": technicalSpecifications
        });
        setIsLang(false);
        if (hasChanged) {
            setOpen(true);
        } else {
            backToListView()
        }
    }
    const openTranslateDialog = (lang: any) => {
        const hasChanged = !shallowEqual(inicialValue, {
            "data": state,
            "contentElements": contentElements,
            "technicalSpecifications": technicalSpecifications
        });
        setIsLang(true);
        if (hasChanged) {
            setLang(lang);
            setOpen(true);
        } else {
            if(lang ==''){
                navigate(`/products/${id}`, {replace: true})
            }else{
                navigate(`/products/${lang}/${id}`, {replace: true})
            }
        }
    }
    // Close snackbar notification
    const closeDialog = () => {
        setOpen(false);
    }

    // This function is called when the select changes
    const inputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, field?: string, type?: string, values?:any[]) => {
        let fieldName: any | string = '';
        let value: any;

        if (type) {
            fieldName = field;
            value = values
        }else{

            // Input fields not checkbox
            if (event.target && event.target.type !== 'checkbox')  {
                fieldName = event.target.name;
                value = event.target.value;
            }

            // Input fields checkbox
            if (event.target && event.target.type === 'checkbox')  {
                fieldName = event.target.name;
                // @ts-ignore
                value = event.target.checked;
            }

            if (field) {
                fieldName = field
                value = event

            }
        }

        // Update select value
        setState({...state, [fieldName]: value});

    };

    //------------------------ Inline content element methods --------------------------

    // Add content element
    const onAddContentElementHandler = (element: ContentElementItemType) => {
        setContentElements((prevState: any) => {
            if (element) {
                return [...prevState, element];
            }
        });
    }

    // Update content element
    const onContentElementUpdateHandler = (updateData: ContentElementItemType) => {
        const foundIndex = contentElements.findIndex((item: ContentElementItemType) => item.id == updateData.id);
        contentElements[foundIndex] = updateData;
        setContentElements(contentElements)
    }

    // Remove content element
    const onContentElementRemoveHandler = (id: number | string) => {
        setContentElements((prevState: ContentElementItemType[]) => prevState.filter(item => item.id !== id));
    }

    // Remove media from content element
    const onContentElementMediaRemoveHandler = (id: number | string) => {
        setRemovedMedia(prevState => [...prevState, id])
    }


    //------------------------ Inline technical specifications methods --------------------------

    // Add technical option
    const onAddTechnicalSpecificationHandler = (element: TechnicalSpecificationItemType) => {
        setTechnicalSpecifications((prevState: any) => {
            if (element) {
                return [...prevState, element];
            }
        });
    }

    // Update technical option
    const onTechnicalSpecificationUpdateHandler = (updateData: TechnicalSpecificationItemType) => {
        const foundIndex = technicalSpecifications.findIndex((item: TechnicalSpecificationItemType) => item.id == updateData.id);
        technicalSpecifications[foundIndex] = updateData;
        setTechnicalSpecifications(technicalSpecifications)
    }

    // Remove technical option
    const onTechnicalSpecificationRemoveHandler = (id: number | string) => {
        setTechnicalSpecifications((prevState: TechnicalSpecificationItemType[]) => prevState.filter(item => item.id !== id));
    }


    //------------------------------- Tabs methods -------------------------------------

    // Handle tab change
    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    // Set tub properties
    const tabsProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    return (
        <React.Fragment>
            <Helmet title="Product"/>

            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Stack direction="row" spacing={2}>
                        <Chip className="lang-nav"  variant="outlined" onClick={(lang) => {openTranslateDialog('') }}  clickable={true} avatar={<Avatar src={england} />} label="En" />
                        <Chip className={`lang-nav ${params.lang=='de' ? "active" : ""}`} variant="outlined" onClick={(lang) => {openTranslateDialog('de') }} clickable={true} avatar={<Avatar src={germany} />} label="De" />
                        <Chip className={`lang-nav ${params.lang=='cn' ? "active" : ""}`} variant="outlined" onClick={(lang) => {openTranslateDialog('cn') }} clickable={true} avatar={<Avatar src={china} />} label="Cn" />
                        <Button variant="contained" color="inherit" onClick={openDialog}>
                            <CloseIcon/>
                        </Button>

                        <Button type="submit" variant="contained" color="primary" form="single-form">
                            <SaveIcon/>
                        </Button>
                    </Stack>
                </Grid>

            </Grid>

            <Divider my={6}/>

            {state &&
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Formik
                                    initialValues={state}
                                    validationSchema={validationSchema}
                                    onSubmit={onSaveProduct}
                                    validateOnChange={true}
                                    validateOnMount={true}
                                >
                                    {({
                                          errors,
                                          handleBlur,
                                          handleChange,
                                          handleSubmit,
                                          touched,
                                          values,
                                      }) => (
                                        <form onSubmit={handleSubmit} id="single-form">
                                            <div className="tabs-wrapper">
                                                <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" allowScrollButtonsMobile>
                                                    <Tab label="General" {...tabsProps(0)} />
                                                    <Tab label="Additional" {...tabsProps(1)} />
                                                    <Tab label="SEO (Website)" {...tabsProps(2)} />
                                                    <Tab label="Technical specifications" {...tabsProps(3)} />
                                                </Tabs>
                                            </div>

                                            <div className="scrollable-content">
                                                <TabPanel value={tabValue} index={0}>
                                                    <GeneralInfoProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={1}>
                                                    <AdditionalProduct
                                                        data={dataObj}
                                                        onChange={(e, field, type, values) => {
                                                            handleChange(e);
                                                            inputHandler(e, field, type, values)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                        state={state}
                                                    />
                                                </TabPanel>
                                                <TabPanel value={tabValue} index={2}>
                                                    <PromotionProduct
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />

                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                                Storytelling for product promotions
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineContentElementTranslate
                                                        items={contentElements}
                                                        onAdd={onAddContentElementHandler}
                                                        onUpdate={onContentElementUpdateHandler}
                                                        onDelete={onContentElementRemoveHandler}
                                                        onMediaDelete={onContentElementMediaRemoveHandler}
                                                    />
                                                    <Grid container spacing={6} mb={10}>
                                                        <Grid item xs={12}></Grid>
                                                    </Grid>

                                                </TabPanel>
                                                <TabPanel value={tabValue} index={3}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6" mb={5}>
                                                               Technical specifications
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <InlineTechnicalSpecificationsTranslate
                                                        items={technicalSpecifications}
                                                        types={dataObj.getTechnicalSpecificationSelect}
                                                        onAdd={onAddTechnicalSpecificationHandler}
                                                        onUpdate={onTechnicalSpecificationUpdateHandler}
                                                        onDelete={onTechnicalSpecificationRemoveHandler}
                                                    />
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={12}>
                                                            <Divider mb={10} mt={5}/>
                                                        </Grid>
                                                    </Grid>

                                                    <TechnicalSpecificationProduct
                                                        data={dataObj}
                                                        onChange={(e, field) => {
                                                            handleChange(e);
                                                            inputHandler(e, field)
                                                        }}
                                                        touched={touched}
                                                        errors={errors}
                                                        values={values}
                                                    />
                                                </TabPanel>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }
            {updated &&
                <SnackbarNotification message="Saved successfully" open={updated}/>
            }
            <ConfirmationDialog
                message="Are you sure you want to leave without saving?"
                title="Leave page"
                open={open}
                button="Confirm"
                onConfirm={backToListView}
                onCancel={closeDialog}/>
        </React.Fragment>
    );
}

export default ProductTranslate;
