import React, {useState} from "react";
import {
    Alert, AlertColor,
    Snackbar
} from "@mui/material";

const SnackbarNotification: React.FC<{ message: string, open: boolean, type?: AlertColor}> = (props) => {
    const [open, setOpen] = useState(props.open);
    const [type, setType] = useState<AlertColor>(props.type ? props.type : 'success');


    //------------------------------- Public methods -------------------------------------

    // Close snackbar notification
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <Alert severity={type} sx={{ width: '100%' }}>{props.message}</Alert>
        </Snackbar>
    )
};

export default SnackbarNotification
