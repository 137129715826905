import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField, InputLabel, Select, MenuItem, FormControl
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const GeneralInfoDownload: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const category: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'category');

    // States
    const [selectedCategory, setSelectedCategory] = useState(state.category ? state.category : (values.category ? values.category[0] : ''));


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="title"
                        label="Title *"
                        value={values.title ? values.title : ''}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="sorting"
                        label="Sorting"
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        value={values.sorting ? values.sorting : ''}
                        error={Boolean(touched.sorting && errors.sorting)}
                        fullWidth
                        helperText={touched.sorting && errors.sorting}
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>

                <Grid item xs={12} md={8} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Category</InputLabel>
                        <Select
                            name="category"
                            value={selectedCategory}
                            label="Category"
                            onChange={(event: any) => {
                                setSelectedCategory(event.target.value);
                                onChange(event)
                            }}
                        >
                            {category.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoDownload;
