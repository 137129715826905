import React, {useContext} from "react";
import {Helmet} from "react-helmet-async";
import {
    Grid,
    Divider as MuiDivider,
    Typography,
    Button
} from "@mui/material";
import ListViewTable from "../../components/ListViewTable";
import {ApprovalsContext} from "./store/approvals-context";

// Types
import {HeadCell} from "../../../types/headCell";

// Styling
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import {Add as AddIcon} from "@mui/icons-material";
import SearchBar from "../../components/SearchBar";

const Divider = styled(MuiDivider)(spacing);


function Approvals() {
    const approvalsCtx = useContext(ApprovalsContext);

    // Set table headers data
    const headCells: Array<HeadCell> = approvalsCtx.headers;

    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Helmet title="Approvals & Compliances"/>
            <Grid item xs={12} mb={10}>
                <SearchBar text={approvalsCtx.search} onSearch={approvalsCtx.onSearch}/>
            </Grid>
            <Grid justifyContent="space-between" container spacing={10}>
                <Grid item>
                    <Typography variant="h3" gutterBottom display="inline">
                        Approvals & Compliances
                    </Typography>
                </Grid>
                <Grid item>
                    <div>
                        <Button variant="contained" color="primary" onClick={approvalsCtx.addItem}  startIcon={<AddIcon/>}>
                            New Approvals & Compliances
                        </Button>
                    </div>
                </Grid>
            </Grid>

            {approvalsCtx.filters
                ? <Divider my={6}/>
                : <Divider my={6} mb={10}/>
            }

            <Grid item xs={12} className="shadow">
                <ListViewTable
                    key='list'
                    rows={approvalsCtx.items}
                    tableHeaders={headCells}
                    onDeleteHandler={approvalsCtx.removeItem}
                    onSelectHandler={approvalsCtx.selectItem}
                    onPaginateHandler={approvalsCtx.onPaginate}
                    onSortHandler={approvalsCtx.onSort}
                    count={approvalsCtx.count}
                    page={approvalsCtx.page}
                    rowsPerPage={approvalsCtx.rowsPerPage}
                    sorting={approvalsCtx.sorting}
                    filters={approvalsCtx.filters}/>
            </Grid>
        </React.Fragment>
    );
}

export default Approvals;
