import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Page components
import Page404 from "./pages/auth/Page404";

//Auth
import SignIn from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Profile from "./pages/auth/Profile";
import ResetProfilePassword from "./pages/auth/ResetProfilePassword";


// Protected routes
import Categories from "./pages/categories/Categories";
import CategoriesContextProvider from "./pages/categories/store/categories-context";
import Category from "./pages/categories/Category";
import CategoryTranslate from "./pages/categories/CategoryTranslate";
import PlatformsContextProvider from "./pages/platforms/store/platforms-context";
import Platforms from "./pages/platforms/Platforms";
import Platform from "./pages/platforms/Platform";
import PlatformTranslate from "./pages/platforms/PlatformTranslate";
import ProductsContextProvider from "./pages/products/store/products-context";
import Product from "./pages/products/Product";
import Products from "./pages/products/Products";
import ProductTranslate from "./pages/products/ProductTranslate";
import DynamicSelectContextProvider from "./pages/settings/dynamic-select/store/dynamic-select-context";
import DynamicSelect from "./pages/settings/dynamic-select/Dynamic-Select";
import DynamicSelects from "./pages/settings/dynamic-select/Dynamic-Selects";
import Approvals from "./pages/settings/approvals/Approvals";
import Approval from "./pages/settings/approvals/Approval";
import ApprovalsContextProvider from "./pages/settings/approvals/store/approvals-context";
import ApprovalTranslate from "./pages/settings/approvals/ApprovalTranslate";
import Companies from "./pages/companies/Companies";
import Company from "./pages/companies/Company";
import CompaniesContextProvider from "./pages/companies/store/companies-context";
import CompanyTranslate from "./pages/companies/CompanyTranslate";
import Articles from "./pages/articles/Articles";
import Article from "./pages/articles/Article";
import ArticlesContextProvider from "./pages/articles/store/articles-context";
import ArticleTranslate from "./pages/articles/ArticleTranslate";
import Downloads from "./pages/settings/downloads/Downloads";
import Download from "./pages/settings/downloads/Download";
import DownloadsContextProvider from "./pages/settings/downloads/store/downloads-context";
import DownloadTranslate from "./pages/settings/downloads/DownloadTranslate";



// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));

const routes = [
    {
        path: "/",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element: <Default/>,
            },
        ],
    },
    {
        path: "dashboard",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element: <Default/>,
            },
        ],
    },
    {
        path: "categories",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element: <AuthGuard><CategoriesContextProvider><Categories/></CategoriesContextProvider></AuthGuard>,
            },
            {
                path: ":id",
                element: <AuthGuard><Category/></AuthGuard>,
            },
            {
                path: ":lang/:id",
                element: <AuthGuard><CategoryTranslate/></AuthGuard>,
            },
            {
                path: "new",
                element: <AuthGuard><Category/></AuthGuard>,
            },
        ],
    },
    {
        path: "products",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element: <AuthGuard><ProductsContextProvider><Products/></ProductsContextProvider></AuthGuard>,
            },
            {
                path: ":id",
                element: <AuthGuard><Product/></AuthGuard>,
            },
            {
                path: ":lang/:id",
                element: <AuthGuard><ProductTranslate/></AuthGuard>,
            },
            {
                path: "new",
                element: <AuthGuard><Product/></AuthGuard>,
            },
        ],
    },
    {
        path: "articles",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element: <AuthGuard><ArticlesContextProvider><Articles /></ArticlesContextProvider></AuthGuard>,
            },
            {
                path: ":id",
                element: <AuthGuard><Article /></AuthGuard>,
            },
            {
                path: ":lang/:id",
                element: <AuthGuard><ArticleTranslate/></AuthGuard>,
            },
            {
                path: "new",
                element: <AuthGuard><Article/></AuthGuard>,
            },
        ],
    },
    {
        path: "platforms",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element:  <AuthGuard><PlatformsContextProvider><Platforms/></PlatformsContextProvider></AuthGuard>,
            },
            {
                path: ":id",
                element: <AuthGuard><Platform/></AuthGuard>,
            },
            {
                path: ":lang/:id",
                element: <AuthGuard><PlatformTranslate/></AuthGuard>,
            },
            {
                path: "new",
                element: <AuthGuard><Platform/></AuthGuard>,
            },
        ],
    },
    {
        path: "companies",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element:  <AuthGuard><CompaniesContextProvider><Companies/></CompaniesContextProvider></AuthGuard>,
            },
            {
                path: ":id",
                element: <AuthGuard><Company/></AuthGuard>,
            },
            {
                path: ":lang/:id",
                element: <AuthGuard><CompanyTranslate/></AuthGuard>,
            },
            {
                path: "new",
                element: <AuthGuard><Company/></AuthGuard>,
            },
        ],
    },
    {
        path: "settings",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "selects",
                element: <AuthGuard><DynamicSelectContextProvider><DynamicSelects/></DynamicSelectContextProvider></AuthGuard>,
            },
            {
                path: "selects/:id",
                element: <AuthGuard><DynamicSelect/></AuthGuard>,
            },
            {
                path: "approvals",
                element: <AuthGuard><ApprovalsContextProvider><Approvals/></ApprovalsContextProvider></AuthGuard>,
            },
            {
                path: "approvals/:id",
                element: <AuthGuard><Approval/></AuthGuard>,
            },
            {
                path: "approvals/:lang/:id",
                element: <AuthGuard><ApprovalTranslate/></AuthGuard>,
            },
            {
                path: "approvals/new",
                element: <AuthGuard><Approval/></AuthGuard>,
            },
            {
                path: "downloads",
                element: <AuthGuard><DownloadsContextProvider><Downloads/></DownloadsContextProvider></AuthGuard>,
            },
            {
                path: "downloads/:id",
                element: <AuthGuard><Download/></AuthGuard>,
            },
            {
                path: "downloads/:lang/:id",
                element: <AuthGuard><DownloadTranslate/></AuthGuard>,
            },
            {
                path: "downloads/new",
                element: <AuthGuard><Download/></AuthGuard>,
            },

        ],
    },
    {
        path: "profile",
        element: <AuthGuard><DashboardLayout/></AuthGuard>,
        children: [
            {
                path: "",
                element: <AuthGuard><Profile/></AuthGuard>,
            },
        ],
    },
    {
        path: "*",
        element: <AuthLayout/>,
        children: [
            {
                path: "*",
                element: <Page404/>,
            },
        ],
    },
    {
        path: "auth",
        element: <AuthLayout/>,
        children: [
            {
                path: "login",
                element: <SignIn/>,
            },
            {
                path: "forgot-password",
                element: <ForgotPassword/>,
            },
            {
                path: "reset-password",
                element: <ResetPassword/>,
            },
            {
                path: "reset-profile-password",
                element: <AuthGuard><ResetProfilePassword/></AuthGuard>,
            },
        ],
    },
];

export default routes;
