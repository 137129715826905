import React, {useState} from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Divider as MuiDivider, Checkbox, Autocomplete,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const AddressCompany: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const countries: { id: string, title: string }[] = data.selectCountries;
    const states: { id: string, title: string }[] = data.selectStates;

    // States
    const [selectedCountries, setSelectedCountries] = useState<any>(state.countries ? state.countries.map((id:string) => countries.filter((item) => item.id === id)[0]) : (values.countries ? values.countries.map((id:string) => countries.filter((item) => item.id === id)[0]) : []));
    const [selectedStates, setSelectedStates] = useState<any[]>(state.states ? state.states.map((id:string) => states.filter((item) => item.id === id)[0]) : (values.states ? values.states.map((id:string) => states.filter((item) => item.id === id)[0]) : []));


    //------------------------------- Public methods -------------------------------------

    // Update countries on change
    const handleCountriesChange = (value:any) => {
        setSelectedCountries(value);
    };

    // Update states on change
    const handleStatesChange = (value:any) => {
        setSelectedStates(value);
    };


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Address info
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="building"
                        label="Building"
                        value={values.building ? values.building : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="street"
                        label="Street"
                        value={values.street ? values.street : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="zip"
                        label="Zip"
                        value={values.zip ? values.zip : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="city"
                        label="City"
                        value={values.city ? values.city : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>

                <Grid item xs={12} md={3} mb={5}>
                    <Autocomplete
                        multiple
                        options={countries}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title ? option.title : ''}
                        defaultValue={selectedCountries || []}
                        value={selectedCountries || []}
                        onChange={(event:any, value:any) => {handleCountriesChange(value);  onChange(event, 'countries', 'autocomplete', value.map((item:any) => item.id))}}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.id}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Countries"  placeholder="Countries" name="countries"/>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <Autocomplete
                        multiple
                        options={states}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title ? option.title : ''}
                        defaultValue={selectedStates || []}
                        value={selectedStates || []}
                        onChange={(event:any, value:any) => {handleStatesChange(value);  onChange(event, 'states', 'autocomplete', value.map((item:any) => item.id))}}
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.id}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="States"  placeholder="States" name="states"/>
                        )}
                    />
                </Grid>

                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="longitude"
                        label="Longitude"
                        value={values.longitude ? values.longitude : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="latitude"
                        label="Latitude"
                        value={values.latitude ? values.latitude : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default AddressCompany;
