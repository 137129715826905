import React from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Divider as MuiDivider,
} from "@mui/material";

// Types
import {FieldsGroupType} from "../../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import {useParams} from "react-router-dom";


const Divider = styled(MuiDivider)(spacing);


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const PromotionProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange} = props;
    const params = useParams();
    const lang = params.lang ? params.lang : '';


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Intro text
            </Typography>
            {lang == 'de' &&
                <React.Fragment>
                    <Grid container spacing={6}>

                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Intro text De"
                                name="introTextDe"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.introTextDe ? values.introTextDe : ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Intro text"
                                name="introText"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.introText ? values.introText : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider mb={10}/>
                        </Grid>
                    </Grid>
                    <Typography variant="h6" mb={5}>
                        Video
                    </Typography>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="videoDe"
                                label="Video De"
                                value={values.videoDe ? values.videoDe : ''}
                                fullWidth
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="video"
                                label="Video"
                                value={values.video ? values.video : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="h6" mb={5}>
                        SEO (Website)
                    </Typography>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="seoTitleDe"
                                label="Seo Title De"
                                value={values.seoTitleDe ? values.seoTitleDe : ''}
                                fullWidth
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="seoTitle"
                                label="Seo Title"
                                value={values.seoTitle ? values.seoTitle : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Description De"
                                name="seoDescriptionDe"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoDescriptionDe ? values.seoDescriptionDe : ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Description"
                                name="seoDescription"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoDescription ? values.seoDescription : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Keywords De"
                                name="seoKeywordsDe"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoKeywordsDe ? values.seoKeywordsDe : ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Keywords"
                                name="seoKeywords"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoKeywords ? values.seoKeywords : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider mb={10}/>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
            {lang == 'cn' &&
                <React.Fragment>
                    <Grid container spacing={6}>

                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Intro text Cn"
                                name="introTextCn"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.introTextCn ? values.introTextCn : ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Intro text"
                                name="introText"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.introText ? values.introText : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider mb={10}/>
                        </Grid>
                    </Grid>
                    <Typography variant="h6" mb={5}>
                        Video
                    </Typography>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="videoCn"
                                label="Video Cn"
                                value={values.videoCn ? values.videoCn : ''}
                                fullWidth
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="video"
                                label="Video"
                                value={values.video ? values.video : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="h6" mb={5}>
                        SEO (Website)
                    </Typography>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="seoTitleCn"
                                label="Seo Title Cn"
                                value={values.seoTitleCn ? values.seoTitleCn : ''}
                                fullWidth
                                onChange={onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                name="seoTitle"
                                label="Seo Title"
                                value={values.seoTitle ? values.seoTitle : ''}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Description Cn"
                                name="seoDescriptionCn"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoDescriptionCn ? values.seoDescriptionCn : ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Description"
                                name="seoDescription"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoDescription ? values.seoDescription : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Keywords Cn"
                                name="seoKeywordsCn"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoKeywordsCn ? values.seoKeywordsCn : ''}
                                onChange={onChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} mb={5}>
                            <TextField
                                label="Seo Keywords"
                                name="seoKeywords"
                                multiline
                                rows={4}
                                fullWidth
                                value={values.seoKeywords ? values.seoKeywords : ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider mb={10}/>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>
    );
}


export default PromotionProduct;
