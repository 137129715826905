import React, {useState} from "react";

import {
    Grid,
    Typography,
    Checkbox,
    TextField, Divider as MuiDivider, Autocomplete, FormControl, InputLabel, Select, MenuItem,
} from "@mui/material";
import styled from "styled-components/macro";
import {spacing} from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// Styling
import {FieldsGroupType} from "../../../types/fieldsGroup";


// Styling
const Divider = styled(MuiDivider)(spacing);
const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const FiltersProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const supply: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'supply');
    const selectedSupplyObj = state.supply ? state.supply.map((id: string) => supply.filter((item) => item.id === id)[0]) :  (values.supply ? values.supply.map((id: string) => supply.filter((item) => item.id === id)[0]) : []);
    const currentConfig: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'currentConfig');
    const selectedCurrentConfigObj = state.currentConfig ? state.currentConfig.map((id: string) => currentConfig.filter((item) => item.id === id)[0]) : (values.currentConfig ? values.currentConfig.map((id: string) => currentConfig.filter((item) => item.id === id)[0]) : []);
    const voltageEmcConfig: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'voltageEmcConfig');
    const selectedVoltageEmcConfigObj = state.voltageEmcConfig ? state.voltageEmcConfig.map((id: string) => voltageEmcConfig.filter((item) => item.id === id)[0]) : (values.voltageEmcConfig ? values.voltageEmcConfig.map((id: string) => voltageEmcConfig.filter((item) => item.id === id)[0]) : []);
    const topologyEmcConfig: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'topologyEmcConfig');
    const selectedTopologyEmcConfigObj = state.topologyEmcConfig ? state.topologyEmcConfig.map((id: string) => topologyEmcConfig.filter((item) => item.id === id)[0]) :  (values.topologyEmcConfig ? values.topologyEmcConfig.map((id: string) => topologyEmcConfig.filter((item) => item.id === id)[0]) : []);
    const productType: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'productType');
    const selectedProductTypeObj = state.productType ? state.productType  : (values.productType ? values.productType[0] : '');
    const attenuationPerf: { id: string, title: string }[] = data.getSpecificSelect.filter((item: any) => item.field === 'attenuationPerf');
    const selectedAttenuationPerfObj = state.attenuationPerf ? state.attenuationPerf.map((id: string) => attenuationPerf.filter((item) => item.id === id)[0]) : (values.attenuationPerf ? values.attenuationPerf.map((id: string) => attenuationPerf.filter((item) => item.id === id)[0]) : []);
    const voltageUnits: { id: string, title: string }[] = data.getUnitSelect.filter((item: any) => item.field === 'voltageUnit');

    // States
    const [selectedSupply, setSelectedSupply] = useState<any[]>(selectedSupplyObj);
    const [selectedCurrentConfig, setSelectedCurrentConfig] = useState<any[]>(selectedCurrentConfigObj);
    const [selectedVoltageEmcConfig, setSelectedVoltageEmcConfig] = useState<any[]>(selectedVoltageEmcConfigObj);
    const [selectedTopologyEmcConfig, setSelectedTopologyEmcConfig] = useState<any[]>(selectedTopologyEmcConfigObj);
    const [selectedAttenuationPerf, setSelectedAttenuationPerf] = useState<any[]>(selectedAttenuationPerfObj);
    const [selectedProductType, setSelectedProductType] = useState<any[]>(selectedProductTypeObj);
    const [voltageUnit, setVoltageUnit] = useState(state.voltageUnit ? state.voltageUnit : (values.voltageUnit ? values.voltageUnit[0] : ''));


    //------------------------------- Public methods -------------------------------------

    // Update supply on change
    const handleSupplyChange = (value: any) => {
        setSelectedSupply(value);
    };

    // Update currentConfig on change
    const handleCurrentConfigChange = (value: any) => {
        setSelectedCurrentConfig(value);
    };

    // Update VoltageEmcConfig on change
    const handleVoltageEmcConfigChange = (value: any) => {
        setSelectedVoltageEmcConfig(value);
    };

    // Update TopologyEmcConfig on change
    const handleTopologyEmcConfigChange = (value: any) => {
        setSelectedTopologyEmcConfig(value);
    };

    // Update TopologyEmcConfig on change
    const handleAttenuationPerfChange = (value: any) => {
        setSelectedAttenuationPerf(value);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General config
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <Autocomplete
                        multiple
                        options={supply}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedSupply || []}
                        defaultValue={selectedSupply || []}
                        onChange={(event: any, value: any) => {
                            handleSupplyChange(value);
                            onChange(event, 'supply', 'autocomplete', value.map((item: any) => item.id))
                        }
                        }
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Supply" placeholder="Supply" name="supply"/>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <Autocomplete
                        multiple
                        options={topologyEmcConfig}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedTopologyEmcConfig || []}
                        defaultValue={selectedTopologyEmcConfig || []}
                        onChange={(event: any, value: any) => {
                            handleTopologyEmcConfigChange(value);
                            onChange(event, 'topologyEmcConfig', 'autocomplete', value.map((item: any) => item.id))
                        }
                        }
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Topology" placeholder="Topology" name="topologyEmcConfig"/>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Product type</InputLabel>
                        <Select
                            name="productType"
                            value={selectedProductType}
                            label="Product type"
                            onChange={(event: any) => {
                                setSelectedProductType(event.target.value);
                                onChange(event)
                            }}
                        >
                            {productType.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <Autocomplete
                        multiple
                        options={attenuationPerf}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedAttenuationPerf || []}
                        defaultValue={selectedAttenuationPerf || []}
                        onChange={(event: any, value: any) => {
                            handleAttenuationPerfChange(value);
                            onChange(event, 'attenuationPerf', 'autocomplete', value.map((item: any) => item.id))
                        }
                        }
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Attenuation performance" placeholder="Attenuation performance"
                                       name="attenuationPerf"/>
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Config voltage
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <Autocomplete
                        multiple
                        options={currentConfig}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedCurrentConfig || []}
                        defaultValue={selectedCurrentConfig || []}
                        onChange={(event: any, value: any) => {
                            handleCurrentConfigChange(value);
                            onChange(event, 'currentConfig', 'autocomplete', value.map((item: any) => item.id))
                        }
                        }
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Current (A)" placeholder="Current (A)" name="currentConfig"/>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <Autocomplete
                        multiple
                        options={voltageEmcConfig}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedVoltageEmcConfig || []}
                        defaultValue={selectedVoltageEmcConfig || []}
                        onChange={(event: any, value: any) => {
                            handleVoltageEmcConfigChange(value);
                            onChange(event, 'voltageEmcConfig', 'autocomplete', value.map((item: any) => item.id))
                        }
                        }
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Voltage (V)" placeholder="Voltage (V)"
                                       name="voltageEmcConfig"/>
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="voltageFrom"
                        label="Voltage from"
                        value={values.voltageFrom ? values.voltageFrom : ''}
                        error={Boolean(touched.voltageFrom && errors.voltageFrom)}
                        fullWidth
                        helperText={touched.voltageFrom && errors.voltageFrom}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="voltageTo"
                        label="Voltage to"
                        value={values.voltageTo ? values.voltageTo : ''}
                        error={Boolean(touched.voltageTo && errors.voltageTo)}
                        fullWidth
                        helperText={touched.voltageTo && errors.voltageTo}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <FormControl className="full-width">
                        <InputLabel>Voltage unit</InputLabel>
                        <Select
                            name="voltageUnit"
                            value={voltageUnit}
                            label="Voltage unit"
                            onChange={(event: any) => {
                                setVoltageUnit(event.target.value);
                                onChange(event)
                            }}
                        >
                            {voltageUnits.map((item: { id: string, title: string }) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Divider mb={10}/>
                </Grid>
            </Grid>

            <Typography variant="h6" mb={5}>
                Config rated
            </Typography>
            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="ratedCurrentsFrom"
                        label="Rated currents from"
                        value={values.ratedCurrentsFrom ? values.ratedCurrentsFrom : ''}
                        error={Boolean(touched.ratedCurrentsFrom && errors.ratedCurrentsFrom)}
                        fullWidth
                        helperText={touched.ratedCurrentsFrom && errors.ratedCurrentsFrom}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="ratedCurrentsTo"
                        label="Rated currents to"
                        value={values.ratedCurrentsTo ? values.ratedCurrentsTo : ''}
                        error={Boolean(touched.ratedCurrentsTo && errors.ratedCurrentsTo)}
                        fullWidth
                        helperText={touched.ratedCurrentsTo && errors.ratedCurrentsTo}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default FiltersProduct;
