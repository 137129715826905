import React from "react";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
} from "@mui/material";


// Types
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import {spacing} from "@mui/system";
import styled from "styled-components/macro";



const TextField = styled(MuiTextField)<{ my?: number }>(spacing);


const ContactInfoCompany: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange} = props;


    //------------------------------- Public methods -------------------------------------


    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                Contact info
            </Typography>

            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={10}>
                    <TextField
                        name="person"
                        label="Person"
                        value={values.person ? values.person : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
            </Grid>
            <Grid container spacing={6}>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="mobile"
                        label="Mobile"
                        value={values.mobile ? values.mobile : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="phone"
                        label="Phone 1"
                        value={values.phone ? values.phone : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="phoneOne"
                        label="Phone 2"
                        value={values.phoneOne ? values.phoneOne : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="fax"
                        label="Fax 1"
                        value={values.fax ? values.fax : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="faxOne"
                        label="Fax 2"
                        value={values.faxOne ? values.faxOne : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="email"
                        label="Email"
                        value={values.email ? values.email : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="website"
                        label="Website"
                        value={values.website ? values.website : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={3} mb={5}>
                    <TextField
                        name="websiteOne"
                        label="Website 2"
                        value={values.websiteOne ? values.websiteOne : ''}
                        fullWidth
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default ContactInfoCompany;
