import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    Alert as MuiAlert,
} from "@mui/material";
import Loader from "../../../../components/Loader";
import SnackbarNotification from "../../../../components/SnackbarNotification";

// Types
import {dynamicSelectListView} from "../../../../types/dynamicSelectListView";


// Graphql
import {useDynamicSelectListQuery} from "../../../../graphql/settings/dynamic-select/dynamic-select.graphql-gen";

// Style
import styled from "styled-components/macro";
import {spacing} from "@mui/system";


const Alert = styled(MuiAlert)(spacing);


export const DynamicSelectContext = React.createContext<dynamicSelectListView>({
    items: [],
    headers: [],
    count: 0,
    selectItem: (id: string | number) => {},
    onPaginate: (limit: number, page: number) => {},
    onSearch: (searchText:string) => {},
    onSort: (filed:string, order: string) => {},
    page: 0,
    rowsPerPage: 10,
    sorting: {field: '', order: ''},
    search: '',
});

const DynamicSelectContextProvider: React.FC = (props) => {
    const model: string = 'SelectField';

    // States
    const [items, setItems] = useState<any[]>([]);
    const [headers, setHeaders] = useState<any[]>([]);
    const [count, setCount] = useState<number>(0);
    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [sorting, setSorting] = useState<{ field: string, order: string }>({field: 'updated_at', order: 'desc'});
    const [search, setSearch] = useState<string>('');

    // Get data from API (queries)
    const {data, error, loading, refetch: refetchListViewData} = useDynamicSelectListQuery({
        variables: {
            model,
            search: search,
            pagination: {
                limit: itemsPerPage,
                page: currentPage
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }, onCompleted: () => {
            if (data) {
                setHeaders(data.getDynamicTable.tableHeader);
                setItems(data.getDynamicTable.tableData);
                setCurrentPage(data.getDynamicTable.page);
                setItemsPerPage(data.getDynamicTable.limit);
                setCount(data.getDynamicTable.count);
                setSearch(data.getDynamicTable.search);
                setSorting(data.getDynamicTable.sorting);
            }
        }
    });


    if (loading) {
        return <Loader/>;
    }

    // Handle error
    if (error) {
        return (
            <Alert mb={4} severity="error">
                Error!
            </Alert>
        );
    }


    //------------------------------- Public methods -------------------------------------


    // Go to singe view
    const selectItemHandler = (id: string | number) => {
        navigate(`/settings/selects/${id}`, {replace: true});
    };


    // Paginate listview table
    const onPaginateHandler = (limit: number, page: number) => {
        refetchListViewData({
            model,
            search,
            pagination: {
                limit: limit,
                page: page
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });

        setItemsPerPage(limit);
        setCurrentPage(page);
    };


    // Search
    const onSearchHandler = (searchText: string) => {
        setSearch(searchText);

        refetchListViewData({
            model,
            search: searchText,
            pagination: {
                limit: itemsPerPage,
                page: currentPage
            },
            sorting: {
                field: sorting.field,
                order: sorting.order
            }
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };

    // Sort
    const onSortHandler = (field: string, order: string) => {
        setCurrentPage(0);
        setSorting({field, order});

        refetchListViewData({
            model,
            search,
            pagination: {
                limit: itemsPerPage,
                page: 0
            },
            sorting: {
                field,
                order
            }
        }).catch(error => {
            return  <SnackbarNotification message="Error, please try again later." open={true} type={'error'}/>
        });
    };



    // Set context values
    const contextValue: dynamicSelectListView = {
        items: items,
        headers: headers,
        count: count,
        selectItem: selectItemHandler,
        onPaginate: onPaginateHandler,
        onSearch: onSearchHandler,
        onSort: onSortHandler,
        page: currentPage,
        rowsPerPage: itemsPerPage,
        search: search,
        sorting: sorting,
    };

    return (
        <DynamicSelectContext.Provider value={contextValue}>
            {props.children}
        </DynamicSelectContext.Provider>
    );
};

export default DynamicSelectContextProvider;
