import React, {useState} from "react";

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    InputLabel,
    Checkbox,
    Autocomplete, FormGroup, FormControlLabel,
} from "@mui/material";

import ReactQuill from "react-quill";

// Styles
import {FieldsGroupType} from "../../../types/fieldsGroup";

// Styling
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import {spacing} from "@mui/system";
import styled from "styled-components/macro";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {DatePicker} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";



const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;


const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const QuillWrapper = styled.div`.ql-editor {min-height: 200px;}`;


const GeneralInfoProduct: React.FC<FieldsGroupType> = (props) => {
    const {data, values, touched, errors, onChange, state} = props;
    const platforms: { id: string, title: string }[] = data.selectPlatforms;
    const selectedPlatformsObj = state.platforms ? state.platforms.map((id: string) => platforms.filter((item) => item.id === id)[0]) : (values.platforms ? values.platforms.map((id: string) => platforms.filter((item) => item.id === id)[0]) : []);

    // States
    const [selectedPlatforms, setSelectedPlatforms] = useState<any[]>(selectedPlatformsObj);
    const [description, setDescription] = useState(state.description ? state.description : (values.description ? values.description : ''));
    const [featuresAndBenefits, setFeaturesAndBenefits] = useState(state.featuresAndBenefits ? state.featuresAndBenefits : (values.featuresAndBenefits ? values.featuresAndBenefits : ''));
    const [applications, setApplications] = useState(state.applications ? state.applications : (values.applications ? values.applications : ''));
    const [newToDate, setNewToDate] = React.useState<Date | null>(state.newToDate ?  state.newToDate : (values.newToDate ?  values.newToDate : null));
    const [valueExportWeb, setValueExportWeb] = useState<boolean>(values.exportWeb?.length == 0 || !values.exportWeb ? false : values.exportWeb);
    const [valueNewCaption, setValueNewCaption] = useState<boolean>(values.new?.length == 0 || !values.new ? false : values.new);


    //------------------------------- Public methods -------------------------------------

    // Update platforms on change
    const handlePlatformChange = (event: any, value: any) => {
        setSelectedPlatforms(value)
    }

    // Update description on change
    const handleChangeDescription = (event: any) => {
        event.toString('html');
        setDescription(event);
    };

    // Update features and benefits on change
    const handleFeaturesAndBenefits = (event: any) => {
        event.toString('html');
        setFeaturesAndBenefits(event);
    };

    // Update approvals on change
    const handleApplications = (event: any) => {
        event.toString('html');
        setApplications(event);
    };

    //update checkbox
    const handleExportWebChange = (event:any) => {
        setValueExportWeb(event.target.checked);
    };
    const handleNewCaptionChange = (event:any) => {
        setValueNewCaption(event.target.checked);
    };

    return (
        <React.Fragment>
            <Typography variant="h6" mb={5}>
                General informations
            </Typography>

            <Grid container spacing={6} mb={5}>
                <Grid item xs={12} md={4} mb={5}>
                    <TextField
                        name="sku"
                        label="SKU *"
                        value={values.sku ? values.sku : ''}
                        error={Boolean(touched.sku && errors.sku)}
                        fullWidth
                        helperText={touched.sku && errors.sku}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <TextField
                        name="sorting"
                        label="Sorting"
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        value={values.sorting ? values.sorting : ''}
                        error={Boolean(touched.sorting && errors.sorting)}
                        fullWidth
                        helperText={touched.sorting && errors.sorting}
                        onChange={onChange}
                        variant="outlined"
                    />
                </Grid>
            </Grid>

            <Grid container spacing={6}>

                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="title"
                        label="Title *"
                        value={values.title ? values.title : ''}
                        error={Boolean(touched.title && errors.title)}
                        fullWidth
                        helperText={touched.title && errors.title}
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <TextField
                        name="subtitle"
                        label="Subtitle"
                        value={values.subtitle ? values.subtitle : ''}
                        error={Boolean(touched.subtitle && errors.subtitle)}
                        fullWidth
                        helperText={touched.subtitle && errors.subtitle}
                        onChange={onChange}
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12} md={6} mb={5}>
                    <Autocomplete
                        multiple
                        options={platforms}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedPlatforms || []}
                        defaultValue={selectedPlatforms || []}
                        onChange={(event: any, value: any) => {
                            handlePlatformChange(event, value);
                            onChange(event, 'platforms', 'autocomplete', value.map((item: any) => item.id))
                        }
                        }
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Platforms (Website)" placeholder="Platforms (Website)" name="platforms"/>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Newest products"
                            value={newToDate}
                            onChange={(newValue: any) => {
                                setNewToDate(newValue)
                                onChange( '', 'newToDate', 'date', newValue);
                            }}
                            renderInput={(params: any) => <TextField {...params}  name="newToDate"/>}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                name="exportWeb"
                                checked={valueExportWeb}
                                onChange={(e) =>{
                                    handleExportWebChange(e);
                                    onChange(e)
                                } }
                            />
                        } label="Export to Web"/>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={2} mb={5}>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                name="new"
                                checked={valueNewCaption}
                                onChange={(e) => {
                                    handleNewCaptionChange(e);
                                    onChange(e)
                                }}
                            />
                        } label="New - Caption"/>
                    </FormGroup>
                </Grid>

                <Grid item xs={12} mb={5}>
                    <TextField
                        label="Short description"
                        name="shortDescription"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.shortDescription ? values.shortDescription : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} mb={5}>
                    <TextField
                        label="List teaser text"
                        name="teaser"
                        multiline
                        rows={4}
                        fullWidth
                        value={values.teaser ? values.teaser : ''}
                        onChange={onChange}
                    />
                </Grid>
                <Grid item xs={12} mb={5}>
                    <QuillWrapper className="full-width">
                        <InputLabel className="mb-5">Description</InputLabel>
                        <ReactQuill
                            theme="snow"
                            value={description}
                            onChange={(e) => {
                                handleChangeDescription(e);
                                onChange(e, 'description')
                            }}
                        />
                    </QuillWrapper>
                </Grid>
                <Grid item xs={12} mb={5}>
                    <QuillWrapper className="full-width">
                        <InputLabel className="mb-5">Features and benefits</InputLabel>
                        <ReactQuill
                            theme="snow"
                            value={featuresAndBenefits}
                            onChange={(e) => {
                                handleFeaturesAndBenefits(e);
                                onChange(e, 'featuresAndBenefits')
                            }}
                        />
                    </QuillWrapper>
                </Grid>
                <Grid item xs={12} mb={5}>
                    <QuillWrapper className="full-width">
                        <InputLabel className="mb-5">Typical applications</InputLabel>
                        <ReactQuill
                            theme="snow"
                            value={applications}
                            onChange={(e) => {
                                handleApplications(e);
                                onChange(e, 'applications')
                            }}
                        />
                    </QuillWrapper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default GeneralInfoProduct;
